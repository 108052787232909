/* global window */
import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { HAS_WEBP_SUPPORT } from 'actionTypes';
import detectWebpSupport from 'helpers/isWebpSupported';

const DetectWebP = props => {
  const { dispatch } = props;
  useEffect(() => {
    detectWebpSupport().then(isWebpSupported => {
      window.webpSupported = String(isWebpSupported ? 1 : 0);
      isWebpSupported && dispatch({ type: HAS_WEBP_SUPPORT });
    });
  }, [dispatch]);
  return null;
};

DetectWebP.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const composedHoc = compose(connect(mapStateToProps, mapDispatchToProps), memo);

export default composedHoc(DetectWebP);
