import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { children as childrenPropTypes } from 'PropTypes';
import AsyncComponent from 'components/AsyncComponent';
import { isPaymentPage } from 'components/Common/CarouselPageUtils';
import LayoutLoader from './loading';
import Desktop from './desktop';

// const Desktop = AsyncComponent(() => import(/* webpackChunkName: "LayoutPartial.desktop" */ 'partials/LayoutPartial/desktop'), LayoutLoader);
const Mobile = AsyncComponent(
  () => import(/* webpackChunkName: "LayoutPartial.mobile" */ 'partials/LayoutPartial/mobile'),
  !isPaymentPage ? LayoutLoader : undefined,
);

const LayoutPartial = ({ layout, ignoreContainer, children }) => {
  if (ignoreContainer) {
    return children;
  }
  switch (layout) {
    case 'desktop':
      return <Desktop>{children}</Desktop>;
    case 'mobile':
      return <Mobile>{children}</Mobile>;
    default:
      return null;
  }
};

LayoutPartial.propTypes = {
  layout: PropTypes.string.isRequired,
  ignoreContainer: PropTypes.bool.isRequired,
  children: childrenPropTypes.isRequired,
};

const selector = state => ({
  layout: state.view.layout,
  ignoreContainer: state.session.ignoreContainer,
});

export default withRouter(connect(selector, {})(LayoutPartial));
