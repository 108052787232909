import { PAYMENT_SESSION_REQUEST, PAYMENT_SESSION_SUCCESS, PAYMENT_SESSION_FAIL } from 'actionTypes';
import api from 'api';
import errors from '../lib/errors';

export default (auth, dispatch, getState) => {
  dispatch({ type: PAYMENT_SESSION_REQUEST, payload: {} });
  api
    .get('/payment-sessions/me')
    .then(response => {
      dispatch({ type: PAYMENT_SESSION_SUCCESS, payload: response.data });
    })
    .catch(error => dispatch({ type: PAYMENT_SESSION_FAIL, payload: errors.clean(error) }));
};
