export class __PHP_Incomplete_Class {
  constructor(name) {
    // eslint-disable-next-line no-underscore-dangle
    this.__PHP_Incomplete_Class_Name = name;
  }
}
export function getByteLength(contents, options) {
  if (typeof Buffer !== 'undefined') {
    return Buffer.byteLength(contents, options.encoding);
  }

  return encodeURIComponent(contents).replace(/%[A-F\d]{2}/g, 'U').length;
}
export function isInteger(value) {
  return typeof value === 'number' && parseInt(value, 10) === value;
}
export function getIncompleteClass(name) {
  return new __PHP_Incomplete_Class(name);
}
export function getClass(prototype) {
  function PhpClass() {}

  PhpClass.prototype = prototype;
  return PhpClass;
}
