/* global window: true */
import { UPDATE_DIMENSIONS_SCROLL } from 'actionTypes';

let scrollPos = -10;
const hideNavigation = (newScrollPos, curScrollPos) => {
  if (window.location.pathname.indexOf('/profile') !== -1) {
    if (curScrollPos > newScrollPos) {
      return false;
    }
    if (curScrollPos < newScrollPos) {
      return true;
    }
  }
  return false;
};
const isElasticScroll = scrollTop => {
  const newScrollHeight = window.document.documentElement.scrollHeight;
  if (scrollTop + window.document.body.offsetHeight >= newScrollHeight - 150 || scrollTop < 0) {
    return true;
  }
  return false;
};
const update = dispatch => {
  const newScrollPos = window.pageYOffset || document.documentElement.scrollTop;

  if (Math.abs(newScrollPos - scrollPos) > 0.1) {
    dispatch({
      type: UPDATE_DIMENSIONS_SCROLL,
      payload: {
        isSearchPage: window.location.pathname.indexOf('/search') !== -1,
        facetBottom: (document.getElementById('facetBottom') || {}).offsetTop || 5000,
        scroll: newScrollPos,
        hideNav: hideNavigation(newScrollPos, scrollPos),
        elasticScroll: isElasticScroll(newScrollPos),
      },
    });
  }
  scrollPos = newScrollPos;
};

let running = true;

export default () => dispatch => {
  const fps = 60;

  const onTick = () => {
    update(dispatch);
    running = false;
  };

  const scrollThrottler = () => {
    if (!running) {
      running = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(onTick);
      } else {
        setTimeout(onTick, 1000 / fps);
      }
    }
  };

  window.addEventListener('scroll', scrollThrottler, false);
  onTick();
};
