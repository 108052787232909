import React, { memo } from 'react';
import PropTypes from 'prop-types';

const styles = {
  mainDiv: () => ({
    color: '#fff',
    fontFamily: 'Roboto',
    fontWeight: '400',
    margin: '0 auto',
    backgroundColor: '#ff5a60',
    position: 'absolute',
    width: '100%',
    zIndex: '99999',
    height: '100vh',
  }),
  imgDiv: {
    margin: '3% auto 0',
    width: '102px',
    height: '107px',
  },
  parentDiv: {
    top: '50%',
    transform: 'translateY(15%)',
  },
  paraOne: {
    padding: '10px 0',
    fontSize: '26px',
    textAlign: 'center',
  },
  paraTwo: {
    fontSize: '16px',
    margin: '0 11%',
    lineHeight: '22px',
    textAlign: 'center',
  },
};

const Orientation = ({ lockOrientation }) => {
  // alert(window.screen.height + "------"+window.screen.width);
  if (Math.abs(lockOrientation) === 90) {
    return (
      <div style={styles.mainDiv()} data-test-selector="landscapedView">
        <div style={styles.parentDiv}>
          <div style={styles.imgDiv}>
            <img src="/assets/rotate-device.svg" alt="Please rotate your device" />
          </div>
          <div style={styles.paraOne} data-test-selector="landscapedText1">{`Please rotate your device`}</div>
          <div
            style={styles.paraTwo}
            data-test-selector="landscapedText2"
          >{`We don't support landscape mode. Please go back to portrait mode for the best experience`}</div>
        </div>
      </div>
    );
  }

  return null;
};

Orientation.defaultProps = {
  lockOrientation: 90,
};

Orientation.propTypes = {
  lockOrientation: PropTypes.number,
};

export default memo(Orientation);
