export const SET_RECENT_CHAT_APPEND_DATA_STATE = 'SET_RECENT_CHAT_APPEND_DATA_STATE';
export const SET_RECENT_CHAT_PREPEND_DATA_STATE = 'SET_RECENT_CHAT_PREPEND_DATA_STATE';

export const FETCH_RECENT_CHAT_DATA_EFFECT = 'FETCH_RECENT_CHAT_DATA_EFFECT';

export const SET_RECENT_CHAT_UPDATE_MESSAGE_STATE = 'SET_RECENT_CHAT_UPDATE_MESSAGE_STATE';
export const SET_RECENT_CHAT_UPDATE_MESSAGE_READ_STATE = 'SET_RECENT_CHAT_UPDATE_MESSAGE_READ_STATE';

export const RECENT_CHAT_SNACKBAR_CLOSE_STATE = 'RECENT_CHAT_SNACKBAR_CLOSE_STATE';

export const RESET_RECENT_CHAT_STATE = 'RESET_RECENT_CHAT_STATE';

export const BACK_FROM_CHAT_HISTORY = 'BACK_FROM_CHAT_HISTORY';

export const RECENT_CHAT_REMOVE_PROFILE_STATE = 'RECENT_CHAT_REMOVE_PROFILE_STATE';

export const RECENT_CHAT_SCROLL_TO_RESET_STATE = 'RECENT_CHAT_SCROLL_TO_RESET_STATE';

export const RECENT_CHAT_ACCEPT_CONFIRM = 'RECENT_CHAT_ACCEPT_CONFIRM';

export const SET_RECENT_CHAT_ACCEPT_MESSAGE_STATE = 'SET_RECENT_CHAT_ACCEPT_MESSAGE_STATE';

export const FETCH_CHATS_PROFILE = 'FETCH_CHATS_PROFILE';
export const CANCEL_FETCH_CHATS_PROFILE = 'CANCEL_FETCH_CHATS_PROFILE';

export const SET_RECENT_CHAT_UPDATE_FILTERED_ITEM_EFFECT = 'SET_RECENT_CHAT_UPDATE_FILTERED_ITEM_EFFECT';
export const SET_RECENT_CHAT_UPDATE_ITEM_EFFECT = 'SET_RECENT_CHAT_UPDATE_ITEM_EFFECT';

export const RECENT_CHAT_LOAD_MORE_ITEMS = 'RECENT_CHAT_LOAD_MORE_ITEMS';
export const RECENT_CHAT_SET_HIGHEST_PAGE_STATE = 'RECENT_CHAT_SET_HIGHEST_PAGE_STATE';
