import { NOTIFICATIONS_REQUEST, NOTIFICATIONS_SUCCESS, NOTIFICATIONS_FAIL } from 'actionTypes';
import api from 'api';
import errors from 'actions/lib/errors';

export default (auth, dispatch, getState, onSuccess, source) => {
  const payload = { mark_as_read: source === 'manual' ? 'yes' : 'no' };
  getState().config.app.webp !== '0' && (payload.file_extension = 'webp');

  dispatch({ type: NOTIFICATIONS_REQUEST, payload });
  api
    .get('/notifications/me', { params: payload })
    .then(response => {
      onSuccess(dispatch, getState, response.data.alerts.unread, response.data.alerts.items, getState().chat.sidebar.alerts, source);
      dispatch({ type: NOTIFICATIONS_SUCCESS, payload: { ...response.data, markAsRead: source === 'manual' } });
    })
    .catch(error => dispatch({ type: NOTIFICATIONS_FAIL, payload: errors.clean(error) }));
};
