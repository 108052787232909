// ref: https://www.html5rocks.com/en/mobile/workingoffthegrid
import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { HAS_NET_CONNECTIVITIY, LOST_NET_CONNECTIVITIY } from 'actionTypes';

const handleNetworkConnection = ({ type, dispatch }) => event =>
  dispatch({
    type,
    payload: event,
  });

const MonitorNetConnection = props => {
  // calls on component lifecyle
  useEffect(() => {
    const { dispatch } = props;

    // ref: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine#Example
    window.addEventListener('offline', handleNetworkConnection({ type: LOST_NET_CONNECTIVITIY, dispatch }), false);

    window.addEventListener('online', handleNetworkConnection({ type: HAS_NET_CONNECTIVITIY, dispatch }), false);
  }, []);
  return null;
};

MonitorNetConnection.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const composedHoc = compose(connect(mapStateToProps, mapDispatchToProps), memo);

export default composedHoc(MonitorNetConnection);
