import device from '../../helpers/device';

let detection = null;

// '/' is added to prevent loading desktop chunks when the user lands on home ('/')
const releasedMobilePages = [
  '/',
  '/inbox/chats',
  '/profile',
  '/search/partner',
  '/profile/daily-recommendations',
  '/inbox/pending/interests',
  '/inbox/filteredout',
  '/inbox/accepted/interests',
  '/inbox/pending/requests',
  '/inbox/sent/requests',
  '/inbox/accepted/requests',
  '/inbox/sent/interests',
  '/inbox/archived/interests',
  '/inbox/expired/interests',
  '/inbox/decidelater/interests',
  '/inbox/requests',
  '/search/broader',
  '/search/ematchmaker',
  '/search/personal',
  '/search/discovery/recently-joined',
  '/search/discovery/premium',
  '/search/discovery/recent-visitors',
  '/search/basic_search',
  '/search/smart_search',
  '/forgot-password',
  '/otp-login',
  '/stop-page/name',
  '/search',
  '/stop-page/csat-survey',
  '/stop-page/photo-upload',
  '/stop-page/phone-setting',
  '/stop-page/annual-income-correction',
  '/stop-page/pay-to-stay',
  '/payment',
  '/cart',
  '/payment/thankyou',
  '/upiauthentication',
  '/payment/responseorder/my-cart',
  '/match-price',
  '/fishnet',
  '/stop-page/album-upload',
  '/top-matches',
  '/crm-screening',
  '/my-shaadi/my-account',
  '/my-shaadi/my-profile',
  '/stop-page/selfie-verification',
  '/stop-page/app-migration',
  '/email-preferences',
  '/update-email',
  // following added so my profile page links will work properly
  '/my-shaadi/profile',
  '/my-shaadi/personal-profile',
  '/my-shaadi/astro',
  '/my-shaadi/astro/add',
  '/my-shaadi/profile/hobbies',
  '/my-shaadi/partner-profile',
  '/my-shaadi/contact-details',
  '/my-shaadi/photo/advance',

  // Upgrade to premium mobile Page
  '/upgrade-to-premium',
  '/my-shaadi',
  '/submit-story',
];

const detect = (pathname, search, width) => {
  if (!detection) {
    detection = {
      isMobile: device.getIsMobile(),
    };
  }
  if (!releasedMobilePages.some(p => p !== '/' && pathname.startsWith(p))) return 'desktop';
  if (width && width <= 720) return 'mobile';
  if (device.getIsMobile()) return 'mobile';
  return 'desktop';
};

let layout = null;

export default (pathname, search, width) => {
  layout = detect(
    pathname || window.location.pathname,
    search || window.location.search,
    width || window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  );
  return { ...detection, layout };
};
