import { Store } from '~/types/state';

export const recentChatInjectible = (store: Store): Promise<void> =>
  Promise.all([
    import(/* webpackChunkName: "recentChat.saga" */ './recentChat.saga'),
    import(/* webpackChunkName: "recentChat.reducer" */ './recentChat.reducer'),
  ]).then(([saga, reducer]) => {
    store.injectSaga('recentChat', saga.default);
    store.injectReducer('recentChat', reducer.default);
  });
