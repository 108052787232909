import cookie from 'cookie';
import api from '~/api';
// import fetchExperiments from '~/api/services/config/fetchExperiments';
import { memoize, clearDataCache } from '~/helpers/actionUtils';
import getMemberLogin from '~/helpers/getMemberLogin';
// import { PREFERRED_SEARCH_PROXY } from '~/helpers/experiments';
import { encode64, searchParams } from './utils';
import '~/api/controllers/PreferredMatchesProxyController';
import { waitFor } from '~/helpers/common';

export const SEARCH_PAGE_API = Symbol.for('@@SEARCH_PAGE_API');

export const clearSearchCache = (key: string) => clearDataCache(`${SEARCH_PAGE_API.toString()}:${key}`);

type Target = {
  path: string;
  query: string;
  changes: Record<string, any>;
  isMostPreferred: boolean;
  defaultFormat: string;
};

type SearchParams = {
  file_extension?: string;
  preferred_count: number;
  isMobile: boolean;
  changes: Record<string, any>;
  target: Target;
};

interface Options {
  selfUid?: string;
  params: SearchParams;
}

export const buildSearchParams = (params: SearchParams) => {
  // FIXME: 'any' used below to fix lint errors; needs to be typed correctly..
  const q: any = searchParams(params.target);

  q.changes = params.target.changes;
  q.isMobile = params.isMobile;

  q._t = new Date().getTime();
  q.request_id = encode64(q);

  q.file_extension = params.file_extension;
  q.preferred_count = params.preferred_count;

  const cookies = cookie.parse(document.cookie);
  q.xyz_serve = cookies.xyz_serve || 'true';

  return q;
};

export default (key: string) =>
  memoize(`${SEARCH_PAGE_API.toString()}:${key}`, async ({ params, selfUid = getMemberLogin() }: Options, config?: { CancelToken?: any }) => {
    const q = buildSearchParams(params);

    // NOTE: this is a hack to solve the authentication issues seen; this has to be fixed by waiting for auth to finish if not available by api routes
    await waitFor(0);

    try {
      const response = await api.get('/preferred_matches_proxy', { params: q }, config);

      return response;
    } catch ({ response }) {
      return response;
    }
  });
