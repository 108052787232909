/* eslint no-underscore-dangle: 0 */
import { AUTH_CACHE, AUTH_SUCCESS, AUTH_FAIL, UNAUTH, PREFERRED_SEARCH_FAIL, OTHER_SEARCH_FAIL } from 'actionTypes';
import { COOKIE_SUCCESS } from '../../actionTypes';

const initialState = false;
export default function(state = initialState, action) {
  switch (action.type) {
    case COOKIE_SUCCESS:
      return !(action.payload.abc || action.payload.ltabc || action.payload._alxm);
    case AUTH_CACHE:
    case AUTH_SUCCESS:
      return false;
    case AUTH_FAIL:
    case UNAUTH:
      return true;
    case PREFERRED_SEARCH_FAIL:
    case OTHER_SEARCH_FAIL:
      return action.payload.error.status === 401 ? true : state;
    default: {
      const { type, payload } = action;
      if (type && type.endsWith('_FAIL') && payload && payload.error && payload.error.status === 401) {
        return true;
      }
      return state;
    }
  }
}
