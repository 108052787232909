import { LAYER_SUCCESS, MODAL_SHOW, SHOW_DOWNLOAD_APP_LAYER } from 'actionTypes';
import guard from 'actions/lib/guard';
import constants from 'constants/constants';

export default ({ type, requestedLayer, data, dispatch, getState }) => {
  const { layer, layer: { layer_template = {}, name = '' } = {}, layerType, campaign_type, page } = data;
  if (Object.keys(layer).length > 0) {
    switch (requestedLayer) {
      case 'exitIntent': {
        console.log('%c Layer exitIntent', 'font-size: 16px; color: green', layer);
        const modals = {
          daily10: 'dailyRecommendations',
          pending_interest: 'pendingExitIntent',
        };
        if (layer.length >= 1) {
          dispatch({
            type: LAYER_SUCCESS,
            payload: { profiles: layer, layerId: 'exitIntent', modal: modals[layerType] },
          });
        } else {
          console.log(`%c ERROR, BAD Layer ${type}: ${requestedLayer} <${data.type}, ${campaign_type}>`, 'font-size: 16px; color: red', layer);
        }
        break;
      }
      case 'campaignLayer': {
        console.log('%c Layer campaignLayer', 'font-size: 16px; color: green', data);
        if (layer_template) {
          const layer_unique_id = `${layer.campaign_criteria_id}-${layer.id}`;

          // Trigger Custom UI for Download App Layer
          // FIXME: Think of making this logic scalable if more layers come up in future
          if (layer.campaign_criteria_id === constants.DOWNLOAD_APP_LAYER_ID) {
            dispatch({
              type: SHOW_DOWNLOAD_APP_LAYER,
            });
            break;
          }
          // End

          const { template = 'template1', image, mobileimage, mobilelink, altText = '', link } = layer_template;
          const { layout } = getState().view;
          let src = image;
          let url = link;
          if (data.campaign_type === 'mobile_image_layer') {
            src = mobileimage;
            url = mobilelink;
          }
          const modal_data = {
            loading: false,
            category: template,
            src,
            alt: altText,
            url,
            name,
          };

          const layerTypeKey = guard.layerBanner.getKey(layout);

          guard.layerBanner.update(layers => ({
            ...layers,
            [layerTypeKey]: layer_unique_id,
          }));

          dispatch({
            type: MODAL_SHOW,
            payload: { ...modal_data, layerId: 'campaignLayer', modal: 'campaignLayer' },
          });
        } else {
          console.log(`%c ERROR, BAD Layer ${type}: ${requestedLayer} <${data.type}, ${campaign_type}>`, 'font-size: 16px; color: red', data);
        }
        break;
      }
      case 'headerBadge': {
        console.log('%c Layer headerBadge', 'font-size: 16px; color: green', data);
        dispatch({ type: LAYER_SUCCESS, payload: { badge: layer, layerId: 'headerBadge' } });
        break;
      }
      case 'profilePageLeftBanner': {
        console.log('%c Layer profilePageLeftBanner', 'font-size: 16px; color: green', data);
        dispatch({ type: LAYER_SUCCESS, payload: { badge: layer, layerId: 'profilePageLeftBanner' } });
        break;
      }
      case 'profilePageOverlayBanner': {
        console.log('%c Layer profilePageOverlayBanner', 'font-size: 16px; color: green', data);
        dispatch({ type: LAYER_SUCCESS, payload: { badge: layer, layerId: 'profilePageOverlayBanner' } });
        break;
      }
      default:
        console.log(
          `%c TO DO Layer ${type}: ${requestedLayer} <${page || '-'}, ${data.type || '-'}, ${campaign_type || '-'}>`,
          'font-size: 16px; color: green',
          layer,
        );
    }
  } else {
    console.log(`%c Layer ${type}: ${requestedLayer} <${data.type}, ${campaign_type}> (none received)`, 'font-size: 16px; color: brown', layer.layer);
  }
};
