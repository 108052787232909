import React from 'react';
import HeaderLoader from 'partials/HeaderPartial/loading';
import Spinner from 'components/Spinner';

const LayoutLoader = () => (
  <main style={{ display: 'flex', height: '100%' }}>
    <HeaderLoader />
    <content style={{ display: 'flex', flex: '1 0 auto', alignItems: 'center', justifiyContent: 'center', paddingTop: '96px' }}>
      <Spinner />
    </content>
  </main>
);

export default LayoutLoader;
