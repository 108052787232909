/* eslint-disable prettier/prettier */
/* eslint camelcase: 0 */
import get from 'lodash/get';

import {
  PROFILE_LIST_REQUEST,
  PROFILES_BACKGROUND_REQUEST,
  PROFILE_LIST_SUCCESS,
  PROFILE_LIST_POSTSUCCESS,
  PROFILES_BACKGROUND_SUCCESS,
  PRELOAD_PROFILES_UIDS_EMPTY,
} from 'actionTypes';
import apiGetProfileList from '../apiGetProfileList';
import isDrPage from '~/helpers/isDrPage.ts';

const PREFETCH_DISTANCE_FORWARD = 3;
const PREFETCH_DISTANCE_BACKWARD = 2;

// Smaller implementations to save us some KBs
const noop = () => undefined;
const not = fn => a => !fn(a);
const includes = (arr, item) => arr.indexOf(item) !== -1;

const removeUndefinedKeys = element => element !== undefined;

// type Uid = string;
// type Params = { dispatch :: Function, getState :: Function, render :: Boolean, spinner :: Boolean };
// type Profile = Object

// filterLoadedProfiles :: Map<Profile> -> Array<Uid>
export const filterLoadedProfiles = profiles =>
  Object.keys(profiles).filter(isProfileLoaded(profiles));

// isProfileReady :: Map<Profile> -> Uid -> bool
export const isProfileReady = profiles => uid =>
  get(profiles, [uid, 'detailed', 'ready']);


const isProfilePreteching = prefetchedUids => uid => !prefetchedUids.includes(uid);

// isProfileLoaded :: Map<Profile> -> Uid -> bool
export const isProfileLoaded = profiles => uid =>
  !includes(['default', 'self'], uid) && !!get(profiles, [uid, 'uid']);

// onRequest :: Params -> Object -> ()
const onRequest = ({ dispatch, render = false, spinner = false }) => payload => {
  if (render) {
    dispatch({ type: PROFILE_LIST_REQUEST, payload: { ...payload, spinner } });
  } else {
    dispatch({ type: PROFILES_BACKGROUND_REQUEST, payload: { ...payload, spinner } });
  }
}

// onSuccess :: Params -> Object -> ()
const onSuccess = ({ dispatch, render = false }) => payload => {
  if (render) {
    dispatch({ type: PROFILE_LIST_SUCCESS, payload });
    dispatch({ type: PROFILE_LIST_POSTSUCCESS, payload });
  } else {
    dispatch({ type: PROFILES_BACKGROUND_SUCCESS, payload });
  }
};

// prefetchProfiles :: (Array<Uid>, Params) -> ()
export const prefetchProfiles = (uids, params) => {
  const { getState } = params;

  params.file_extension =
    getState().config.app.webp !== '0'
      ? 'webp'
      : params.file_extension;

  apiGetProfileList({ uids }, params, onRequest(params), onSuccess(params), noop, false);
};

// preloadProfileList :: (Uid, Params) -> ()
export default (uid, params, { forward = PREFETCH_DISTANCE_FORWARD, backward =PREFETCH_DISTANCE_BACKWARD, profileUids } = {}) => {
  const { getState, dispatch } = params;
  // FIXME: never pass around getState and dispatch; pass the exact state scope that is needed by the action
  const { profiles = [], dailyRecommendationPage: { recommendations: { items = [] }}, profilePage: { collection: { uids = [], prefetchedUids = [] } = {} } = {} } = getState();
  const isDRPage = isDrPage();

  let derivedUids = uids;
  if (profileUids) {
    derivedUids = profileUids;
  } else if (isDRPage) {
    const recommendationUids = items.map(p => p.uid);
    derivedUids = recommendationUids;
  }

  const index = derivedUids.indexOf(uid);
  if (index < 0 ) return;

  const lowerBoundIndex = Math.max(index - backward, 0);
  const upperBoundIndex = Math.min(index + forward, derivedUids.length); 

  if(upperBoundIndex - lowerBoundIndex > 0) {
    const neededUids = derivedUids // eslint-disable-line
      .slice(lowerBoundIndex, upperBoundIndex + 1) // + 1 is needed because slice is not inclusive of end index
      .filter(not(isProfileReady(profiles)))
      .filter(isProfilePreteching(prefetchedUids))
      .filter(removeUndefinedKeys);

    if (neededUids.length) {
      prefetchProfiles(neededUids, params);
    } else if (isDRPage) {
      dispatch({ type: PRELOAD_PROFILES_UIDS_EMPTY });
    }
  }
};
