// os, platform from query
import { parse } from 'qs';

const queryParams = parse(window.location.search.slice(1));

export const ua = window.navigator.userAgent;
const getUa = () => window.navigator.userAgent;

const regexForExtractingDevice = /^Mozilla\/\d.\d \([^;]*; [^;]*; ([^;]*?)(\)|;)/;

export const getDeviceName = userAgent => {
  let detectedDeviceName;
  try {
    const matchArray = regexForExtractingDevice.exec(userAgent);
    detectedDeviceName = matchArray ? matchArray[1] : 'No Device Info Found';
  } catch (e) {
    detectedDeviceName = 'Device Extraction Failed';
  }
  return detectedDeviceName.replace(/ Build.*/, '');
};

export const deviceName = getDeviceName(ua);

const networkEffectiveType = () => {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
  return connection.effectiveType || 'Undefined';
};

export const getDevicePerfBuckets = () => ({
  networkType: networkEffectiveType(),
  hardwareConcurrency: navigator.hardwareConcurrency,
  deviceMemory: navigator.deviceMemory,
});

const getBrowserVersion = () => {
  try {
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null)
        return tem
          .slice(1)
          .join(' ')
          .replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M[1];
  } catch (err) {
    return 'unknown';
  }
};

const device = {
  // Firefox 1.0+
  isFirefox: typeof InstallTrigger !== 'undefined',
  // Safari 3.0+ "[object HTMLElementConstructor]"
  isSafari:
    /constructor/i.test(window.HTMLElement) ||
    ((p = {}) => p.toString() === '[object SafariRemoteNotification]')(window.safari && window.safari.pushNotification),
  // Internet Explorer 6-11
  isIE: /* @cc_on!@ */ false || !!document.documentMode,
  // Chrome 1+
  isChrome: !!window.chrome && !!(window.chrome.webstore || window.chrome.app),
  isAndroid: !!ua.match(/Android/i),
  isBlackBerry: !!ua.match(/BlackBerry/i),
  isIPhone: !!ua.match(/iPhone/i),
  isIPod: !!ua.match(/iPod/i),
  isIPad: !!ua.match(/iPad/i),
  isOperaMini: ua.match(/Opera Mini/i) || false,
  isOpera: ua.match(/Opera Mini/i) || (!!window.opr && !!window.opr.addons) || !!window.opera || ua.indexOf(' OPR/') >= 0 || false,
  isWindows: !!ua.match(/IEMobile|WPDesktop|webOS|Windows Phone/i),
  canRotate: typeof window.orientation !== 'undefined',
  isSmallScreen: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 720,
  isVerySmallScreen: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 355,
  browser: {
    getBrowserName: () => {
      if (device.isOpera) {
        return 'opera';
      }
      if (device.isFirefox) {
        return 'firefox';
      }
      if (device.isSafari) {
        return 'safari';
      }
      if (device.isIE) {
        return 'ie';
      }
      if (device.isEdge) {
        return 'edge';
      }
      if (device.isChrome) {
        return 'chrome';
      }
      if (device.isBlink) {
        return 'blink';
      }
      return 'unknown';
    },
    isUC: ua.indexOf('UCBrowser') > 0,
    isIE: () => {
      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        this.version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        this.version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      return trident + msie > 0;
    },
    isEdge: () => {
      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
      const edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        this.version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }
      return edge > 0;
    },
  },
  getOS: () => {
    device.isWindows10();
    device.isWindows8();
    device.isWindows7();
    device.isWindowsVista();
    device.isWindowsXP();
    device.isWindows2000();
    device.isMacOriOS();
    device.isUNIX();
    device.isLinux();
    return device.os;
  },
  isWindows10: () => {
    if (ua.indexOf('Windows NT 10.0') !== -1) {
      device.os = 'Windows 10';
      return true;
    }
    return false;
  },
  isWindows8: () => {
    if (ua.indexOf('Windows NT 6.2') !== -1) {
      device.os = 'Windows 8';
      return true;
    }
    return false;
  },
  isWindows7: () => {
    if (ua.indexOf('Windows NT 6.1') !== -1) {
      device.os = 'Windows 7';
      return true;
    }
    return false;
  },
  isWindowsVista: () => {
    if (ua.indexOf('Windows NT 6.0') !== -1) {
      device.os = 'Windows Vista';
      return true;
    }
    return false;
  },
  isWindowsXP: () => {
    if (ua.indexOf('Windows NT 5.1') !== -1) {
      device.os = 'Windows XP';
      return true;
    }
    return false;
  },
  isWindows2000: () => {
    if (ua.indexOf('Windows NT 5.0') !== -1) {
      device.os = 'Windows 2000';
      return true;
    }
    return false;
  },
  isMacOriOS: () => {
    if (ua.indexOf('Mac') !== -1) {
      device.os = 'Mac/iOS';
      return true;
    }
    return false;
  },
  isUNIX: () => {
    if (ua.indexOf('X11') !== -1) {
      device.os = 'UNIX';
      return true;
    }
    return false;
  },
  isLinux: () => {
    if (ua.indexOf('Linux') !== -1) {
      device.os = 'Linux';
      return true;
    }
    return false;
  },
};
device.isIOS = device.isIPhone || device.isIPod || device.iPad || false;
// Edge 20+
device.isEdge = !device.isIE && !!window.StyleMedia;
// Blink engine detection
device.isBlink = (device.isChrome || device.isOpera) && !!window.CSS;
device.os = queryParams.os || device.getOS();
device.isMobile = device.isAndroid || device.isBlackBerry || device.isIOS || device.isOperaMini || device.isWindows || device.isSmallScreen;
// needed for resize
device.getIsSmallScreen = () => (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 720;
device.getIsMobile = () => {
  const newUa = getUa();
  return (
    !!newUa.match(/Android/i) ||
    !!newUa.match(/BlackBerry/i) ||
    !!newUa.match(/iPhone/i) ||
    !!newUa.match(/IPod/i) ||
    !!newUa.match(/iPad/i) ||
    !!newUa.match(/Opera Mini/i) ||
    !!newUa.match(/IEMobile|WPDesktop|webOS|Windows Phone/i) ||
    device.getIsSmallScreen()
  );
};
device.platform = device.isMobile ? 'mobile' : 'desktop';
device.isDesktop = !device.isMobile;
device.browser.name = device.browser.getBrowserName();
device.browser.version = getBrowserVersion();
device.deviceid = queryParams.deviceid
  ? `${queryParams.deviceid}|${device.platform}|${device.os}|${device.browser.name}|${device.browser.version}`
  : device.browser.name;
device.shortPlatform = device.isMobile ? 'wap' : 'web';

// type DeviceInfo = { ua: '', browser: {}, cpu: {}, device: {}, engine: {}, os: {} };

// parseUserAgent :: String -> Promise DeviceInfo
export const parseUserAgent = userAgent =>
  import(/* webpackChunkName: "useragent-parser" */ 'ua-parser-js')
    .then(UAParser => UAParser.default || UAParser)
    .then(UAParser => UAParser(userAgent));

// TODO: Make this a singleton
// getDeviceInfo :: String -> Promise DeviceInfo
export const getDeviceInfo = () => parseUserAgent(navigator.userAgent);

export const getMobileOperatingSystem = () => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(ua)) {
    return 'Windows Phone';
  }

  if (/android/i.test(ua)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export default device;
