import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "InboxPage.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "InboxPage.mobile" */ './InboxRevampAB'));

const InboxPage = ({ isMobileScreen, type, action }) =>
  isMobileScreen ? <Mobile type={type} action={action === 'filtered' ? 'pending' : action} /> : <Desktop action={action} type={type} />;

InboxPage.propTypes = {
  action: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(InboxPage);
