import { shouldRegisterServiceworker } from '~/constants/service-worker';
import { webpushRequestCookie } from '~/helpers/cookies';
import supports from './supports';

const emptyPromise = () => new Promise(() => undefined);

const createAsyncGuard = (predicate: (...args: any[]) => boolean | Promise<any>) => (...args: any[]): Promise<any> => {
  const result = predicate(...args);
  if (result && typeof (result as any).then === 'function') {
    return Promise.resolve(result);
  }
  return result ? Promise.resolve() : emptyPromise();
};

export const syncGuards: any = {
  checkCanFlushSubscription: () => syncGuards.checkShouldRegister() && syncGuards.checkPushNotifications(),
  checkRequestPushPermission: () =>
    syncGuards.checkHasNotRequestedPermission() &&
    syncGuards.checkCurrentPermission() &&
    syncGuards.checkShouldRegister() &&
    syncGuards.checkPushNotifications(),
  checkHasNotRequestedPermission: () => {
    const hasShownWebPushModal = webpushRequestCookie.get();
    return !hasShownWebPushModal;
  },
  checkCurrentPermission: () => supports.notifications() && Notification.permission === 'default',
  checkShouldRegister: () => supports.serviceWorker() && shouldRegisterServiceworker(),
  checkPushNotifications: () => supports.pushNotifications() && supports.notifications() && supports.idb(),
};

export const asyncGuards: any = {
  checkPushPermission: createAsyncGuard(() =>
    Promise.all([asyncGuards.checkShouldRegister(), asyncGuards.checkPushNotifications(), asyncGuards.checkCurrentPermission()]),
  ),
  checkRequestPushPermission: createAsyncGuard(syncGuards.checkRequestPushPermission),
  checkHasNotRequestedPermission: createAsyncGuard(syncGuards.checkHasNotRequestedPermission),
  checkCurrentPermission: createAsyncGuard(syncGuards.checkCurrentPermission),
  checkShouldRegister: createAsyncGuard(syncGuards.checkShouldRegister),
  checkPushNotifications: createAsyncGuard(syncGuards.checkPushNotifications),
};
