import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import NotFound from 'pages/NotFound';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Mobile = AsyncComponent(() => import(/* webpackChunkName: "ChatHistoryPage.mobile" */ './mobile'), Spinner);

const ChatHistoryPage = props => (props.isMobileScreen ? <Mobile /> : <NotFound />);

ChatHistoryPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(ChatHistoryPage);
