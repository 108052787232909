import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import monitorScroll from 'actions/onAppInit/monitorScroll';

const MonitorScroll = props => {
  useEffect(() => props.monitorScroll(), []);
  return null;
};

MonitorScroll.propTypes = {
  monitorScroll: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapActionToProps = { monitorScroll };

const composedHoc = compose(withRouter, connect(mapStateToProps, mapActionToProps), memo);

export default composedHoc(MonitorScroll);
