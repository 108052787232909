import React, { FC } from 'react';
import { connect } from 'react-redux';

import NotFound from '~/pages/NotFound';
import AsyncComponent from '~/components/AsyncComponent';
import Spinner from '~/components/Spinner';
import withLayoutDetection from '~/components/HOC/withLayoutDetection';

import { getMsiteFilteredOutChatBucket } from '~/helpers/experiments';
import { Settings } from '~/types/settings';
import { StateType } from '~/types/actions';

const Mobile = AsyncComponent(() => import(/* webpackChunkName: "RecentChatPage.mobile" */ './mobile'), Spinner);
const MobileB = AsyncComponent(() => import(/* webpackChunkName: "RecentChatPageB.mobile" */ './mobileB'), Spinner);

interface RecentChatPageProps {
  isMobileScreen: boolean;
  settings: Settings;
}

const RecentChatPage: FC<RecentChatPageProps> = ({ isMobileScreen = false, settings = { experiments: {} } }) => {
  const msiteFilteredOutChatBucket = getMsiteFilteredOutChatBucket(settings);
  if (!isMobileScreen) return <NotFound />;
  return msiteFilteredOutChatBucket === 'B' ? <MobileB /> : <Mobile />;
};

const mapStateToProps = (state: StateType) => {
  const { session = {} } = state;
  const { settings = {} } = session;
  return { settings };
};

export default withLayoutDetection(connect(mapStateToProps, null)(RecentChatPage));
