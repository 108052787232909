import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Mobile = AsyncComponent(() => import(/* webpackChunkName: "OrderSuccessPage.mobile" */ './mobile'), Spinner);
const Desktop = AsyncComponent(() => import(/* webpackChunkName: "OrderSuccessPage.desktop" */ './desktop'), Spinner);

const OrderSuccessPage = props => (props.isMobileScreen ? <Mobile /> : <Desktop />);

OrderSuccessPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(OrderSuccessPage);
