import { createMuiTheme } from '@material-ui/core/styles';
import { cond, constant } from 'helpers/common';
import isIE from 'helpers/deviceInfo/isIE';

export const themeObj = {
  typography: {
    fontFamily: '"Roboto", sans-serif',
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#ff0000',
      main: '#00bcd5',
      dark: '#0194a8',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiCircularProgress: {
      root: {
        zIndex: 3,
      },
    },
    MuiInput: {
      root: {
        display: 'block',
        '&$disabled': {
          '&:after': {
            borderBottom: '1px dashed #cdced1 !important',
          },
        },
        '&$error': {
          '&:after': {
            borderColor: '#f44336',
          },
        },
        '&$underline': {
          '&:before': {
            borderColor: '#cdced1',
          },
          '&&&&:hover:not($disabled):before': {
            borderColor: '#cdced1',
          },
          '&:after': {
            transform: 'scaleX(0)',
            borderColor: '#00bcd5',
            height: 2,
          },
        },
      },
      error: {},
      underline: {},
      input: {
        color: '#51505D',
        font: '400 16px/18px Roboto,sans-serif',
        width: '93%',
        padding: '4px 0 7px',
        caretColor: '#00bcd5',
      },
    },
    MuiButton: {
      root: {
        background: '#00bcd5',
        borderRadius: 3,
        border: 0,
        color: '#fff',
        height: 48,
        boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
        '&:hover': {
          backgroundColor: '#0194a8 !important',
        },
        a: {
          color: 'red',
        },
        '&$disabled': {
          background: '#0194a8 !important',
        },
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
        textAlign: 'left',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#51505d',
        font: ' 300 16px Roboto,sans-serif',
        padding: '0',
        lineHeight: 'normal',
        '&$disabled': {
          color: '#cdced1 !important',
          transform: 'translate(0, 24px) scale(1) !important',
          '&$disabled': {
            color: '#cdced1 !important',
          },
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        font: ' 300 14px Roboto,sans-serif',
        color: '#72727D',
        transform: `translate(0, ${isIE() ? 1.25 : -0.5}px) scale(1) !important`,
      },
      formControl: {
        transform: `translate(0, ${isIE() ? 20 : 21}px) scale(1) !important`,
      },
    },
    MuiChip: {
      root: {
        margin: '0 5px 5px 0',
      },
    },
    MuiPaper: {
      root: {
        background: '#f1f1f2',
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: '#83E1ED',
      },
    },
  },
};

const theme = createMuiTheme({
  ...themeObj,
  palette: {
    ...themeObj.palette,
    primary: {
      light: '#ff0000',
      main: '#0194a8',
      dark: '#0194a8',
      contrastText: '#fff',
    },
  },
  overrides: {
    ...themeObj.overrides,
    MuiCheckbox: {
      root: {
        color: '#cdced1',
      },
    },
  },
});

const FPtheme = createMuiTheme({
  ...themeObj,
  overrides: {
    ...themeObj.overrides,
    MuiInputLabel: {
      ...themeObj.overrides.MuiInputLabel,
      shrink: {
        ...themeObj.overrides.MuiInputLabel.shrink,
        color: '#51505d',
      },
    },
    MuiInput: {
      ...themeObj.overrides.MuiInput,
      input: {
        color: '#41404d',
      },
    },
  },
});

const msiteProfileCreationFormTheme = createMuiTheme({
  ...themeObj,
  palette: {
    ...themeObj.palette,
    primary: {
      light: '#ff0000',
      main: '#0194a8',
      dark: '#0194a8',
      contrastText: '#fff',
    },
  },
  overrides: {
    ...themeObj.overrides,
    MuiButton: {
      ...themeObj.overrides.MuiButton,
      root: {
        ...themeObj.overrides.MuiButton.root,
        '&:hover': {
          background: '#00bcd5 !important',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#cdced1',
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          fontSize: '13px',
          fontWeight: '500',
        },
      },
    },
  },
});

const msiteForgotPassWordFormTheme = createMuiTheme({
  ...themeObj,
  overrides: {
    ...themeObj.overrides,
    MuiFormLabel: {
      ...themeObj.overrides.MuiFormLabel,
      root: {
        ...themeObj.overrides.MuiFormLabel.root,
        font: '300 4.44vw Roboto,sans-serif',
        fontSize: '4.44vw !important',
        '&$focused': {
          ...themeObj.overrides.MuiFormLabel.root['&$focused'],
          fontSize: '4.44vw !important',
        },
      },
    },
    MuiInput: {
      ...themeObj.overrides.MuiInput,
      root: {
        ...themeObj.overrides.MuiInput.root,
      },
      input: {
        ...themeObj.overrides.MuiInput.input,
        padding: '0.3em 0 0.3em 0',
        fontSize: '4.44vw',
        color: '#41404d',
      },
      formControl: {
        ...themeObj.overrides.MuiInput.formControl,
        marginTop: '1.15em !important',
      },
    },

    MuiInputLabel: {
      ...themeObj.overrides.MuiInputLabel,
      shrink: {
        ...themeObj.overrides.MuiInputLabel.shrink,
        font: ' 300 5.2vw Roboto,sans-serif !important',
        color: '#51505d',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '4vw',
        fontWeight: '300',
        lineHeight: '1.3em',
      },
    },
  },
});

msiteForgotPassWordFormTheme.overrides.MuiFormLabel.focused = {
  color: '#0194a8 !important',
};

msiteForgotPassWordFormTheme.overrides.MuiFormLabel.error = {
  color: '#e53a41 !important',
};

const tabCapsuleTheme = createMuiTheme({
  ...themeObj,
  overrides: {
    ...themeObj.overrides,
    MuiButton: {
      ...themeObj.overrides.MuiButton,
      root: {
        ...themeObj.overrides.MuiButton.root,
      },
    },
  },
});

const dropdownFieldObj = createMuiTheme({
  ...themeObj,
  overrides: {
    ...themeObj.overrides,
    MuiInput: {
      ...themeObj.overrides.MuiInput,
      input: {
        ...themeObj.overrides.MuiInput.input,
        color: '#51505d',
        width: '90%',
        padding: '1px 22px 0 16px',
        border: '1px solid #dfe0e3 !important',
        borderRadius: '6px',
        height: '44px',
      },
      underline: {
        '&:before': { border: 'none !important' },
      },
    },
  },
});

const rogMobileRepetationPage = createMuiTheme({
  ...themeObj,
  overrides: {
    ...themeObj.overrides,
    MuiInput: {
      ...themeObj.overrides.MuiInput,
      input: {
        ...themeObj.overrides.MuiInput.input,
        border: '1px solid #cdced1',
        borderRadius: '4px',
        width: '100%',
        height: '88px',
        color: '#51505d',
        fontSize: '14px',
        fontWeight: '400',
        padding: '12px 14px',
        boxSizing: 'border-box',
      },
    },
  },
});

const rogMobileRepetationPageWeb = createMuiTheme({
  ...themeObj,
  overrides: {
    ...themeObj.overrides,
    MuiInput: {
      ...themeObj.overrides.MuiInput,
      input: {
        ...themeObj.overrides.MuiInput.input,
        border: '1px solid #cdced1',
        borderRadius: '4px',
        width: '100%',
        height: '110px',
        color: '#51505d',
        fontSize: '16px',
        fontWeight: '400',
        padding: '12px 14px',
        boxSizing: 'border-box',
      },
    },
  },
});

const theme2 = createMuiTheme({
  ...theme,
  singleDropdown: {
    // Use the system font over Roboto.
    font: '300 12px Roboto,sans-serif',
    padding: '5px 10px',
  },
  overrides: {
    MuiButton: {
      root: {
        background: 'red',
        [theme.breakpoints.down('md')]: {
          background: '#95959d',
        },
        '&:hover': {
          background: '#00bcd5 !important',
        },
      },
    },
  },
});

const theme3 = createMuiTheme({
  ...theme,

  overrides: {
    ...theme.overrides,
    MuiInput: {
      ...theme.overrides.MuiInput,
      input: {
        ...theme.overrides.MuiInput.input,
        width: '90%',
      },
    },
  },
});

const drawerTextFieldObj = { ...themeObj };

drawerTextFieldObj.overrides.MuiInput.root = {
  ...drawerTextFieldObj.overrides.MuiInput.root,
  '&$disabled': {
    '&:before': {
      border: 'none !important',
      backgroundImage: 'linear-gradient(to right, rgb(205, 206, 209) 54%, rgb(255, 255, 255) 0%) !important',
      backgroundPosition: 'bottom !important',
      backgroundSize: '13px 1px !important',
      backgroundRepeat: 'repeat-x !important',
    },
  },
};

drawerTextFieldObj.overrides.MuiFormLabel.root = {
  color: '#41404d',
  font: ' 300 16px Roboto,sans-serif',
  padding: '0',
  display: 'block',
  '&$focused': {
    color: '#51505d !important',
  },
};

drawerTextFieldObj.overrides.MuiInputLabel = {
  shrink: {
    font: '300 14px/18px Roboto,sans-serif',
    color: '#72727d',
    transform: `translate(0, ${isIE() ? 1.25 : 1.5}px) scale(1) !important`,
  },
};

const drawerTextField = createMuiTheme(drawerTextFieldObj);

const drawerFieldThemeObj = {
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiChip: themeObj.overrides.MuiChip,
    MuiInput: {
      root: {
        flexWrap: 'wrap',
        minHeight: '40px',
        color: '#fff',
        fontWeight: 400,
        fontSize: '16px',
      },
      input: {
        padding: 0,
        '&::placeholder': {
          color: '#fff',
          fontSize: '16px',
          fontWeight: 300,
          opacity: 0.9,
        },
      },
    },
  },
};

const drawerFieldTheme = createMuiTheme(drawerFieldThemeObj);

const drawerThemeObj = { ...themeObj };

drawerThemeObj.overrides.MuiModal = {
  root: {
    position: 'absolute',
  },
};

drawerThemeObj.overrides.MuiBackdrop = {
  root: {
    height: '100vh',
  },
};

drawerThemeObj.overrides.MuiMenuItem = {
  '&$selected': {
    backgroundColor: '#83E1ED !important',
  },
};

drawerThemeObj.overrides.MuiListItem = {
  button: {
    '&:hover': {
      backgroundColor: '',
    },
  },
};

const drawerTheme = createMuiTheme(drawerThemeObj);
const profileCreationDrawer = createMuiTheme({
  root: {},
  titleBar: {
    backgroundColor: '#ff5a60',
  },
  searchableTitleBarRoot: {
    backgroundColor: '#ff5a60',
    boxShadow: '0 2px 2px rgba(0,0,0,0.24), 0 0 2px rgba(0,0,0,0.1)',
    padding: '6px',
    minHeight: '40px',
    maxWidth: `calc(90vw - 12px)`,
  },
  searchableTitleBar: {
    backgroundColor: '#d84c51',
    boxShadow: 'none',
    maxWidth: 'calc(90vw - 12px)',
    minHeight: '40px',
    borderRadius: '5px',
  },
  searchTextLabel: {
    minHeight: '40px',
  },
  title: {
    color: '#fff',
    font: ' 500 16px Roboto,sans-serif',
  },
  textField: {},
  selectedChips: {},
  options: {
    paddingLeft: '45px',
    color: '#41404d',
    fontSize: '14px',
  },

  'option-highlighter': {
    margin: 0,
  },
  'Multi-option-highlighter': {
    margin: ' 0 0 0 14px',
  },
  'option-selected': {
    backgroundColor: '#0194a8',
    color: '#fff',
  },
  'Multi-options': {
    justifyContent: 'left',
    padding: '10px 0 10px 14px',
  },

  'Multi-options-category': {
    margin: '0',
  },
  rippleRoot: {
    width: '30px',
    flex: 'none',
    paddingLeft: '15px',
  },
  backBtnRipple: {
    '&&': { background: 'transparent', height: '40px' },
  },
  list_category: {
    fontSize: '14px',
    color: '#72727d',
    textTransform: 'uppercase',
  },
  backBtn: {
    opacity: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderWidth: '0 2px 2px 0',
    borderRadius: '0 2px 0 2px',
    height: '8px',
    padding: 0,
    width: '8px',
    transform: `rotate(135deg)`,

    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
  },
});
const profileCreationDropdown = createMuiTheme({
  'Multi-options': {
    paddingTop: '7px',
    paddingBottom: '7px',
  },
});

const textareaHighlightingTheme = ({ isFocused = false, canShowError = false, isReadOnly = false, errorMsg = {}, textArea = {}, common = {} }) => ({
  common: {
    ...common,
  },
  textArea: {
    borderColor: cond([
      [constant(isReadOnly), constant(textArea.isReadOnly || '#cdced1')],
      [constant(canShowError), constant('#e53a41')],
      [constant(isFocused), constant('#00bcd5')],
      [constant(true), constant(textArea.default || '#cdced1')],
    ]),
    caretColor: cond([
      [constant(canShowError), constant('#e53a41')],
      [constant(true), constant('#00bcd5')],
    ]),
    ...textArea,
  },
  textHtml: {},
  errorMsg: {
    ...errorMsg,
  },
  highlightArea: {},
});
export {
  theme,
  theme2,
  theme3,
  drawerTextField,
  drawerFieldTheme,
  drawerTheme,
  FPtheme,
  msiteProfileCreationFormTheme,
  tabCapsuleTheme,
  dropdownFieldObj,
  msiteForgotPassWordFormTheme,
  profileCreationDrawer,
  profileCreationDropdown,
  textareaHighlightingTheme,
  rogMobileRepetationPage,
  rogMobileRepetationPageWeb,
};
