import { LAYER_REQUEST, LAYER_FAIL } from 'actionTypes';
import api from 'api';
import errors from 'actions/lib/errors';
import '~/api/controllers/LayersController';

export default (requestedLayers, { dispatch, getState, type, source }, onSuccess) => {
  dispatch({ type: LAYER_REQUEST, payload: { type, source } });
  const { layout } = getState().view;
  api
    .get('/layers/me', { params: { types: requestedLayers, layout } })
    .then(response => {
      Object.keys(response.data).forEach(requestedLayer =>
        onSuccess({ source, type, requestedLayer, data: response.data[requestedLayer], dispatch, getState }),
      );
    })
    .catch(error => {
      dispatch({ type: LAYER_FAIL, payload: errors.clean(error) });
    });
};
