import { parse } from 'qs';
import { VERSION as SW_VERSION, shouldRegisterServiceworker } from '~/constants/service-worker';

import device from '~/helpers/device';
import { match } from '~/helpers/common';
import { logError as logErrorOnRollbar } from '~/helpers/rollbar';
import getMemberLogin from '~/helpers/getMemberLogin';
import { retrievePendingEvents } from '~/helpers/idb/sw/sw-pending-events';
import { trackSwEvent } from '~/actions/lib/utils/da';

// logSwError :: Error|string -> ()
export const logSwError = e => {
  const error = typeof e === 'string' ? new Error(e) : e;
  logErrorOnRollbar(`PWAError: ${error.message}`, error);
};

export function track(data) {
  const { action, version = SW_VERSION, ...event_info } = data;

  return trackSwEvent({
    action,
    memberlogin: `${getMemberLogin() || ''}`,
    version: `${version}`,
    platform: `${device.platform}`,
    os: `${device.os}`,
    browser_name: `${device.browser.name}`,
    browser_version: `${device.browser.version}`,
    user_agent: navigator.userAgent,
    event_info,
  });
}

// handleEvent :: ({ data: any }) -> ()
export function handleEvent({ data } = {}) {
  if (!data) return null;
  return match({
    track: () => track(data),
    error: () => logSwError(data.message),
  })(data.type);
}

const enableTracking = sw =>
  sw.withInstance(async serviceWorker => {
    const getATHEvent = selection => ({
      action: `pwa:add_to_homescreen_${selection}`,
      selection,
    });

    try {
      // Service worker fired events
      serviceWorker.onmessage = handleEvent;

      window.addEventListener('appinstalled', () =>
        handleEvent({
          data: {
            type: 'track',
            ...getATHEvent('installed'),
          },
        }),
      );

      // Install prompt tracking
      window.addEventListener('beforeinstallprompt', async event => {
        try {
          // Don't show add to home screen prompt on mobile devices
          // since we are showing custom smartbanner
          if (device.isMobile || !shouldRegisterServiceworker()) {
            event.preventDefault && event.preventDefault();
            return;
          }

          const selection = await event.userChoice;

          handleEvent({
            data: {
              type: 'track',
              ...getATHEvent(selection.outcome),
            },
          });
        } catch (error) {
          logSwError(error);
        }
      });

      // Pending events
      const events = (await retrievePendingEvents()) || [];
      events.forEach(handleEvent);

      // Track entry into the page
      const query = parse(window.location.search.slice(1));
      if (query.source === 'homescreen') {
        return handleEvent({
          data: {
            type: 'track',
            action: 'pwa:open_homescreen',
          },
        });
      }
    } catch (e) {
      logSwError(e);
    }

    return null;
  });

export default enableTracking;
