import React, { memo, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ReactReduxContext } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { history as historyPropTypes } from 'PropTypes';
import onAppInit, { apiGetLoggerinfo } from 'actions/onAppInit';
import ContextProvider from 'components/Common/Context';
import theme from 'theme';
import getModulePageName from 'reducers/session/getModulePageName';
import { StylesProvider } from '@material-ui/core/styles';
import ApiServiceSingleton from '@shaaditech/api-services/lib/apiServiceSingleton';
import cookie from 'cookie';

const authenticationInfo = () => {
  const cookieObj = cookie.parse(document.cookie);
  return cookieObj;
};

ApiServiceSingleton.storeAppInfo(authenticationInfo());
const { Container } = theme;

const ShaadiApp = props => {
  const { store } = useContext(ReactReduxContext);
  const { children, needMainPadding, layout, canShowLayerPartial, history, apiGetLoggerinfo: getLoggerData, onAppInit: onAppInitialization } = props;
  useEffect(
    () => {
      const onContextMenu = () => getModulePageName().canAllowRightClick && layout === 'mobile';
      if (getModulePageName().isShaadiEventFeedbackPage) {
        getLoggerData();
      } else {
        onAppInitialization(history);
      }
      document.body.oncontextmenu = onContextMenu;
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <StylesProvider injectFirst>
      <ThemeProvider
        theme={{
          needMainPadding,
          contentDisplay: (getModulePageName().isProfileCreationPage || getModulePageName().isForgotPasswordPage) && 'none',
        }}
      >
        <ContextProvider dispatch={store.dispatch} layout={layout} canShowLayerPartial={canShowLayerPartial} history={history}>
          <Container>{children}</Container>
        </ContextProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

ShaadiApp.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape(historyPropTypes).isRequired,
  onAppInit: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired,
  needMainPadding: PropTypes.bool.isRequired,
  canShowLayerPartial: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ view: { layout }, session: { needMainPadding, canShowLayerPartial } }) => ({
  layout,
  needMainPadding,
  canShowLayerPartial,
});

const mapActionToProps = { onAppInit, apiGetLoggerinfo };

const composedHoc = compose(withRouter, connect(mapStateToProps, mapActionToProps), memo);

export default composedHoc(ShaadiApp);
