import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncComponent from '../../components/AsyncComponent';
import PageLoadSpinner from '../../components/PageLoadSpinner';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "ProfilePage.desktop" */ './desktop'), PageLoadSpinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "ProfilePage.mobile" */ './mobile'), PageLoadSpinner);

const ProfilePage = ({ layout, isSelfProfilePreview }) => (layout === 'desktop' || isSelfProfilePreview ? <Desktop /> : <Mobile />);

ProfilePage.propTypes = {
  layout: PropTypes.string.isRequired,
  isSelfProfilePreview: PropTypes.bool.isRequired,
};

const selector = state => ({
  layout: state.view.layout,
  isSelfProfilePreview: state.session.isSelfProfilePreview,
});

export default connect(selector, {})(ProfilePage);
