import { parse } from 'qs';

import {
  ROUTE_CHANGE,
  CONTACT_EOI_SUCCESS,
  UNAUTH,
  SESSION_SUCCESS,
  SHORTLIST_CREATE_SUCCESS,
  AUTH_SUCCESS,
  AUTH_FAIL,
  SESSION_FAIL,
  COUNTS_FAIL,
  CHATS_DATA_FAIL,
  LAYER_SUCCESS,
  PREFERRED_SEARCH_FAIL,
  OTHER_SEARCH_FAIL,
  CART_FAIL,
  CART_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  PREMIUM_BANNNER_CACHE,
  PREMIUM_BANNNER_SUCCESS,
  FORGOT_PASSWORD_OTP_SEND,
  FP_OTP_VALIDATION_REQUEST,
  FORGOT_PASSWORD_OTP_REQUEST,
  FP_OTP_VALIDATION_SUCCESS,
  FP_OTP_VALIDATION_FAIL,
  FORGOT_PASSWORD_OTP_FAIL,
  GUEST_TOKEN_CACHE,
  GUEST_TOKEN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  GET_EXPERIEMENT_ACTION_SUCCESS,
  GET_PROFILE_PHOTO_SUCCESS,
  GET_SELF_PROFILE_SUCCESS,
  GET_SELF_PROFILE_FAIL,
  GET_SELF_PROFILE_REQUEST,
  GET_PROFILE_PHOTO_FAIL,
  GET_PROFILE_PHOTO_REQUEST,
  COOKIE_SUCCESS,
  HAS_HOROSCOPE,
  HAS_NET_CONNECTIVITIY,
  LOST_NET_CONNECTIVITIY,
  BOTTOM_EOI_TOGGLE,
  SMART_BANNER_TOGGLE,
  BASIC_PROFILE_DETAILS_SUCCESS,
  PROFILE_MOBILE_VERIFY_STATUS_SUCCESS,
  PROFILE_MOBILE_VERIFY_STATUS_FAIL,
  SESSION_CACHE,
  UPDATE_LAYER_COUNT,
  DRAFT_SETTING_SUCCESS,
  UPDATE_DRAFT_SETTING_STATUS,
  LOGIN_WITH_OTP_REQUEST,
  LOGIN_WITH_OTP_SENT,
  LOGIN_WITH_OTP_FAIL,
  PAYMENT_SESSION_SUCCESS,
  PAYMENT_SESSION_FAIL,
  LOGEER_EXP_DATA_SUCCESS,
} from 'actionTypes';

import getIsNative from 'helpers/isNative';

import settings from 'reducers/session/settings';
import shortlists from 'reducers/session/shortlists';
import getIsLoggedIn from 'reducers/session/isLoggedIn';
import exitIntent from 'reducers/session/exitIntent';
import getIsLoggedOut from 'reducers/session/isLoggedOut';
import getModulePageName from 'reducers/session/getModulePageName';
import searchPremiumBanner from 'reducers/session/searchPremiumBanner';

const urlGetParams = window.location.search.slice(1);
const isNative = getIsNative();
const queryParams = parse(urlGetParams);
const goUrl = isNative ? 'http://native_app_fake_url' : queryParams.nextLiteAppRegUrl ? urlGetParams.replace('nextLiteAppRegUrl=', '') : '/my-shaadi';

const initialState = {
  uid: undefined,
  document: {
    title: '',
  },
  experiments: {},
  areExperimentsFetched: false,
  exitIntent: exitIntent(undefined, {}),
  settings: settings(undefined, {}),
  shortlists: shortlists(undefined, {}),
  isLoggedIn: getIsLoggedIn(undefined, {}),
  isLoggedOut: getIsLoggedOut(undefined, {}),
  searchPremiumBanner: searchPremiumBanner(undefined, {}),
  nextUrl: goUrl,
  isNative,
  isStoppage: false,
  canShowSkip: false,
  canShowChat: false,
  canShowLayerPartial: false,
  isUserFetchComplete: false,
  canRedirect: false,
  queryParams,
  rogStatus: {},
  bottomEoiVisible: false,
  smartBannerVisible: true,
  isFilterList: false,
  guest: {
    hasOTPsend: false,
    isOTPVerified: false,
    exit: false,
    OTPtoken: '',
    OTPsuccessTxt: '',
    accessToken: '',
    username: '',
    otp: '',
  },
  user: {
    isLoggedIn: getIsLoggedIn(undefined, {}),
    isLoggedOut: getIsLoggedOut(undefined, {}),
    errusr: '',
    uid: '',
    gender: '',
    firstName: '',
    lastName: '',
    religion: '',
    motherTongue: '',
    country: '',
    complexion: '',
    built: '',
    height: '',
    weight: '',
    diet: '',
    drink: '',
    smoke: '',
    aboutMe: '',
    hasProfilePhoto: false,
    photos: {},
    heShe: '',
    himHer: '',
    hisHer: '',
    mrMs: '',
    heSheOrYou: '',
    himHerOrYou: '',
    hisHerOrYou: '',
    mrMsOrYou: '',
    heSheOrYour: '',
    himHerOrYour: '',
    hisHerOrYour: '',
    mrMsOrYour: '',
    doOrDoes: '',
    ptnr: '',
    sessionId: '',
    siteDisplay: '',
    litem: 'false',
    slang: 'en-US',
    isMobileNoVerify: '',
  },
  ...getModulePageName(),
  mobileHoroscopeTitle: 'Add Horoscope Details',
  cookieSet: false,
  hasNet: true,
};

const setUserLoggedInOrOut = (state, action) => {
  const isLoggedIn = getIsLoggedIn(state.isLoggedIn, action);
  const isLoggedOut = getIsLoggedOut(state.isLoggedOut, action);
  return {
    isLoggedIn,
    isLoggedOut,
    user: {
      ...state.user,
      isLoggedIn,
      isLoggedOut,
    },
  };
};

export default function(state = initialState, action) {
  const { type, payload = {} } = action;
  switch (type) {
    case BOTTOM_EOI_TOGGLE: {
      return {
        ...state,
        bottomEoiVisible: payload.visible,
        isFilterList: payload.isFilterList ? payload.isFilterList : false,
      };
    }
    case SMART_BANNER_TOGGLE: {
      return {
        ...state,
        smartBannerVisible: payload.visible,
      };
    }
    case HAS_HOROSCOPE: {
      if (!payload.hasAstro) return state;
      return {
        ...state,
        mobileHoroscopeTitle: 'View/Edit Horoscope Details',
      };
    }
    case ROUTE_CHANGE: {
      const modulePageNames = getModulePageName();
      const canShowSkipPageWise = !modulePageNames.isProfileCreationPage && !/^\/stop-page\/phone-setting/i.test(payload.pathname);

      return {
        ...state,
        ...modulePageNames,
        canRedirect: false,
        canShowSkip: canShowSkipPageWise,
      };
    }
    case CONTACT_EOI_SUCCESS:
      return {
        ...state,
        settings: settings(state.settings, action),
      };
    case UNAUTH:
      return {
        ...initialState,
        ...setUserLoggedInOrOut(state, action),
        cookieSet: state.cookieSet,
      };
    case 'RELEVANT_EVENT_DETAILS_SET_STATE':
    case SESSION_CACHE:
    case SESSION_SUCCESS:
      return {
        ...state,
        ...payload.auth,
        shortlists: shortlists(state.shortlists, { ...action, payload: payload.shortlists }),
        settings: settings(state.settings, { ...action, payload }),
        searchPremiumBanner: searchPremiumBanner(state.searchPremiumBanner, action),
        ...setUserLoggedInOrOut(state, action),
      };
    case LOGEER_EXP_DATA_SUCCESS:
    case PAYMENT_SESSION_SUCCESS:
      return {
        ...state,
        settings: settings(state.settings, { ...action, payload }),
        ...setUserLoggedInOrOut(state, action),
      };
    case SHORTLIST_CREATE_SUCCESS:
      return {
        ...state,
        shortlists: shortlists(state.shortlists, action),
        ...setUserLoggedInOrOut(state, action),
      };
    case AUTH_SUCCESS:
    case AUTH_FAIL:
    case SESSION_FAIL:
    case PAYMENT_SESSION_FAIL:
    case COUNTS_FAIL:
    case CHATS_DATA_FAIL:
    case LAYER_SUCCESS:
      return {
        ...state,
        exitIntent: exitIntent(state.exitIntent, action),
        ...setUserLoggedInOrOut(state, action),
      };
    case PREFERRED_SEARCH_FAIL:
    case OTHER_SEARCH_FAIL:
    case CART_FAIL:
    case CART_SUCCESS:
      return {
        ...state,
        ...setUserLoggedInOrOut(state, action),
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        settings: settings(state.settings, { ...action, payload }),
      };
    case PREMIUM_BANNNER_CACHE:
    case PREMIUM_BANNNER_SUCCESS:
      return {
        ...state,
        searchPremiumBanner: searchPremiumBanner(state.searchPremiumBanner, action),
      };
    case FORGOT_PASSWORD_OTP_SEND: {
      return {
        ...state,
        guest: {
          ...state.guest,
          hasOTPsend: true,
          OTPtoken: payload.token,
          OTPsuccessTxt: payload.message,
        },
      };
    }
    case FP_OTP_VALIDATION_REQUEST: {
      const { otp = '' } = payload;
      return {
        ...state,
        guest: {
          ...state.guest,
          otp,
        },
      };
    }
    case FORGOT_PASSWORD_OTP_REQUEST: {
      const { username = '' } = payload;
      return {
        ...state,
        guest: {
          ...state.guest,
          hasOTPsend: false,
          isOTPVerified: false,
          username,
        },
      };
    }
    case FP_OTP_VALIDATION_SUCCESS: {
      return {
        ...state,
        guest: {
          ...state.guest,
          isOTPVerified: true,
        },
      };
    }
    case FP_OTP_VALIDATION_FAIL: {
      return {
        ...state,
        guest: {
          ...state.guest,
          isOTPVerified: false,
        },
      };
    }

    case FORGOT_PASSWORD_OTP_FAIL: {
      return {
        ...state,
        guest: {
          ...state.guest,
          hasOTPsend: false,
          isOTPVerified: false,
        },
      };
    }

    case LOGIN_WITH_OTP_REQUEST: {
      const { username = '' } = payload;
      return {
        ...state,
        guest: {
          ...state.guest,
          hasOTPsend: false,
          isOTPVerified: false,
          username,
        },
      };
    }
    case LOGIN_WITH_OTP_FAIL: {
      return {
        ...state,
        guest: {
          ...state.guest,
          hasOTPsend: false,
          isOTPVerified: false,
        },
      };
    }
    case LOGIN_WITH_OTP_SENT: {
      return {
        ...state,
        guest: {
          ...state.guest,
          hasOTPsend: true,
          // OTPtoken: payload.token,
          OTPsuccessTxt: payload.text_message,
        },
      };
    }

    case GUEST_TOKEN_CACHE:
    case GUEST_TOKEN_SUCCESS: {
      const { guest_token } = payload;
      const uid = String(guest_token).split('|')[1];
      return {
        ...state,
        accessToken: guest_token,
        user: { ...state.user, uid },
        guest: {
          ...state.guest,
          accessToken: guest_token,
        },
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        accessToken: '',
        user: { ...state.user, uid: '' },
      };
    }
    case COOKIE_SUCCESS: {
      const { reg_logger, abc, mid, misc2 = '', slang = 'en-US', litem = 'false' } = payload;
      const accessToken = getModulePageName().isRegLoggerPage ? reg_logger : abc;
      const uid = String(accessToken).split('|')[1];
      const siteDisplay = (misc2.match(/s:7:"[a-z]*";/) || [''])[0].split('"')[1] || '';
      return {
        ...state,
        deviceId: mid,
        mid,
        siteDisplay,
        accessToken,
        firstPtnr: payload.first_ptnr,
        sessionId: payload.ltabc,
        errusr: payload.ust || '',
        ptnr: payload.first_ptnr,
        user: { ...state.user, uid, slang, litem },
        guest: {
          ...state.guest,
          exit: abc && abc !== '' && abc !== 'SatyaNet',
        },
        cookieSet: true,
      };
    }
    case GET_EXPERIEMENT_ACTION_SUCCESS: {
      const { data: { experiment: experiments = {} } = {} } = payload.response.data;
      return {
        ...state,
        experiments,
        areExperimentsFetched: true,
      };
    }
    case GET_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          photos: { ...payload.data },
        },
      };
    case GET_SELF_PROFILE_SUCCESS: {
      return {
        ...state,
        isUserFetchComplete: true,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    }
    case BASIC_PROFILE_DETAILS_SUCCESS: {
      const { gender, country } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          gender,
          country,
        },
      };
    }
    case PROFILE_MOBILE_VERIFY_STATUS_SUCCESS:
    case PROFILE_MOBILE_VERIFY_STATUS_FAIL: {
      const { isMobileNoVerify } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          isMobileNoVerify,
        },
      };
    }
    case GET_SELF_PROFILE_FAIL:
      return {
        ...state,
        isUserFetchComplete: true,
      };
    case LOST_NET_CONNECTIVITIY:
      return { ...state, hasNet: false };
    case HAS_NET_CONNECTIVITIY:
      return { ...state, hasNet: true };
    case DRAFT_SETTING_SUCCESS:
    case UPDATE_DRAFT_SETTING_STATUS:
    case UPDATE_LAYER_COUNT:
      return { ...state, settings: settings(state.settings, action) };

    case GET_SELF_PROFILE_REQUEST:
    case GET_PROFILE_PHOTO_FAIL:
    case GET_PROFILE_PHOTO_REQUEST:
    default:
      return state;
  }
}
