/* eslint-disable prettier/prettier */
import { compose } from 'redux';
import { taggedSum } from 'daggy';
import { parse } from 'qs';
import { constant, cata } from 'helpers/common';

// :: PageType
export const PageType = taggedSum('PageType', {
  Shortlisted: [],
  Viewed: [],
  Blocked: [],
  Ignored: [],
});

// getHeadingText :: PageType -> String
export const getHeadingText = cata({
  Shortlisted: constant('Your shortlisted profiles'),
  Viewed: constant('Profiles you have viewed'),
  Blocked: constant('Profiles you have blocked'),
  Ignored: constant('Profiles you have ignored'),
});

// getPageTitle :: PageType -> String
export const getPageTitle = cata({
  Shortlisted: constant('My Shortlisted profiles - Free Matrimonial - Shaadi.com'),
  Viewed: constant('Viewed profiles - Free Matrimonial - Shaadi.com'),
  Blocked: constant('Blocked profiles - Free Matrimonial - Shaadi.com'),
  Ignored: constant('Ignored profiles - Free Matrimonial - Shaadi.com'),
});

// setPageTitle :: PageType -> ()
export const setPageTitle = compose(
  title => (document.title = title),
  getPageTitle,
);

// getPageTypeId :: PageType -> String
export const getPageTypeId = cata({
  Shortlisted: constant('shortlisted'),
  Viewed: constant('full-profile'), // constant('full-profile'),
  Blocked: constant('blocked'),
  Ignored: constant('ignored'),
});

// getSource :: PageType -> String
export const getSource = cata({
  Shortlisted: constant('shortlisted_members-listing'),
  Viewed: constant('profiles_viewed-listing'),
  Blocked: constant('blocked-listing'),
  Ignored: constant('ignored-listing'),
});

// getEvtRef :: PageType -> String
export const getEvtRef = cata({
  Shortlisted: constant(btoa('matches_shortlisted_matches_listing')),
  Viewed: constant(btoa('matches_recently_viewed_listing')),
  Blocked: constant(btoa('matches_blocked_profile_listing')),
  Ignored: constant(btoa('matches_ignored_profile_listing')),
});

// getTracking :: PageType -> { source :: String, evt_ref :: String }
export const getTracking = page => ({
  source: getSource(page),
  evt_ref: getEvtRef(page),
});

// getNoResultsError :: PageType -> { heading :: String, message :: String }
export const getNoResultsError = cata({
  Shortlisted: constant({ heading: 'No results', message: 'You have not shortlisted any profiles yet' }),
  Viewed: constant({ heading: 'No results', message: 'You have not viewed any profiles yet' }),
  Blocked: constant({ heading: 'No results', message: 'You have not blocked any profiles yet' }),
  Ignored: constant({ heading: 'No results', message: 'You have not ignored any profiles yet' }),
});

// getSearchParams :: Location -> Object
export const getSearchParams = ({ search }) => parse(search.slice(1));

// href :: Location -> String
export const href = ({ pathname, search }) => `${pathname}${search}`;

export default {};
