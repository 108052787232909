// type Key = String | Symbol;

// | Takes a list of pairs/two element tuples and converts it into an object
// | Eg - [ [ 'a', 'b' ], [ 'c', 'd' ] ] -> { a: 'b', c: 'd' }
// fromPairs :: [[Key, b]] -> Object b
export const fromPairs = pairs => pairs.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

// | Takes a mapping function and a set of keys and returns the values. The values are generated by
// |  mapping over the passed set of keys
// | Eg - (x => x + '-value') -> [ 'a', 'b', 'c' ] -> { a: 'a--value', b: 'b--value', c: 'c--value' }
// mapKeysToValue :: (Key -> a, [Key]) -> Object a
export const mapKeysToValue = (mapFn, keys) => fromPairs(keys.map(k => [k, mapFn(k)]));
