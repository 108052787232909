import localCache from '~/localCache';
import {
  CHAT_SKEW,
  CHAT_OTHER_IS_TYPING,
  CHAT_OTHER_IS_NOT_TYPING,
  CHAT_MESSAGE_STATUS,
  CHAT_MESSAGES_RECEIVED,
  CHAT_WINDOW_ADD,
  CHAT_WINDOW_UNREAD,
  BEEP_SOUND_STOP,
} from '~/actionTypes';
import { getMsiteFilteredOutChatBucket, getDesktopFilteredOutChatBucket } from '~/helpers/experiments';
import { AuthObjType, UnknownObject, DispatchType, GetStateType } from '~/types/actions';

import apiIsReceiveAllowed from './apiIsReceiveAllowed';
import doChatAction from '../doChatAction';

const sound = (getState: GetStateType) =>
  new Promise(resolve => {
    const { chat = {} } = getState();
    const { settings = {} } = chat;
    const { sound: soundSetting = '' } = settings;
    if (soundSetting === 'off') {
      return resolve('failed');
    }
    return setTimeout(() => resolve('success'), 500);
  });

export default (auth: AuthObjType, msg: Record<'from', string> & UnknownObject, dispatch: DispatchType, getState: GetStateType): void => {
  console.log(`<--- onMessage ${msg.status}`, `${msg.from || ''}>${msg.to || ''} ${msg.body || ''}`);
  const { session = {}, view = {} } = getState();
  const { settings = { experiments: {} } } = session;
  const msiteFilteredOutChatBucket = getMsiteFilteredOutChatBucket(settings);
  const desktopFilteredOutChatBucket = getDesktopFilteredOutChatBucket(settings);
  const { layout = '' } = view;
  const isMobile = layout === 'mobile';
  switch (msg.status) {
    case 'serverDelay': {
      console.log(`api skew: ${(Number(msg.addToServer) || 0) / 1000}s`);
      dispatch({ type: CHAT_SKEW, payload: { api: msg.addToServer } });
      return;
    }
    case 'chatcode': {
      doChatAction('onMessage', msg.from, 'chatActivity', 'chatcode')(dispatch, getState);
      return;
    }
    case 'beganTyping':
      dispatch({ type: CHAT_OTHER_IS_TYPING, payload: { chatUid: msg.from } });
      return;
    case 'pausedTyping':
    case 'endedTyping':
      dispatch({ type: CHAT_OTHER_IS_NOT_TYPING, payload: { chatUid: msg.from } });
      return;
    case 'read': {
      const { messageId, from } = msg;
      dispatch({ type: CHAT_MESSAGE_STATUS, payload: { chatUid: from, messageId, status: 'read' } });
      return;
    }
    case 'delivered': {
      const { messageId, from } = msg;
      dispatch({ type: CHAT_MESSAGE_STATUS, payload: { chatUid: from, messageId, status: 'delivered' } });
      return;
    }
    case 'selfMessage': {
      const { from, messageId, body, t, time, to } = msg;
      const m = { chatUid: to, from, messageId, body, t, time, to, isSelf: true, status: 'sent' };
      dispatch({ type: CHAT_MESSAGES_RECEIVED, payload: { chatUid: to, messages: [m] } });
      dispatch({ type: CHAT_MESSAGE_STATUS, payload: { chatUid: to, messageId, status: 'sent' } });
      return;
    }
    case 'newMessage': {
      const onAllowed = () => {
        const { from, messageId, body, t, time, to } = msg;
        // console.log({ msg });
        const { recentChat = {}, chat = {}, profiles = {} } = getState();
        const { windows = {}, messages = {} } = chat;
        const messagesHistory = messages[from] || {};
        const { hide_message: hideMessage = true } = messagesHistory;
        const { items = [], filteredItems = [] } = recentChat;
        const recentChatItem = [...items, ...filteredItems].find(i => i.uid === from) ?? { hideMessage };
        const hide_message = recentChatItem.hideMessage;
        const cacheKey = `unreadCount`;
        let cachedCount = localCache.read(cacheKey);
        if (cachedCount) {
          cachedCount += 1;
        } else {
          cachedCount = 1;
        }
        localCache.write(cacheKey, cachedCount, 3600 * 24 * 7);

        doChatAction('onMessage', from, 'chatActivity', 'markAsDelivered', [messageId])(dispatch, getState);
        setTimeout(() => {
          dispatch({ type: CHAT_OTHER_IS_NOT_TYPING, payload: { chatUid: from } });
          dispatch({
            type: CHAT_MESSAGES_RECEIVED,
            payload: {
              chatUid: from,
              messages: [{ chatUid: from, from, messageId, body, t, time, to, isSelf: false, status: 'none' }],
              hide_message,
            },
          });
        }, 1);
        // const isMobile = getState().view.layout === 'mobile';

        const { normal = [], stacked = [], closed = [] } = windows;
        // const windows = getState().chat.windows;
        const ourWindow = [...normal, ...stacked, ...closed].filter(w => w.uid === from)[0];
        const openWindows = [...normal, ...stacked, ...closed].filter(w => w.status === 'opened');
        if (!profiles[from]) {
          doChatAction('onMessage', from, 'refreshChatProfile')(dispatch, getState);
        }
        if (!ourWindow && openWindows.length > 0) {
          dispatch({ type: CHAT_WINDOW_ADD, payload: { uid: from, initialStatus: 'minimized' } });
          dispatch({ type: CHAT_WINDOW_UNREAD, payload: from });
          !isMobile &&
            sound(getState).then(() => {
              setTimeout(() => dispatch({ type: BEEP_SOUND_STOP }), 1500);
            });
        } else if (!ourWindow && openWindows.length === 0 && !isMobile) {
          dispatch({ type: CHAT_WINDOW_ADD, payload: { uid: from, initialStatus: 'opened' } });
          doChatAction('onMessage', from, 'chatActivity', 'markAsRead', [messageId])(dispatch, getState);
          doChatAction('onMessage', msg.from, 'chatWindowAction', ['open'])(dispatch, getState);
          dispatch({ type: CHAT_WINDOW_UNREAD, payload: from });
          !isMobile &&
            sound(getState).then(() => {
              setTimeout(() => dispatch({ type: BEEP_SOUND_STOP }), 1500);
            });
        } else if (ourWindow && ourWindow.status === 'stacked') {
          dispatch({ type: CHAT_WINDOW_UNREAD, payload: from });
          !isMobile &&
            sound(getState).then(() => {
              setTimeout(() => dispatch({ type: BEEP_SOUND_STOP }), 1500);
            });
        } else if (ourWindow && ourWindow.status === 'minimized') {
          dispatch({ type: CHAT_WINDOW_UNREAD, payload: from });
          !isMobile &&
            sound(getState).then(() => {
              setTimeout(() => dispatch({ type: BEEP_SOUND_STOP }), 1500);
            });
        } else if (ourWindow && ourWindow.status === 'closed') {
          doChatAction('onMessage', from, 'chatActivity', 'markAsRead', [messageId])(dispatch, getState);
          doChatAction('onMessage', msg.from, 'chatWindowAction', ['open'])(dispatch, getState);
        } // else ourWindow is open, nothing to do
      };
      if ((!isMobile && desktopFilteredOutChatBucket === 'B') || (isMobile && msiteFilteredOutChatBucket === 'B')) {
        onAllowed();
      } else {
        const params = { dispatch, getState, type: 'onMessage', self: auth.uid };
        apiIsReceiveAllowed(msg.from, params, onAllowed);
      }
      return;
    }
    default:
      console.log(`%c TO DO in onMessage ${msg.status}`, 'font-size: 16px', auth.uid, JSON.stringify(msg));
  }
};
