import { openDB } from '../utils';

const VERSION = 1;

export const STORES = {
  PENDING_EVENTS: 'pending-events',
  CONFIG: 'config',
};

const swDB = openDB('shaadi-sw', VERSION, {
  upgrade(db) {
    db.createObjectStore(STORES.CONFIG);
    db.createObjectStore(STORES.PENDING_EVENTS, {
      keyPath: 'id',
      autoIncrement: true,
    });
  },
});

export async function withTransaction(storeName, fn) {
  const db = await swDB;
  const tx = await db.transaction(storeName, 'readwrite');
  const store = await tx.objectStore(storeName);
  const returnVal = await fn({ db, tx, store });
  await tx.done;
  return returnVal;
}

export default swDB;
