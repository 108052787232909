export function trackEvent(schema, data, environment, schemaVersion = '1-0-0') {
  window.da(`trackUnstructEvent:${environment}`, {
    schema: `iglu:com.shaadi/${schema}/jsonschema/${schemaVersion}`,
    data,
  });
}

const getEnvironment = (nodeEnv, mode) => (nodeEnv === 'production' ? `production-${mode}` : `development-${mode}`);

const sendSnowPlowEvent = args => {
  const { memberlogin, profileId, event_referrer, event_loc, event_type, isNonReal, platform = 'web-react' } = args;
  console.log(
    'Snowplow Data : ',
    `event_type: ${event_type}, memberlogin: ${memberlogin}, profileid: ${profileId}, event_referrer: ${event_referrer}, event_loc: ${event_loc}`,
  );

  const environment = (isNonReal !== true && 'production-realtime') || 'production';
  const daSchema = (isNonReal !== true && 'viewed_unviewed') || 'profile_completion';
  const data = (isNonReal !== true && {
    memberlogin,
    profile_id: profileId,
    event_type,
    platform,
    event_referrer,
    event_loc,
  }) || {
    event_type,
    listing_type: event_referrer,
    os: 'web-react',
  };

  window.da(`trackUnstructEvent:${environment}`, {
    schema: `iglu:com.shaadi/${daSchema}/jsonschema/1-0-0`,
    data,
  });
};

export const trackDefaultEvent = args => sendSnowPlowEvent(args);

export const trackAnnualIncomeEvent = args => {
  const { isNonReal = false, source, event, newIncome, oldIncome, platform, profileId } = args;

  const environment = (isNonReal !== true && 'production-realtime') || 'production';
  const daSchema = 'snowplow_annual_income_tracking';
  const data = {
    profile_id: profileId,
    source,
    event_type: event,
    new_income: newIncome,
    old_income: oldIncome,
    platform,
  };

  window.da(`trackUnstructEvent:${environment}`, {
    schema: `iglu:com.shaadi/${daSchema}/jsonschema/1-0-0`,
    data,
  });
};

export const trackNewMatchesEvent = args => trackEvent('cta_blocked_tracking', args, 'production');
// trackSwEvent :: (*, SwEventSchemaVariant) -> ()
export const trackSwEvent = data => trackEvent('service_worker_events_tracking', data, 'production');
export const trackMostMatchesSwitchToggleEvent = data =>
  trackEvent('most_matches_switch_toggle_tracking', data, getEnvironment(process.env.NODE_ENV, 'batch'));
export const trackTrueViewEvent = data => trackEvent('trueview', data, getEnvironment(process.env.NODE_ENV, 'realtime'));
export const trackNewCtasEvent = (data, schemaVersion = '2-0-0') => trackEvent('cta_tracking', data, 'production', schemaVersion);
export const trackChatUsageEvent = (data, schemaVersion = '1-0-0') => trackEvent('zend_chat_usage', data, 'production', schemaVersion);
export const trackOnboardingResurface = data => trackEvent('bulk_interest_tracking', data, 'production');
export const trackProfileDeletion = data => trackEvent('profile_deletion', data, 'production');

export default {
  trackDefaultEvent,
  trackAnnualIncomeEvent,
  trackNewMatchesEvent,
  trackNewCtasEvent,
};
