import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withReducers from 'withReducers';

export default ({ reducers, injectReducerAsProps, injectActionsAsProps }) => Component => {
  const withHistoryAndReducers = props => <Component {...props} />;
  return compose(withReducers(reducers), withRouter, connect(injectReducerAsProps, injectActionsAsProps))(withHistoryAndReducers);
};
