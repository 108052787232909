import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import withLayoutDetection from 'components/HOC/withLayoutDetection';
import Loader from './loading';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "HeaderPartial.desktop", webpackPreload: true */ './desktop'), Loader);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "HeaderPartial.mobile" ,webpackPreload: true */ './mobile'), Loader);

const HeaderPartial = props => (props.isMobileScreen ? <Mobile /> : <Desktop />);

HeaderPartial.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(HeaderPartial);
