import cookie from 'cookie';
import { identity, compose } from './common';

export const getCookie = name => cookie.parse(document.cookie)[name];

export const currentDomainName = () => {
  const temp = window.location.hostname
    .split(':')[0]
    .split('.')
    .reverse();
  return `.${temp[1]}.${temp[0]}`;
};

export const myDomain = currentDomainName();

/* eslint default-case: 0, no-prototype-builtins: 0 */
export const createCookie = (sKey, sValue, vEnd, sPath = '/', sDomain = myDomain, bSecure = false) => {
  if (!sKey || /^(?:expires|max-age|path|domain|secure)$/.test(sKey)) {
    return;
  }
  let sExpires = '';
  if (vEnd) {
    switch (typeof vEnd) {
      case 'number':
        sExpires = `; max-age=${vEnd}`;
        break;
      case 'string':
        sExpires = `; expires=${vEnd}`;
        break;
      case 'object':
        if ('toGMTString' in vEnd) {
          sExpires = `; expires=${vEnd.toGMTString()}`;
        }
        break;
    }
  }
  document.cookie = `${escape(sKey)}=${escape(sValue)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}${
    bSecure ? '; secure' : ''
  }`;
};

/**
 * Cookie constructor
 * type Config = { parse :: String -> *, stringify: * -> String, defaultExpiry :: * };
 * :: (String, Config) -> Cookie
 *
 * Methods:
 * get -> Get the value of the cookie
 * set -> Set a new value to the cookie
 * update -> Map over the parsed value and return a new value to stringify and set
 * isEmpty -> Check if the value is undefined, null or empty string
 */
export const Cookie = (name, { parse = identity, stringify = identity, defaultExpiry } = {}) => {
  const getCookieValue = () => compose(parse, getCookie)(name);
  const setCookieValue = (value, expiry = defaultExpiry, ...args) => createCookie(name, stringify(value), expiry, ...args);
  return {
    // get :: () -> *
    get: getCookieValue,
    // set :: (*, String|Number|Object, ...*) -> ()
    set: setCookieValue,
    // isEmpty -> () -> Boolean
    isEmpty: () => [undefined, null, ''].indexOf(getCookieValue()) >= 0,
    // update :: (a -> a', ...*) -> ()
    update: (mapFn, ...args) => setCookieValue(compose(mapFn, getCookieValue)(), ...args),
  };
};

export const BooleanCookie = (name, options) =>
  Cookie(name, {
    parse: x => (x && !!JSON.parse(x)) || false,
    stringify: x => `${x || false}`,
    ...options,
  });

export const webpushRequestCookie = BooleanCookie('has_requested_notifications', { defaultExpiry: 7 * 24 * 60 * 60 });
