import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AsyncComponent from 'components/AsyncComponent';
import MatchmakingRoutes from 'routes/otherRoutes';
import ShaadiApp from 'ShaadiApp';
import MsiteChatRoutes from 'routes/msiteChatRoutes';

const BasicSearchPage = AsyncComponent(() => import(/* webpackChunkName: "BasicSearchPage" */ 'pages/BasicSearchPage'));
const OTPFlowPages = AsyncComponent(() => import(/* webpackChunkName: "OTPFlowPages" */ 'pages/OTPFlowPages'));
const EmailPreferences = AsyncComponent(() => import(/* webpackChunkName: "EmailPreferences" */ 'pages/EmailPreferences'));
const EmailVerificationCard = AsyncComponent(() => import(/* webpackChunkName: "EmailVerificationCard" */ 'pages/EmailVerificationCard'));
const ProfileCreationPage = AsyncComponent(() => import(/* webpackChunkName: "ProfileCreationPage" */ 'pages/ProfileCreationPage'));
const SelfProfilePage = AsyncComponent(() => import(/* webpackChunkName: "SelfProfilePage" */ 'pages/SelfProfilePage'));
const StopPage = AsyncComponent(() => import(/* webpackChunkName: "StopPage.responsive" */ 'pages/StopPage'));
const PaymentPage = AsyncComponent(() => import(/* webpackChunkName: "PaymentPage" */ 'pages/PaymentPage'));
const CartPage = AsyncComponent(() => import(/* webpackChunkName: "CartPage" */ 'pages/CartPage'));
const OrderSuccessPage = AsyncComponent(() => import(/* webpackChunkName: "OrderSuccessPage" */ 'pages/OrderSuccessPage'));
const UpiAuthentication = AsyncComponent(() => import(/* webpackChunkName: "UpiAuthentication" */ 'pages/UpiAuthenticationPage'));
const PhotoAlbumsPage = AsyncComponent(() => import(/* webpackChunkName: "RegPhotoUpload" */ 'pages/PhotoAlbumsPage'));
const CrmScreeningPage = AsyncComponent(() => import(/* webpackChunkName: "CrmScreening" */ 'pages/CrmScreeningPage'));
const PtpStopPage = AsyncComponent(() => import(/* webpackChunkName: "PtpStopPage" */ 'pages/PtpStopPage'));
const PaymentStopPage = AsyncComponent(() => import(/* webpackChunkName: "PaymentStopPage" */ 'pages/PaymentStopPage'));
const FishnetStopPage = AsyncComponent(() => import(/* webpackChunkName: "PaymentStopPage" */ 'pages/PaymentStopPage'));
const TopMatchesStopPage = AsyncComponent(() => import(/* webpackChunkName: "TopMatchesStopPage" */ 'pages/TopMatchesStopPage'));
const ShaadiEventsFeedback = AsyncComponent(() => import(/* webpackChunkName: "ShaadiEventsFeedback" */ 'pages/ShaadiEventsFeedback'));

const ShaadiAppRoutes = () => (
  <ShaadiApp>
    <Switch>
      <Route path="/payment" component={() => ''} />
      <Route path="/cart" component={() => ''} />
      <Route path="/profile-creation/step/*" component={() => ''} />
      <Route path="/upiauthentication/:orderId" component={() => ''} />
      <Route path="/top-matches" component={() => ''} />
      <Route path="/payment/responseorder/my-cart" component={() => ''} />
      <Route path="/match-price" component={() => ''} />
      <Route path="/fishnet" component={() => ''} />
    </Switch>

    <Switch>
      {/* 
        NOTE: any new route added here, must also be added to isPageWithNoShell variable in public/index.html 
      */}
      <Route path="/shaadi-live-feedback/:event_id" component={ShaadiEventsFeedback} />

      <Route path="/payment" exact component={PaymentPage} />
      <Route path="/cart" exact component={CartPage} />
      <Route path="/payment/thankyou" component={OrderSuccessPage} />
      <Route path="/stop-page" component={StopPage} />
      <Route path="/profile-creation/step/:stepNumber([1-4]{1})" exact component={ProfileCreationPage} />
      <Route path="/forgot-password/*" exact component={OTPFlowPages} />
      <Route path="/otp-login/*" exact component={OTPFlowPages} />
      <Route path="/email-preferences/:encMailer/:encUid" exact component={EmailPreferences} />
      <Route path="/update-email" component={EmailVerificationCard} />
      <Route path="/profile/preview" exact component={SelfProfilePage} />
      <Route path="/search" exact component={BasicSearchPage} />
      <Route path="/inbox/chats/*" exact component={MsiteChatRoutes} />
      <Route path="/upiauthentication/:orderId" component={UpiAuthentication} />
      <Route path="/photo-album" exact component={PhotoAlbumsPage} />
      <Route path="/crm-screening" exact component={CrmScreeningPage} />
      <Route path="/payment/responseorder/my-cart" component={PtpStopPage} />
      <Route path="/match-price" component={PaymentStopPage} />
      <Route path="/fishnet" component={FishnetStopPage} />
      <Route path="/top-matches" component={TopMatchesStopPage} />
      <Route component={MatchmakingRoutes} />
      {/* 
        NOTE: any new route added here, must also be added to isPageWithNoShell variable in public/index.html 
      */}
    </Switch>
  </ShaadiApp>
);

export default ShaadiAppRoutes;
