import React, { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';

export default reducersMap => WrappedComponent => props => {
  const { store } = useContext(ReactReduxContext);
  reducersMap.forEach(({ key, value: reducer }) => {
    !store.getState()[key] && store.injectReducer(key, reducer);
  });
  return <WrappedComponent {...props} />;
};
