import withAuth from 'actions/withAuth';
import { MODAL_SHOW } from 'actionTypes';
import guard from 'actions/lib/guard';
import apiGetLayer from './apiGetLayer';
import onSuccess from './onSuccess';

export default (source, uid, type, ...args) => (dispatch, getState) => {
  // eslint-disable-next-line
  // window.testCampaignLayer = () => dispatch({ type: MODAL_SHOW, payload: { name: 'Introducing Trust Badge', url: '/payment/index', category: 'template1', alt: 'Payment',  src: 'http://img.shaadi.com/imgs/header-badges/shaadipayimg_20170409234141.gif', layerId: 'campaignLayer', modal: 'campaignLayer' }});

  withAuth(
    ({ auth }) => {
      const params = { source, self: auth.uid, type, dispatch, getState };

      switch (type) {
        case 'fetchHeaderBadge':
          return apiGetLayer(['headerBadge'], params, onSuccess);
        case 'campaignLayer': {
          const { layout } = getState().view;
          const layerTypeKey = guard.layerBanner.getKey(layout);
          if (!guard.layerBanner.hasShownLayer(layerTypeKey)) {
            return apiGetLayer(['campaignLayer'], params, onSuccess);
          }
          return null;
        }
        case 'prefetchExitIntent': {
          return apiGetLayer(['exitIntent'], params, onSuccess);
        }
        case 'exitEvent': {
          if (getState().session.exitIntent) {
            const modal = getState().session.exitIntent;

            // DailyRecommendations Layer trigger if and only if when dr count is greather than 0.
            if (modal === 'dailyRecommendations') {
              const { nav } = getState();
              const matchNavData = nav.find(data => data.key === 'matches');
              if (matchNavData) {
                const recommendationsData = matchNavData.nav.find(mdata => mdata.key === 'recommendations');
                // Condition to skip the dailyRecommendations Layer if the count is <= 0 or we are on the DR the Page.
                if ((recommendationsData && recommendationsData.count <= 0) || recommendationsData?.isActive) {
                  return null;
                }
              }
            }

            const context = `ei_${modal.toLowerCase().slice(0, 2)}`;
            if (guard.canShow(context, auth.uid)) {
              dispatch({ type: MODAL_SHOW, payload: { modal } });
              guard.markShown(context, auth.uid, 3600 * 6);
            }
          }
          return null;
        }
        case 'getProfile':
          return apiGetLayer(['profilePageLeftBanner', 'profilePageOverlayLayer', 'profilePageOverlayBanner'], params, onSuccess);
        case 'getAlbumPromoBanner':
          return apiGetLayer(['profilePageOverlayBanner'], params, onSuccess);
        default:
          console.log('TO DO layerAction', type, { source, uid, args, self: auth.uid });
          return null;
      }
    },
    { caller: 'doPreferredAction', allowCache: true, delay: 1 },
  );
};
