import { parse, stringify } from 'qs';
import { SET_CANCEL, RESET_CANCEL } from 'actionTypes';
import localCache from 'localCache';
import popup from './popup';
import GA from './ga';
import DA from './da';

const cacheKey = (pageName, uid, query = {}) => `${uid}::${pageName}?${stringify(query)}`;

const clearProfileCaches = (uid, self) => {
  // Invalidate all search and profile caches.
  localCache.clearPrefix(`${self}::search`);
  localCache.clearPrefix(`${self}::profile`);
};

const setCancel = (axios, dispatch, source) => {
  const CancelTokenClass = axios.CancelToken;
  const CancelToken = new CancelTokenClass(cancelFn => {
    dispatch({ type: SET_CANCEL, payload: { source, cancelFn } });
  });
  return CancelToken;
};
const resetCancel = (dispatch, source = {}, cancelSource) => {
  cancelSource.filter(sourceInfo => source[sourceInfo]).forEach(src => source[src]());
  dispatch({ type: RESET_CANCEL, payload: { source: cancelSource } });
};

const cancelCall = (cancelSource = []) => (dispatch, getState) => {
  const source = getState().cancelApi;
  resetCancel(dispatch, source, cancelSource);
};

const clearRefineLocalCache = (uid, selfListKey) => {
  const refineLocalCacheArrKey = [
    'refine_discovery_recent_visitors',
    'refine_near_me',
    'refine_recently-joined',
    'refine_basic_search',
    'refine_matches',
  ];
  refineLocalCacheArrKey
    .filter(m => m !== selfListKey)
    .map(n => {
      const rkey = cacheKey(n, uid, {});
      localCache.write(rkey, {}, -1);
      return n;
    });
};

export { cacheKey, parse, stringify, clearProfileCaches, popup, GA, DA, setCancel, resetCancel, clearRefineLocalCache, cancelCall };
