import React, { useEffect, useMemo, FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from '~/core/Main';
import Content from '~/core/Content';
import withHistoryAndReducers from '~/components/HOC/withHistoryAndReducers';

import HeaderPartial from '~/partials/HeaderPartial';
import LayoutPartial from '~/partials/LayoutPartial';
import LayerPartial from '~/partials/LayerPartial';
import RecentChatPage from '~/pages/RecentChatPage';
import BuddyListPage from '~/pages/BuddyListPage';
import ChatHistoryPage from '~/pages/ChatHistoryPage';
import ChatAppPromotionPage from '~/pages/ChatAppPromotionPage';
import DownloadAppPartial from '~/partials/DownloadAppPartial';

import withDucks from '~/components/HOC/withDucks';
import { RESET_RECENT_CHAT_STATE } from '~/actions/sagas/recentChat/recentChat.actionTypes';
import socketInitB from '~/actions/onChatInit/socketInitB';
import onChatDestroyAction from '~/actions/onChatDestroy';
import { recentChatInjectible } from '~/actions/sagas/recentChat';
import { getMsiteFilteredOutChatBucket, getChatAppPromotionBucket } from '~/helpers/experiments';

import { DispatchType, StateType } from '~/types/actions';
// import { Location } from '~/types/location';
import { Settings } from '~/types/settings';

interface MsiteChatRoutesProps {
  // location: Location;
  settings: Settings;
  onChatInitialize?: () => undefined;
  onChatDestroy?: () => undefined;
  resetRecentChatState?: () => undefined;
}

// eslint-disable-next-line prettier/prettier
const MsiteChatRoutes: FC<MsiteChatRoutesProps> = ({
  settings,
  onChatInitialize,
  onChatDestroy,
  resetRecentChatState
}) => {

  const msiteFilteredOutChatBucket = useMemo(() => getMsiteFilteredOutChatBucket(settings), [settings]);
  const appPromotionExperiment = useMemo(() => getChatAppPromotionBucket(settings), [settings]);
  const pageToDisplay = appPromotionExperiment === 'B' && !settings.isPaidUser ? ChatAppPromotionPage : ChatHistoryPage;

  useEffect(() => {
    if (msiteFilteredOutChatBucket === 'B' && onChatInitialize && onChatDestroy && resetRecentChatState) {
      console.log('chat mobile initialize should happen only on entering 3 routes');
      onChatInitialize();
      return () => {
        console.log('chat mobile disconnect should happen only on exiting 3 routes');
        resetRecentChatState();
        onChatDestroy();
      };
    }
    return undefined;
  }, [onChatInitialize, onChatDestroy, resetRecentChatState, msiteFilteredOutChatBucket]);

  return (
    <LayoutPartial>
      <Switch>
        <Route component={HeaderPartial} />
      </Switch>
      <Content>
        <Main>
          <Switch>
            <Route path="/inbox/chats/recent-chats" component={RecentChatPage} />
            <Route path="/inbox/chats/buddy-list" component={BuddyListPage} />
            <Route path="/inbox/chats/history/pid/:uid" component={pageToDisplay} />
          </Switch>
        </Main>
      </Content>
      <DownloadAppPartial />
      <LayerPartial />
    </LayoutPartial>
  );
};
const injectActionsAsProps = {
  onChatInitialize: socketInitB,
  onChatDestroy: onChatDestroyAction,
  resetRecentChatState: () => (dispatch: DispatchType) => dispatch({ type: RESET_RECENT_CHAT_STATE }),
};

const injectReducerAsProps = (state: StateType) => {
  const { session = {} } = state;
  const { settings = {} } = session;
  return { settings };
};

const propsInjection = {
  reducers: [],
  injectReducerAsProps,
  injectActionsAsProps,
};

export default withDucks([recentChatInjectible])(withHistoryAndReducers(propsInjection)(MsiteChatRoutes));
