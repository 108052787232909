import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import InboxPage from 'pages/InboxPage';
import ContactSummaryPage from 'pages/ContactSummaryPage';

const InboxAcceptedInterests = () => <InboxPage type="connect" action="accepted" />;
const InboxPendingRequests = () => <InboxPage type="connect" action="pending" />;
const InboxAcceptedRequests = () => <InboxPage type="connect" action="pending" />;
const InboxSentRequests = () => <InboxPage type="connect" action="awaiting" />;
const InboxFilteredOut = () => <InboxPage type="connect" action="filtered" />;
const InboxArchivedInterests = () => <InboxPage type="connect" action="deleted" />;
const InboxConnectsExpired = () => <InboxPage type="connect" action="pending_expired" />;
const InboxConnectsExpiringSoon = () => <InboxPage type="connect" action="decidelater" />;
const InboxRequestPending = () => <InboxPage type="request" action="pending" />;
const InboxRequestAccepted = () => <InboxPage type="request" action="accepted" />;
const InboxRequestAwaiting = () => <InboxPage type="request" action="awaiting" />;

const OtherRoutes = () => (
  <Switch>
    <Route path="/inbox/pending/interests" component={InboxPendingRequests} />
    <Route path="/inbox/pending/recent-interests" component={InboxAcceptedRequests} />
    <Route path="/inbox/filteredout" component={InboxFilteredOut} />
    <Route path="/inbox/accepted/interests" component={InboxAcceptedInterests} />
    <Route path="/inbox/sent/interests" component={InboxSentRequests} />
    <Route path="/inbox/archived/interests" component={InboxArchivedInterests} />
    <Route path="/inbox/expired/interests" component={InboxConnectsExpired} />
    <Route path="/inbox/decidelater/interests" component={InboxConnectsExpiringSoon} />
    <Route path="/inbox/contact-summary" component={ContactSummaryPage} />
    <Route path="/inbox/pending/requests" component={InboxRequestPending} />
    <Route path="/inbox/accepted/requests" component={InboxRequestAccepted} />
    <Route path="/inbox/sent/requests" component={InboxRequestAwaiting} />
    <Route path="/inbox/sent/photo-requests" component={InboxRequestAwaiting} />
    <Route component={NotFound} />
  </Switch>
);

export default OtherRoutes;
