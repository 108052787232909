import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import setApiHeadersCommon from 'api/setCommonHeaders';

const MonitorAuthentication = props => {
  const onPropsChange = () => setApiHeadersCommon(props);
  useEffect(onPropsChange, [props]);
  return null;
};

const mapStateToProps = ({
  config: {
    app: { appKey, platform, accessToken, autologinToken, authHistoryToken },
  },
}) => ({
  appKey,
  platform,
  accessToken,
  autologinToken,
  authHistoryToken,
});

const composedHoc = compose(connect(mapStateToProps), memo);

export default composedHoc(MonitorAuthentication);
