import React from 'react';
import Spinner from '~/components/Spinner';

const PageLoadSpinner = ({ isMobile = false, styles = {} }) => (
  <div
    style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', paddingTop: !isMobile && '96px', boxSizing: 'border-box', ...styles }}
  >
    <Spinner />
  </div>
);

export default PageLoadSpinner;
