import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AsyncComponent from '~/components/AsyncComponent';
import withLayoutDetection from '~/components/HOC/withLayoutDetection';
import { getPaymentUrl } from './UpgradeToPremiumPageUtils';

const Mobile = AsyncComponent(() => import('./mobile'));

interface Props {
  isMobileScreen: boolean;
}

const UpgradeToPremiumPage = ({ isMobileScreen }: Props) => {
  const history = useHistory();
  if (isMobileScreen) {
    return <Mobile />;
  }
  history.push(getPaymentUrl('free', false, false, '/'));
  return '';
};

UpgradeToPremiumPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(UpgradeToPremiumPage);
