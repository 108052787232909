import overEvery from 'lodash/overEvery';
import cond from 'lodash/cond'; // Reference: https://lodash.com/docs/4.17.11#cond
import { compose, prop } from './fp-utils';

export * from './fp-utils';

export { cond, overEvery };

export const encode64 = compose(window.btoa, unescape, encodeURIComponent);
export const unsafeDecode64 = compose(decodeURIComponent, escape, window.atob);
export const decode64 = str => {
  if (!str) return '';
  try {
    return unsafeDecode64(str);
  } catch (err) {
    return '';
  }
};

// cata :: Object (...a -> b) -> SumType ...a -> b
export const cata = pattern => d => d.cata(pattern);

// and :: (...a -> Boolean, ...a -> Boolean) -> ...a -> Boolean
export const and = (fn1, fn2) => (...args) => fn1(...args) && fn2(...args);
// or :: (...a -> Boolean, ...a -> Boolean) -> ...a -> Boolean
export const or = (fn1, fn2) => (...args) => fn1(...args) || fn2(...args);

// isPropEqual :: String -> (a, a) -> Boolean
export const isPropEqual = propName => (prevProp, nextProp) => {
  const getProp = prop(propName);
  return prevProp === nextProp ? true : getProp(prevProp) === getProp(nextProp);
};

// arePropsEqual :: [String] -> (a, a) -> Boolean
export const arePropsEqual = propNameList => overEvery(propNameList.map(isPropEqual));

// truncate :: (String, Number, String) -> String
export const truncate = (str, limit, rest = '...') => `${`${str}`.slice(0, limit)}${`${str || ''}`.length > limit ? rest : ''}`;

export const isNotMemberType = (flags, type) => flags !== type;
export const addBlankSpace = (string, after) => {
  if (!string) return null;
  const v = string.replace(/[^\dA-Z]/g, '');
  const regex = new RegExp(`.{${after}}`, 'g');
  return v.replace(regex, a => `${a} `);
};

export const htmlDecode = input => {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};
export const checkPageYOffset = input => window.pageYOffset < input;

// clampCount :: (Number, Number) -> String
export const clampCount = (max, num) => (num > max ? `${max}+` : `${num}`);

// waitFor :: Number -> a -> Promise a
export const waitFor = delay => x => new Promise(res => setTimeout(() => res(x), delay));

// If function, call it and return value, if value, return it.
// resolveValue :: (b -> a | a, b) -> a
const resolveValue = (a, ...args) => (typeof a === 'function' ? a(...args) : a);

// match :: Object (a | (b, ...c) -> a) -> (b, ...c) -> a
export const match = (obj, defaultKey = 'default') => (value, ...args) => resolveValue(obj[value] || obj[defaultKey], value, ...args);

export const isOnlineMode = modeName => ['Net Banking', 'Credit Card', 'Debit Card', 'Emi', 'UPI', 'PAYTM'].includes(modeName);

export const getEventLoc = (type, metadata) =>
  match({
    premium_carousel: `${metadata.event_loc}_carousel_click`,
    default: `${metadata.event_loc}`,
  })(type);

export const getEvtRef = (type, metadata) =>
  match({
    premium_carousel: `${metadata.event_referrer}_premiumplus_carousel`,
    default: `${metadata.event_referrer}`,
  })(type);

export const getReferralProps = (type, metadata) => ({
  type,
  entry_point: metadata.entry_point,
  event_loc: getEventLoc(type, metadata),
  event_referrer: getEvtRef(type, metadata),
  event_referrer_url: metadata.event_referrer_url,
});

export const isPremiumMember = membership => !new RegExp(/\b(\w*free|was\w*)\b/).test(membership.toLowerCase());

export const copyWriteText = () => `© 1996-${new Date().getFullYear()} Shaadi.com`;
