import { AUTH_CACHE, AUTH_REQUEST } from 'actionTypes';
import guard from 'actions/lib/guard';
import localCache from 'localCache';
import getCacheKeyOfAuth from 'helpers/getCacheKeyOfAuth';
import redirectExternal from 'helpers/redirectExternal';
import getDomain from 'helpers/getDomain';
import api from 'api';
import { httpResponse } from 'api/helpers';
import setApiHeadersCommon from 'api/setCommonHeaders';
import getModulePageName from 'reducers/session/getModulePageName';
import handleLoginFail from 'actions/withAuth/handleLoginFail';
import handleLoginSuccess from 'actions/withAuth/handleLoginSuccess';
import handleAuthCache from 'actions/withAuth/handleAuthCache';
import cookie from 'cookie';
import '~/api/controllers/AuthController';

let singleton;
let key;
let historySingleton;
let optDispatch;

const isLoggedOut = () => {
  const { abc, reg_logger, ltabc } = cookie.parse(document.cookie);
  return (abc === 'SatyaNet' || reg_logger === 'SatyaNet') && ltabc === 'SatyaNet';
};

export default (wrappedFuncToCall, options = {}) => {
  const { dispatch, getState, params, force, allowCache, history, delay } = options;
  optDispatch = optDispatch || dispatch;
  let authData = key && localCache.read(key);
  let authParams;
  if (force) {
    singleton = undefined;
  }
  historySingleton = history || historySingleton;

  const injectOptsToPromiseCallback = {
    setApiHeadersCommon,
    getCacheKeyOfAuth,
    getModulePageName,
    guard,
    delay,
    dispatch: optDispatch,
    history: historySingleton,
    wrappedFuncToCall,
  };

  const injectOptsToPromiseCatch = { getModulePageName, getDomain, redirectExternal, dispatch: optDispatch };

  if (isLoggedOut()) {
    localCache.clear();
    return Promise.reject(
      httpResponse(401, {
        code: 'E_NO_AUTH',
        message: 'Please login to continue.',
      }),
    ).catch(handleLoginFail(injectOptsToPromiseCatch));
  }

  if (!singleton) {
    const {
      config: { app },
      view: { layout },
    } = getState();
    const isMobile = layout === 'mobile';
    setApiHeadersCommon(app);
    const { accessToken } = app;
    if (accessToken && accessToken.length > 8) {
      key = getCacheKeyOfAuth(accessToken);
      authData = localCache.read(key);
    }

    authData && dispatch({ type: AUTH_CACHE, payload: authData });

    dispatch({ type: AUTH_REQUEST, payload: {} });

    authParams = { params: { se: params.se } };

    if (!isMobile) {
      authParams.params.ml = params.ml;
    }

    singleton = true;
  }

  if (allowCache && authData) {
    return Promise.resolve({ data: authData, status: 200 }).then(handleAuthCache(injectOptsToPromiseCallback));
  }

  return api
    .get('/auth/me', authParams)
    .then(handleLoginSuccess({ ...injectOptsToPromiseCallback, currentDate: new Date() }))
    .catch(handleLoginFail(injectOptsToPromiseCatch));
};
