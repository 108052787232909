/* eslint camelcase: 0 */
import { CHAT_HISTORY_REQUEST, CHAT_HISTORY_SUCCESS, CHAT_HISTORY_FAIL } from 'actionTypes';
import api from 'api';
import errors from 'actions/lib/errors';

export default async (uid, { dispatch }) => {
  dispatch({ type: CHAT_HISTORY_REQUEST, payload: { chatUid: uid } });
  try {
    const { data: historyResponse } = await api.get('/chat-histories', { params: { uid } });
    // NOTE: this is a hack to handle the case when no messages are sent or when hide_message is false the chat history api does not return the hide_message key at all
    if (historyResponse?.hide_message === undefined) {
      const decoratedProfileResponse = await api.get('/profile/decorated', { params: { id: uid }, decorator_name: 'recent_chat_panel_wap' });
      const { data: dataParent = {} } = decoratedProfileResponse;
      const { data = {} } = dataParent;
      const { [uid]: decoratedProfile = {} } = data;
      const { gamification = {} } = decoratedProfile;
      const { both_party_gamified: hide_message = false } = gamification;
      const enhancedHistoryResponse = { ...historyResponse, hide_message };
      // console.log({ decoratedProfileResponse, enhancedHistoryResponse, hide_message, uid, gamification });
      dispatch({ type: CHAT_HISTORY_SUCCESS, payload: enhancedHistoryResponse });
    } else {
      dispatch({ type: CHAT_HISTORY_SUCCESS, payload: historyResponse });
    }
  } catch (error) {
    dispatch({ type: CHAT_HISTORY_FAIL, payload: { ...errors.clean(error), chatUid: uid } });
  }
};
