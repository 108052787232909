import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { children as childrenPropTypes } from 'PropTypes';
import _isEqual from 'lodash/isEqual';

import { getTheme } from 'theme/common';

const DesktopLayout = ({ layout, children }) => {
  const [containerStyles, setContainerStyles] = useState(getTheme().container);

  useEffect(() => {
    const newStyles = getTheme().container;
    if (!_isEqual(containerStyles, newStyles)) {
      setContainerStyles(newStyles);
    }
  }, [layout]);

  return (
    <div className="desktop-container" style={containerStyles}>
      {children}
    </div>
  );
};

DesktopLayout.propTypes = {
  children: childrenPropTypes.isRequired,
};

const mapStateToProps = state => ({
  layout: state.view.layout,
});

export default connect(mapStateToProps)(DesktopLayout);
