import React from 'react';
import PropTypes from 'prop-types';

const { Provider, Consumer } = React.createContext();

const Context = { Provider, Consumer };

const ProfileConsumer = Consumer;

export { Provider, ProfileConsumer, Context };

const ProfileProvider = ({ children, ...props }) => <Context.Provider value={props}>{children}</Context.Provider>;

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProfileProvider;
