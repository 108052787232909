interface RouteConfigObjType {
  getController: () => void;
  method: string;
}

interface RouteConfigType {
  get: Record<string, RouteConfigObjType>;
  post: Record<string, RouteConfigObjType>;
  put: Record<string, RouteConfigObjType>;
  patch: Record<string, RouteConfigObjType>;
  delete: Record<string, RouteConfigObjType>;
}

const routes: RouteConfigType = {
  get: {},
  post: {},
  put: {},
  patch: {},
  delete: {},
};

// GET
routes.get['/sessions/me'] = {
  getController: () => import(/* webpackChunkName: "SessionsController" */ './controllers/SessionsController'),
  method: 'show',
};
routes.get['/app/logger'] = {
  getController: () => import(/* webpackChunkName: "SessionsController" */ './controllers/SessionsController'),
  method: 'getLoggerAndExpData',
};

routes.get['/payment-sessions/me'] = {
  getController: () => import(/* webpackChunkName: "PaymentSessionsController" */ './controllers/PaymentSessionsController'),
  method: 'show',
};
routes.get['/counts/daily-recommendations'] = {
  getController: () => import(/* webpackChunkName: "CountsController" */ './controllers/DrCountsController'),
  method: 'show',
};

routes.get['/success-stories'] = {
  getController: () => import(/* webpackChunkName: "SuccessStoriesController" */ './controllers/SuccessStoriesController'),
  method: 'index',
};
// routes.get['/preferred_matches_proxy'] = {
//   getController: () => import(/* webpackChunkName: "PreferredMatchesProxyController" */ './controllers/PreferredMatchesProxyController'),
//   method: 'index',
// };
routes.get['/other_search'] = {
  getController: () => import(/* webpackChunkName: "OtherSearchController" */ './controllers/OtherSearchController'),
  method: 'index',
};
// routes.get['/other_search_proxy'] = {
//   getController: () => import(/* webpackChunkName: "OtherSearchProxyController" */ './controllers/OtherSearchProxyController'),
//   method: 'index',
// };
routes.get['/widgets/:widget/matches'] = {
  getController: () => import(/* webpackChunkName: "WidgetMatchesController" */ './controllers/WidgetMatchesController'),
  method: 'index',
};
routes.get['/search/photos'] = {
  getController: () => import(/* webpackChunkName: "SearchPhotosController" */ './controllers/SearchPhotosController'),
  method: 'index',
};
routes.get['/chats/me'] = {
  getController: () => import(/* webpackChunkName: "ChatsController" */ './controllers/ChatsController'),
  method: 'index',
};
routes.get['/recentChats/paged'] = {
  getController: () => import(/* webpackChunkName: "ChatsController" */ './controllers/ChatsController'),
  method: 'getPagedRecentChatList',
};
routes.get['/recentChat/profile'] = {
  getController: () => import(/* webpackChunkName: "ChatsController" */ './controllers/ChatsController'),
  method: 'getProfile',
};
routes.get['/discoverMatches'] = {
  getController: () => import(/* webpackChunkName: "DiscoverMatchesController" */ './controllers/DiscoverMatchesController'),
  method: 'index',
};
routes.get['/intentsGroup'] = {
  getController: () => import(/* webpackChunkName: "IntentsController" */ './controllers/IntentsController'),
  method: 'index',
};
routes.get['/chat-authorizations/me'] = {
  getController: () => import(/* webpackChunkName: "ChatAuthorizationsController" */ './controllers/ChatAuthorizationsController'),
  method: 'show',
};
routes.get['/notifications/me'] = {
  getController: () => import(/* webpackChunkName: "NotificationsController" */ './controllers/NotificationsController'),
  method: 'index',
};
routes.get['/chat-histories'] = {
  getController: () => import(/* webpackChunkName: "ChatHistoriesController" */ './controllers/ChatHistoriesController'),
  method: 'show',
};
routes.get['/drafts'] = {
  getController: () => import(/* webpackChunkName: "DraftsController" */ './controllers/DraftsController'),
  method: 'index',
};
routes.get['/drafts/default'] = {
  getController: () => import(/* webpackChunkName: "DraftsController" */ './controllers/DraftsController'),
  method: 'show',
};
routes.get['/drafts/all'] = {
  getController: () => import(/* webpackChunkName: "DraftsController" */ './controllers/DraftsController'),
  method: 'showAllDrafts',
};
routes.get['/profiles/:id'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'show',
};
routes.get['/profiles/list'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'showMultiple',
};
routes.get['/txtprofiles/:id'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'showTxt',
};
routes.get['/profiles/blocked-count'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'getBlockedCount',
};
routes.get['/profile/decorated'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'decoratedProfile',
};
routes.get['/profile-queues/me'] = {
  getController: () => import(/* webpackChunkName: "ProfileQueuesController" */ './controllers/ProfileQueuesController'),
  method: 'show',
};
routes.get['/profile-queues/bulk'] = {
  getController: () => import(/* webpackChunkName: "ProfileQueuesController" */ './controllers/ProfileQueuesController'),
  method: 'index',
};
routes.get['/shortlists/me/list_ids'] = {
  getController: () => import(/* webpackChunkName: "ShortlistsController" */ './controllers/ShortlistsController'),
  method: 'show',
};
routes.get['/tickers/me'] = {
  getController: () => import(/* webpackChunkName: "TickersController" */ './controllers/TickersController'),
  method: 'show',
};
routes.get['/serve/get-vip-consulant-detail'] = {
  getController: () => import(/* webpackChunkName: "ConsultationController" */ './controllers/ConsultationController'),
  method: 'index',
};
routes.get['/serve/get-consultation'] = {
  getController: () => import(/* webpackChunkName: "ConsultationController" */ './controllers/ConsultationController'),
  method: 'track',
};
routes.get['/profiles/photos:id'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotosController" */ './controllers/ProfilePhotosController'),
  method: 'index',
};
routes.get['/search/banner'] = {
  getController: () => import(/* webpackChunkName: "SearchBannerController" */ './controllers/SearchBannerController'),
  method: 'index',
};
routes.get['/save-search'] = {
  getController: () => import(/* webpackChunkName: "SavedSearchController" */ './controllers/SavedSearchController'),
  method: 'index',
};
routes.get['/:id/astro'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'getAstro',
};
routes.get['/reg-photo-page-profile'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'getConsentStoppageData',
};

routes.get['/:id/photos'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'getAllAlbumPhotos',
};
routes.get['/:id/profile-photo'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'getProfilePhoto',
};
routes.get['/:id/album-photos'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'getProfileAndAlbumPhotos',
};
routes.get['/:id/photos/rejected'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'getRejectedPhotos',
};
routes.get['/profiles/:id/photos'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'getOtherProfilePhotos',
};

routes.get['/inbox'] = {
  getController: () => import(/* webpackChunkName: "InboxController" */ './controllers/InboxController'),
  method: 'index',
};
routes.get['/inbox/requests'] = {
  getController: () => import(/* webpackChunkName: "InboxController" */ './controllers/InboxController'),
  method: 'getRequestSummary',
};
routes.get['/:id/preferences'] = {
  getController: () => import(/* webpackChunkName: "PreferencesController" */ './controllers/PreferencesController'),
  method: 'getPhotoPrivacySettings',
};
routes.get['/contactSummary'] = {
  getController: () => import(/* webpackChunkName: "ContactSummaryController" */ './controllers/ContactSummaryController'),
  method: 'show',
};
routes.get['/payment/get-cart'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'cart',
};
routes.get['/payment/bank-list'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'bankList',
};
routes.get['/payment/door-step'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'doorStep',
};
routes.get['/payment/shaadi-centers'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'shaadiCenters',
};
routes.get['/payment/uae-cities'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'uaeCities',
};
routes.get['/payment/member-contact-details'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'memberContactDetails',
};
routes.get['/payment/get-products'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'products',
};
routes.get['/payment/order-success'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'orderSuccess',
};
routes.get['/payment/bank-list-juspay'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'bankListJusPay',
};
routes.get['/payment/emi-details'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'emiDetails',
};
routes.get['/payment/get-ptp-details'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'ptpDetails',
};
routes.get['/profiles/:id/badge'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'getTrustBadgeData',
};
routes.get['/csatSurvey'] = {
  getController: () => import(/* webpackChunkName: "CsatSurveyController" */ './controllers/CsatSurveyController'),
  method: 'show',
};
routes.get['/phoneSettings'] = {
  getController: () => import(/* webpackChunkName: "PhoneSettingsController" */ './controllers/PhoneSettingsController'),
  method: 'show',
};
routes.get['/dashBoardWidgets'] = {
  getController: () => import(/* webpackChunkName: "DashBoardWidgetsController" */ './controllers/DashBoardWidgetsController'),
  method: 'index',
};
routes.get['/member/get-language'] = {
  getController: () => import(/* webpackChunkName: "PreferencesController" */ './controllers/PreferencesController'),
  method: 'getLanguageSettings',
};
routes.get['/payment/my-orders'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'myOrders',
};
routes.get['/payment/payment-stop-page'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'paymentStopPage',
};
routes.get['/payment/mop-downtime-messaging'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'mopDowntimeMessaging',
};
// POST
routes.post['/contact-lookups'] = {
  getController: () => import(/* webpackChunkName: "ContactLookupsController" */ './controllers/ContactLookupsController'),
  method: 'create',
};
routes.post['/send-sms'] = {
  getController: () => import(/* webpackChunkName: "SendSmsController" */ './controllers/SendSmsController'),
  method: 'create',
};
routes.post['/drafts'] = {
  getController: () => import(/* webpackChunkName: "DraftsController" */ './controllers/DraftsController'),
  method: 'create',
};
routes.get['/draft-setting'] = {
  getController: () => import(/* webpackChunkName: "DraftSettingsController" */ './controllers/DraftSettingsController'),
  method: 'show',
};
routes.put['/draft-setting/update'] = {
  getController: () => import(/* webpackChunkName: "DraftSettingsController" */ './controllers/DraftSettingsController'),
  method: 'update',
};
routes.post['/profile-actions'] = {
  getController: () => import(/* webpackChunkName: "ProfileActionsController" */ './controllers/ProfileActionsController'),
  method: 'create',
};
routes.post['/profile-actions/layerShown'] = {
  getController: () => import(/* webpackChunkName: "ProfileActionsController" */ './controllers/ProfileActionsController'),
  method: 'updateLayerShown',
};
routes.post['/shortlists'] = {
  getController: () => import(/* webpackChunkName: "ShortlistsController" */ './controllers/ShortlistsController'),
  method: 'create',
};
routes.post['/sendMessage'] = {
  getController: () => import(/* webpackChunkName: "MessagesController" */ './controllers/MessagesController'),
  method: 'sendMessage',
};
routes.post['/sendVerificationRequest'] = {
  getController: () => import(/* webpackChunkName: "VerificationRequestController" */ './controllers/VerificationRequestController'),
  method: 'send',
};
routes.post['/serve/enquirynew'] = {
  getController: () => import(/* webpackChunkName: "ConsultationController" */ './controllers/ConsultationController'),
  method: 'send',
};
routes.post['/attachments'] = {
  getController: () => import(/* webpackChunkName: "AttachmentsController" */ './controllers/AttachmentsController'),
  method: 'create',
};
routes.post['/save-search'] = {
  getController: () => import(/* webpackChunkName: "SavedSearchController" */ './controllers/SavedSearchController'),
  method: 'save',
};
routes.post['/profile-photo-upload'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'update',
};
routes.post['/facebook-photo-upload'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'facebookUpdate',
};
routes.post['/report-phone'] = {
  getController: () => import(/* webpackChunkName: "ReportPhoneController" */ './controllers/ReportPhoneController'),
  method: 'save',
};
routes.post['/consent/:id'] = {
  getController: () => import(/* webpackChunkName: "ProfileActionsController" */ './controllers/ProfileActionsController'),
  method: 'verificationConsent',
};
routes.post['/inbox'] = {
  getController: () => import(/* webpackChunkName: "InboxController" */ './controllers/InboxController'),
  method: 'send',
};
routes.post['/get-sms'] = {
  getController: () => import(/* webpackChunkName: "SendSmsController" */ './controllers/SendSmsController'),
  method: 'send',
};
routes.post['/payment/otp-generation'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'otpGeneration',
};
routes.post['/profile/track'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'track',
};
routes.post['/profile/mark-profile-viewed'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'trackProfileView',
};
routes.post['/csatSurvey'] = {
  getController: () => import(/* webpackChunkName: "CsatSurveyController" */ './controllers/CsatSurveyController'),
  method: 'save',
};
routes.post['/profile/:id/create'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'createProfile',
};
routes.post['/payment/add-to-cart'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'addToCart',
};
routes.post['/phoneSettings'] = {
  getController: () => import(/* webpackChunkName: "PhoneSettingsController" */ './controllers/PhoneSettingsController'),
  method: 'save',
};
routes.post['/payment/get-order-id'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'getOrderId',
};
routes.post['/payment/jus-pay-txns'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'jusPayTxns',
};
routes.post['/forgot-password/otp-generation'] = {
  getController: () => import(/* webpackChunkName: "ForgotPasswordController" */ './controllers/ForgotPasswordController'),
  method: 'otpGeneration',
};
routes.post['/forgot-password/otp-verification'] = {
  getController: () => import(/* webpackChunkName: "ForgotPasswordController" */ './controllers/ForgotPasswordController'),
  method: 'otpVerification',
};
// PUT
routes.put['/forgot-password/reset-password'] = {
  getController: () => import(/* webpackChunkName: "ForgotPasswordController" */ './controllers/ForgotPasswordController'),
  method: 'resetPassword',
};
routes.put['/drafts'] = {
  getController: () => import(/* webpackChunkName: "DraftsController" */ './controllers/DraftsController'),
  method: 'update',
};
routes.post['/add/default/drafts'] = {
  getController: () => import(/* webpackChunkName: "DraftsController" */ './controllers/DraftsController'),
  method: 'add',
};
routes.put['/profiles/me'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'update',
};
routes.put['/astro/me'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'updateAstro',
};
routes.put['/matches-tour-shown'] = {
  getController: () => import(/* webpackChunkName: "PreferencesController" */ './controllers/PreferencesController'),
  method: 'updateMatchesSwitch',
};
routes.put['/chat-presence/update'] = {
  getController: () => import(/* webpackChunkName: "PresenceController" */ './controllers/PresenceController'),
  method: 'updateChatPresence',
};
routes.put['/chat-windows/update'] = {
  getController: () => import(/* webpackChunkName: "ChatWindowsController" */ './controllers/ChatWindowsController'),
  method: 'update',
};
routes.put['/:id/photo'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'updatePhoto',
};
routes.put['/:id/preferences'] = {
  getController: () => import(/* webpackChunkName: "PreferencesController" */ './controllers/PreferencesController'),
  method: 'updatePhotoPrivacySettings',
};
routes.put['/payment/otp-verification'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'otpVerification',
};
routes.put['/profiles/:id/badge'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'updateTrustBadge',
};
routes.put['/member/update-language'] = {
  getController: () => import(/* webpackChunkName: "PreferencesController" */ './controllers/PreferencesController'),
  method: 'updateLanguageSettings',
};
routes.put['/self/update-name-and-name-privacy'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'updateNameAndNamePrivacy',
};
routes.put['/verify/name'] = {
  getController: () => import(/* webpackChunkName: "ProfilesController" */ './controllers/ProfilesController'),
  method: 'nameVerification',
};
routes.put['/payment/cancel-ptp-order'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'ptpCancelOrder',
};
routes.put['/payment/update-auto-subscription-setting'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'updateAutoSubscriptionSetting',
};
// DELETE
routes.delete['/:id/photo'] = {
  getController: () => import(/* webpackChunkName: "ProfilePhotoController" */ './controllers/ProfilePhotoController'),
  method: 'deletePhoto',
};

routes.get['/get/accountSettings'] = {
  getController: () => import(/* webpackChunkName: "AccountSettingsController" */ './controllers/AccountSettingsController'),
  method: 'index',
};

routes.post['/validate/email'] = {
  getController: () => import(/* webpackChunkName: "AccountSettingsController" */ './controllers/AccountSettingsController'),
  method: 'validateEmail',
};

routes.put['/update/email'] = {
  getController: () => import(/* webpackChunkName: "AccountSettingsController" */ './controllers/AccountSettingsController'),
  method: 'updateEmail',
};
routes.get['/payment/addon-details'] = {
  getController: () => import(/* webpackChunkName: "PaymentController" */ './controllers/PaymentController'),
  method: 'addonDetails',
};
export default routes;
