import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import NotFound from '~/pages/NotFound';
import AsyncComponent from '~/components/AsyncComponent';
import Spinner from '~/components/Spinner';
import withLayoutDetection from '~/components/HOC/withLayoutDetection';
import redirectExternal from '~/helpers/redirectExternal';
import getDomain from '~/helpers/getDomain';
import constants from '~/constants/constants';
import device from '~/helpers/device';
import Orientation from '~/mComponents/Common/ScreenOrientation';
import { StateType } from '~/types/actions';

const Mobile = AsyncComponent(() => import(/* webpackChunkName: "ChatAppPromotionPage.mobile" */ './ChatAppPromotionPage'), Spinner);
const loginWithPasswordLink = `${window.location.protocol}//${getDomain()}${constants.URI.loginPage}`;

interface ChatAppPromotionPageProps {
  isMobileScreen: boolean;
}

const ChatAppPromotionPage: FC<ChatAppPromotionPageProps> = ({ isMobileScreen = false }) => {
  const [deviceOrientation, setDeviceOrientation] = useState(window.orientation as number);

  useEffect(() => {
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        () => {
          setTimeout(() => {
            setDeviceOrientation(window.orientation as number);
          }, 1);
        },
        false,
      );
    }
  }, [deviceOrientation]);

  useEffect(() => {
    // @ts-ignore - Only for Msite
    if (device.shortPlatform === 'web') {
      redirectExternal(loginWithPasswordLink);
    }
  }, []);

  if (!isMobileScreen) return <NotFound />;
  return Math.abs(deviceOrientation) === 90 ? <Orientation lockOrientation={deviceOrientation} /> : <Mobile />;
};

const mapStateToProps = (state: StateType) => {
  const { session = {} } = state;
  const { settings = {} } = session;
  return { settings };
};

export default withLayoutDetection(connect(mapStateToProps, null)(ChatAppPromotionPage));
