/* global window: true */
import { UPDATE_DIMENSIONS_RESIZE } from 'actionTypes';
import logger from 'logger';
import detectLayout from './detectLayout';

let width = 0;
const update = dispatch => {
  const newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const newHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  if (Math.abs(newWidth - width) > 1) {
    logger.debug('%c RESIZE', 'color: purple; font-weight: bold;', newWidth);
    dispatch({
      type: UPDATE_DIMENSIONS_RESIZE,
      payload: {
        width: newWidth,
        height: newHeight,
        layout: detectLayout(window.location.pathname, null, newWidth).layout,
        orientation: window.screen && window.screen.width > window.screen.height ? 'landscape' : 'portrait',
      },
    });
  }
  width = newWidth;
};

let running = true;

export default () => dispatch => {
  const fps = 10;

  const onTick = () => {
    update(dispatch);
    running = false;
  };

  const resizeThrottler = () => {
    if (!running) {
      running = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(onTick);
      } else {
        setTimeout(onTick, 1000 / fps);
      }
    }
  };

  window.addEventListener('resize', resizeThrottler, false);
  window.addEventListener('orientationchange', () => update(dispatch), false);
  onTick();
};
