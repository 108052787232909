import { deviceTrackingHeaders } from './helpers';
import server from './server';
import autoLoginRequest from './ww4/autoLoginRequest';

export default ({ token, appKey, platform }) =>
  server({
    ...autoLoginRequest(token),
    headers: {
      'X-App-Key': appKey,
      'X-Platform': platform,
      ...deviceTrackingHeaders(),
    },
  })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
