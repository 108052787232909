export const SHAADI_PREMIUM_ASSIST_CONTACT_NUMBER = '+7862889999';
export const SHAADI_PREMIUM_ASSIST_CONTACT_AVAILABILITY_HOURS = '10am - 7pm';

export const UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_ENTPT = 'direct';
export const UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOC = 'premium_bottom_nav_tab';
export const UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTREF = 'app_premium_bottom_nav';
export const UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOCBTN = 'premium_bottom_nav_tab';

export const FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_FREE_USER = 'app_premium_bottom_nav_free';
export const FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_RENEW_USER = 'app_premium_bottom_nav_about_to_lapse';
export const FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_LAPSED_USER = 'app_premium_bottom_nav_lapsed';
export const FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_PREMIUM_USER = 'app_premium_bottom_nav_premium';

export const PAYMENT_VISITS_API_CALL_REQUEST_SOURCE_RENEW_USER_AUTO_OPEN = 'app_premium_bottom_nav_about_to_lapse_renew_auto_open';
export const PAYMENT_VISITS_API_CALL_REQUEST_SOURCE_LAPSED_USER_AUTO_OPEN = 'app_premium_bottom_nav_lapsed_renew_auto_open';

export const PAYMENT_VISITS_API_CALL_REQUEST_SOURCE_RENEW_USER = 'app_premium_bottom_nav_about_to_lapse_renew';
export const PAYMENT_VISITS_API_CALL_REQUEST_SOURCE_LAPSED_USER = 'app_premium_bottom_nav_lapsed_renew';

// export const CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_FREE_USER = 'app_premium_bottom_nav_free_view_plans';
export const CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_FREE_USER = 'app_premium_bottom_nav_free';
export const CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_RENEW_USER = 'app_premium_bottom_nav_about_to_lapse_view_plans';
export const CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_LAPSED_USER = 'app_premium_bottom_nav_lapsed_view_plans';

export const CHOOSE_ANOTHER_PLAN_SILVER_RENEW_USER_REQUEST_SOURCE = 'app_premium_bottom_nav_about_to_lapse_silver_view_plans';
export const CHOOSE_ANOTHER_PLAN_SILVER_LAPSED_USER_REQUEST_SOURCE = 'app_premium_bottom_nav_lapsed_silver_view_plans';

export const CHOOSE_ANOTHER_PLAN_VIP_RENEW_USER_REQUEST_SOURCE = 'app_premium_bottom_nav_about_to_lapse_vip_view_plans';
export const CHOOSE_ANOTHER_PLAN_VIP_LAPSED_USER_REQUEST_SOURCE = 'app_premium_bottom_nav_lapsed_vip_view_plans';

export const RENEW_NOW_BUTTON_TEXT_NORMAL_USER = 'Renew Now';
export const RENEW_NOW_SMALL_BUTTON_TEXT_NORMAL_USER = 'Renew';

export const RENEW_NOW_BUTTON_TEXT_VIP_USER = 'Request Renewal';
export const RENEW_NOW_SMALL_BUTTON_TEXT_VIP_USER = 'Request Renewal';

export const RENEW_NOW_BUTTON_TEXT_SILVER_USER = 'View All Plans';
export const RENEW_NOW_SMALL_BUTTON_TEXT_SILVER_USER = 'View Plans';
