import batchRequestService from '../services/batchRequestService';
import requestService from '../services/requestService';
import layerRequest from '../ww4/layer';
import Router from '~/api/router';

const maps = {
  headerBadge: {
    type: 'headerbadge_inner',
    campaign_type: 'layer',
  },
  exitIntent: {
    page: 'exit_intents',
  },
  profilePageLeftBanner: {
    type: 'banner',
    campaign_type: 'banner_profile',
  },
  profilePageOverlayLayer: {
    type: 'layer_react',
    campaign_type: 'layer',
  },
  profilePageOverlayBanner: {
    type: 'banner',
    campaign_type: 'banner_photo',
  },
  campaignLayer: ({ layout }) => ({
    type: 'layer_react',
    campaign_type: layout === 'mobile' ? 'mobile_image_layer' : 'layer_react',
  }),
  regExitIntent: {
    page: 'reg-layer',
    type: 'exit_intent',
  },
};

const layerBannerMap = (type, params) => (typeof maps[type] === 'function' ? maps[type](params) : maps[type]);

const index = (logger, query, auth) => {
  const { uid } = auth;
  const { layout } = query;
  const requests = query.types.reduce((acc, type) => ({ ...acc, [type]: layerRequest(uid, layerBannerMap(type, { layout })) }), {});

  return batchRequestService(logger, query, auth, requests, data =>
    query.types.reduce(
      (acc, type) => ({
        ...acc,
        [type]: {
          layer: data[type].data || {},
          layerType: data[type].type || type,
          ...layerBannerMap(type, { layout }),
        },
      }),
      {},
    ),
  );
};

const getBanner = (logger, query, auth) => {
  const { uid } = auth;
  const request = {
    method: 'get',
    url: `/pages/banners/${uid}`,
    params: {
      type: 'accepted_interest',
      decorator: {
        name: 'accepted_banner',
        profile_photo: true,
        img_size: ['small', 'medium'],
        img_border: '_nb',
      },
    },
  };
  return requestService(logger, query, auth, request, d => d);
};

Router.get('/layers/me', index);
Router.get('/pages/banners', getBanner);

export default {
  index,
  getBanner,
};
