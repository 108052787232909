/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
const IS_PROD = process.env.NODE_ENV !== 'development';

type InitializeDatadogRumProps = {
  isProd?: boolean;
  sampleRate?: number;
};

const initializeDatadogRum = ({ isProd = IS_PROD, sampleRate = 5 }: InitializeDatadogRumProps = {}): false | Promise<void> =>
  isProd &&
  import(/* webpackChunkName: "datadog" */ '@datadog/browser-rum').then(({ datadogRum }) => {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID || '',
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || '',
      sampleRate,
    });
  });

export default initializeDatadogRum;
