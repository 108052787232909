import { parse, stringify } from 'qs';
import constants from '~/constants/constants';
import { syncGuards } from '~/helpers/service-workers/guards';
import withAuth from '../withAuth';
import apiGetCounts from './apiGetCounts';
import apiGetSession from './apiGetSession';
import apiGetPaymentSession from './apiGetPaymentSession';
import apiGetSuccessStories from './apiGetSuccessStories';
import doLayerAction from '../doLayerAction';
import { domainCheck, getHostName } from './utils';
import apiGetLoggerinfo from './apiGetLoggerinfo';

const DOMAINS_TO_BLOCK_SUCCESS_STORIES = ['parsi'];

/* function alertMetrics() {
  const cacheKey = 'perfScore';
  const perfScore = localCache.read(cacheKey);
  if (document.readyState === 'complete' && !perfScore) {
    setTimeout(() => {
      const scores = getScore();
      localCache.write('perfScore', (scores.overall || 0).toFixed(4), 86400);
    }, 1000);
  }
} */

export default history => (dispatch, getState) => {
  const params = parse(history.location.search.slice(1));
  const {
    session: { canCallAppInit, isProfileCreationPage, isPaymentPage, isSearchPage, isPaymentCartPages },
    view: { layout },
  } = getState();

  if (isProfileCreationPage) {
    // alertMetrics();
    // document.onreadystatechange = alertMetrics;
    return;
  }

  if (constants.isForgotPasswordPage) return;

  const liteAppInit = () => {
    if (getState().config.app.platform === constants.LITE_APP_PLATFORM) {
      const qs = parse(window.location.search, { ignoreQueryPrefix: true });
      if (qs.doReload === 'true') {
        const newURL = constants.isStoppage ? '/' : decodeURIComponent(stringify({ ...qs, doReload: 'false' }, { addQueryPrefix: true }));
        history.push(newURL);
        window.location.reload(true);
      }
    }
  };
  const afterAuth = ({ auth }) => {
    // @todo get all the below API calls in HOC wrapped around components where it is required
    isPaymentPage && getState().config.app.platform === 'web' && apiGetSuccessStories(auth, dispatch);
    !isPaymentCartPages && dispatch(apiGetSession(auth));
    isPaymentCartPages && apiGetPaymentSession(auth, dispatch, getState);

    if (!isPaymentCartPages && !isSearchPage) {
      apiGetCounts(auth, dispatch, undefined, getState);
      const hostName = getHostName(window);
      if (layout === 'desktop' && !domainCheck(DOMAINS_TO_BLOCK_SUCCESS_STORIES, hostName)) {
        apiGetSuccessStories(auth, dispatch);
      }
      const layerAPI = {
        prefetchExitIntent: { mobile: false, desktop: true },
        getAlbumPromoBanner: { mobile: false, desktop: true },
        campaignLayer: { mobile: true, desktop: true },
      };

      if (!syncGuards.checkRequestPushPermission()) {
        const layerAction = type => doLayerAction('onAppInit', undefined, type)(dispatch, getState);
        Object.keys(layerAPI).forEach(type => layerAPI[type][layout] && layerAction(type));
      }

      // window.testExitLayer = () => doLayerAction('onAppInit', undefined, 'prefetchExitIntent')(dispatch, getState);
      if (window.app && typeof window.app.markLoginInApp === 'function') {
        window.app.markLoginInApp();
      }
    }

    setTimeout(liteAppInit, 10);
  };
  const paramsForAuth = {
    dispatch,
    getState,
    params,
    history,
    caller: 'onAppInit',
    allowCache: true,
  };
  withAuth(canCallAppInit ? afterAuth : () => false, paramsForAuth);
};

export { apiGetLoggerinfo };
