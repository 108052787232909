import get from 'lodash/get';
import { SESSION_REQUEST, SESSION_CACHE, SESSION_SUCCESS, SESSION_FAIL, HAS_HOROSCOPE } from 'actionTypes';
import api from 'api';
import localCache from 'localCache';
import errors from '../lib/errors';
import { cacheKey } from '../lib/utils';

export default auth => (dispatch, getState) => {
  dispatch({ type: SESSION_REQUEST, payload: {} });
  const cKey = cacheKey('session', auth.uid);
  const sessionData = localCache.read(cKey);
  if (sessionData) {
    dispatch({ type: SESSION_CACHE, payload: sessionData });
  }

  api
    .get('/sessions/me', { params: { file_extension: `${getState().config.app.webp !== '0' ? 'webp' : ''}` } })
    .then(response => {
      dispatch({ type: SESSION_SUCCESS, payload: response.data });
      const astroStatus = get(response, ['data', 'self', 'astro', 'status'], 'None');
      const hasAstro = astroStatus !== 'None';
      dispatch({ type: HAS_HOROSCOPE, payload: { hasAstro } });
      localCache.write(cKey, response.data, 12 * 3600);
    })
    .catch(error => dispatch({ type: SESSION_FAIL, payload: errors.clean(error) }));
};
