import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const isMobileScreen = layout => layout === 'mobile';

export default ComposedComponent => {
  const withLayoutDetection = props => <ComposedComponent isMobileScreen={isMobileScreen(props.layout)} {...props} />;
  withLayoutDetection.propTypes = { layout: PropTypes.string.isRequired };
  const injectReducerAsProps = ({ view: { layout } }) => ({ layout });
  return connect(injectReducerAsProps)(memo(withLayoutDetection));
};
