import { LOGEER_EXP_DATA_REQUEST, LOGEER_EXP_DATA_SUCCESS, LOGEER_EXP_DATA_ERROR } from 'actionTypes';
import api from 'api';
import errors from '../lib/errors';
import withAuth from '../withAuth';

const loggerInfo = () => (dispatch, getState) => {
  withAuth(
    () => {
      dispatch({ type: LOGEER_EXP_DATA_REQUEST, payload: {} });

      api
        .get('/app/logger')
        .then(response => {
          dispatch({ type: LOGEER_EXP_DATA_SUCCESS, payload: response.data });
        })
        .catch(error => dispatch({ type: LOGEER_EXP_DATA_ERROR, payload: errors.clean(error) }));
    },
    { getState, dispatch, params: {} },
  );
};

export default loggerInfo;
