import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withLayoutDetection from '~/components/HOC/withLayoutDetection';
import { DISMISS_DOWNLOAD_APP_LAYER } from '~/actionTypes';
import constants from '~/constants/constants';
import { DownloadAppProps, DownloadAppState } from './DownloadAppPartial.types';
import AsyncComponent from '~/components/AsyncComponent';

// Dynamic import so code is not downloaded in desktop
const Layer = AsyncComponent(() => import(/* webpackChunkName: "DownloadAppPartial.Layer", webpackPreload: true */ './Layer'));
const Banner = AsyncComponent(() => import(/* webpackChunkName: "DownloadAppPartial.Banner" ,webpackPreload: true */ './Banner'));

const DownloadAppPartial: React.FC<DownloadAppProps> = props =>
  props.isMobileScreen ? (
    <>
      {props.showLayer && <Layer {...props} />}
      {<Banner {...props} />}
    </>
  ) : null;

const mapStateToProps = (state: DownloadAppState) => {
  let custom_layer = '';

  if (state.modal) {
    custom_layer = state.modal.campaignLayer.custom_layer;
  }

  return {
    showLayer: custom_layer === constants.DOWNLOAD_APP_LAYER_NAME,
    hideBanner: !state.session.smartBannerVisible,
  };
};

// eslint-disable-next-line
const mapDispatchToProps = (dispatch: any) => ({
  dismissLayer: () =>
    dispatch({
      type: DISMISS_DOWNLOAD_APP_LAYER,
    }),
});

const composedHoc = compose(withLayoutDetection, connect(mapStateToProps, mapDispatchToProps), memo);

export default composedHoc(DownloadAppPartial);
