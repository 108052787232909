import DEVICE from '../helpers/device';

export const WAIT_DURATION = 5000;
export const MIN_WAIT_DURATION = 1000;
export const NAVIGATION_DELAY_ON_DR = 800;

export const INDAIN_CUSTOMER_CARE_NUMBER = '7862889999';

export default {
  MAX_PHOTO_COUNTS: 20,
  DEVICE,
  // eslint-disable-next-line
  NODE_API_ENDPOINT: `${process.env.REACT_APP_SOA_API_URL || 'https://ww4.shaadi.com'}/api/`,
  UPLOAD_FILE_URL: 'https://upload-file.shaadi.com/api/',
  PHOTO_DISPLAY_SETTING: {
    SHOW_ALL: 'Show All',
    WHEN_I_CONTACT: 'When I Contact',
    ONLY_WHEN_I_CONTACT: 'Only When I Contact',
    SHOW_ALL_LC: 'show all',
    WHEN_I_CONTACT_LC: 'when i contact',
    ONLY_WHEN_I_CONTACT_LC: 'only when i contact',
  },
  PHOTO_STATUS: {
    COMING_SOON: 'coming_soon',
    SHOW_PHOTO: 'show_photo',
    ADD_PHOTO: 'add_photo',
  },
  SHAADI_URL: 'www.shaadi.com',
  URI: {
    loginPage: '/registration/user/login',
    stopPageApiUrl: '/stop-page/overview?gtrk=2',
    incompleteProfilePage: '/registration/profile/incomplete',
    myShaadi: '/my-shaadi',
    signUpPage: '/registration/user',
  },
  validProfileFieldSet: [
    'account',
    'basic',
    'lifestyle',
    'health_info',
    'appearance',
    'family',
    'trait',
    'origin',
    'doctrine',
    'account',
    'location',
    'profession',
    'education',
    'interests_and_more',
    'activity_factor',
  ],
  validProfilePhotoFieldSet: ['count', 'photos'],
  validPhotoSizes: [
    'small',
    'medium',
    'semilarge',
    'large',
    '40X40',
    '60X60',
    '120X120',
    '150X200',
    '250X310',
    '400X500',
    '450X600',
    '720X1006',
    '750X1333',
  ],
  isStoppage: /^\/stop-page\//i.test(window.location.pathname),
  isRegPage: /^\/registration\//i.test(window.location.pathname),
  isMyShaadiPage: /^\/my-shaadi\//i.test(window.location.pathname),
  isForgotPasswordPage: /^\/forgot-password\//i.test(window.location.pathname),
  isPaymentCart: ['/cart', '/payment', '/payment/thankyou'],
  LITE_APP_PLATFORM: 'lite-native-android',
  isPaymentPage: ['/payment'],
  mobileNumberMaxLength: {
    Austria: 12,
    Malaysia: 10,
    Germany: 11,
    Brazil: 11,
    China: 11,
    'United Kingdom': 10,
    India: 10,
    Canada: 10,
    USA: 10,
    Indonesia: 12,
  },
  mobileNumberMinLength: {
    India: 10,
    Canada: 10,
    USA: 10,
  },

  descriptionSuggestionText: 'Edit the suggested text above\u{1F446} to add a personal touch',
  descriptionSuggestionTextIE: 'Edit the suggested text above to add a personal touch',
  descriptionPlaceholder:
    'Example: I am a successful professional working with a well known company. We are a close-knit family with modern values. I enjoy reading and music and I am looking or someone who believes that marriage is a journey for growth and fulfilment.',
  descriptionPlaceholder_yourself:
    'Tips - What does you like to do in your free time? How do your friends describe you? What are your expectations from a partner?',
  descriptionPlaceholder_him:
    'Tips - What does he like to do in his free time? How do his friends describe him? What are his expectations from a partner?',
  descriptionPlaceholder_her:
    'Tips - What does she like to do in her free time? How do her friends describe her? What are her expectations from a partner?',
  WEB_PLATFORM: 'web',
  WAP_PLATFORM: 'wap',
  WEB_VIEW_PLATFORM: 'android',
  DOWNLOAD_APP_LAYER_ID: '1900',
  DOWNLOAD_APP_LAYER_NAME: 'downloadApp',
  ALBUM_GAMIFICATION_SOURCE: 'msite-gamification',
};
