/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable func-names */
// eslint-disable-next-line no-redeclare
/* global window */
import last from 'lodash/last';
import { METADATA, ROUTE_CHANGE, COOKIE_SUCCESS, UPDATE_DIMENSIONS_RESIZE } from 'actionTypes';
import constants from 'constants/constants';
import device from 'helpers/device';
import { encode64, decode64 } from '~/helpers/common';

export const safeGetEvtRef = (query = {}, evtRefKey = 'evt_ref') => {
  const evtRef = query[evtRefKey] || '';
  if (Array.isArray(evtRef)) {
    return last(evtRef);
  }
  return evtRef;
};

const getEventReferrerPath = (query, isDr, isProfileIdSearch) => {
  if (query.evt_ref) return decode64(safeGetEvtRef(query));
  if (isDr) return 'daily5';
  if (isProfileIdSearch) return 'search_results_profile_id';
  else
    return `/${`${(window.location.href || window.document.referrer || '').split('://')[1]}`
      .split('?')[0]
      .split('/')
      .slice(1, 100)
      .join('/')}`;
};

const maps = {
  '/search/partner': 'matches_my_matches_listing',
  '/search/partner/viewed': 'matches_my_matches_listing',
  '/profile': 'profile',
  '/inbox/pending/interests': 'inbox-interests',
  '/inbox/accepted/interests': 'inbox-accepted_members',
  '/inbox/pending/requests': 'inbox-requests',
  '/inbox/accepted/requests': 'inbox-accepted_requests',
  '/inbox/sent/requests': 'inbox-sent_requests',
  '/inbox/sent/interests': 'inbox-sent_interests',
  '/inbox/archived/interests': 'inbox-deleted_interests',
  '/top-matches': 'resurface_new',
};

const loc = path => maps[path] || path.replace(/\//g, '-');

const initialState = {
  entry_point: 'direct',
  event_loc: null,
  event_loc_url: null,
  event_referrer: null,
  event_referrer_url: null,
  device: device.platform,
  device_os: device.os,
  platform: `${device.isMobile ? 'wap' : 'web'}-${window.location.hostname}`,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COOKIE_SUCCESS: {
      const cookies = action.payload;
      return {
        ...state,
        entry_point: cookies.entpt || cookies['entpt-session'] || state.entry_point,
        platform: cookies.litem ? `${constants.LITE_APP_PLATFORM}-${window.location.hostname}` : state.platform,
      };
    }
    case METADATA: {
      const newState = {
        ...state,
        ...action.payload,
        entry_point: state.entry_point,
        platform: state.platform,
      };
      return {
        ...newState,
        event_loc_ref: encode64(newState.event_loc),
        event_referrer_ref: encode64(newState.event_referrer),
      };
    }
    case ROUTE_CHANGE: {
      const prefix = `${window.location.protocol}//${window.location.host}`;
      const { pathname, search, query } = action.payload;
      const url = `${prefix}${pathname}${search}`;
      const isDr = pathname.indexOf('daily-recommendations') >= 0;
      const isProfileIdSearch = !!query.txtprofileid;
      const refPath = getEventReferrerPath(query, isDr, isProfileIdSearch);

      if (state.event_loc_url === url) {
        return state;
      }
      const eventReferrer = loc(refPath);
      const eventReferrerRef = encode64(loc(refPath));

      return {
        ...state,
        event_loc: isDr ? loc('profile') : loc(pathname),
        event_loc_ref: isDr ? encode64(loc('profile')) : encode64(loc(pathname)),
        event_loc_url: url,
        event_referrer: eventReferrer,
        event_referrer_ref: state.event_loc_ref || eventReferrerRef,
        event_referrer_url: state.event_loc_url || window.document.referrer,
        ga_event_action_label: `${isDr ? loc('profile') : loc(pathname)}&${eventReferrer}`,
      };
    }
    case UPDATE_DIMENSIONS_RESIZE:
      return {
        ...state,
        device: action.payload.layout,
        platform: `${device.getIsMobile() ? 'wap' : 'web'}-${window.location.hostname}`,
      };
    default:
      return state;
  }
}
