const onChatDestroy = () => (): void => {
  // console.log('destroying!');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (window && (window as any).chatSocket) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).chatSocket.disconnect();
  }
};

export default onChatDestroy;
