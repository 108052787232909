const PARSER_TYPES = {
  N: 'null',
  i: 'int',
  d: 'float',
  b: 'boolean',
  s: 'string',
  a: 'array-object',
  C: 'serializable-class',
  O: 'notserializable-class',
};
export default class Parser {
  constructor(contents, index, options) {
    this.contents = contents;
    this.index = index;
    this.options = options;
  }

  error(message = 'Syntax Error') {
    return new Error(`${message} at index ${this.index} while unserializing payload`);
  }

  advance(index) {
    this.index += index;
  }

  readAhead(index) {
    const contents = this.peekAhead(index);
    this.index += index;
    return contents;
  }

  readUntil(expected) {
    const index = this.contents.indexOf(expected, this.index);

    if (index === -1) {
      throw this.error(`Expected '${expected}'`);
    }

    return this.readAhead(index - this.index);
  }

  peekAhead(index) {
    return this.contents.toString(this.options.encoding, this.index, this.index + index);
  }

  seekExpected(contents) {
    const slice = this.readAhead(contents.length);

    if (slice !== contents) {
      this.index -= contents.length;
      throw this.error(`Expected '${contents}'`);
    }
  }

  getType() {
    const [type, ps] = this.readAhead(2);
    const parserType = PARSER_TYPES[type];

    if (!parserType) {
      throw this.error('Unknown type');
    }

    if (parserType === 'null' ? ps !== ';' : ps !== ':') {
      throw this.error();
    }

    return parserType;
  }

  getLength() {
    const length = parseInt(this.readUntil(':'), 10);

    if (Number.isNaN(length)) {
      throw this.error();
    }

    return length;
  }

  getByLength(startSequence, endSequence, callback) {
    const length = this.getLength();
    this.seekExpected(`:${startSequence}`);
    const result = callback(length);
    this.seekExpected(endSequence);
    return result;
  }
}
