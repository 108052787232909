import React from 'react';
import PropTypes from 'prop-types';
import DeferredAsyncComponent from 'components/DeferredAsyncComponent';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = DeferredAsyncComponent(() => import(/* webpackChunkName: "FooterPartial.desktop" */ './desktop'));
const Mobile = DeferredAsyncComponent(() => import(/* webpackChunkName: "FooterPartial.mobile" */ './mobile'));

const FooterPartial = props => (props.isMobileScreen ? <Mobile /> : <Desktop />);
FooterPartial.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(FooterPartial);
