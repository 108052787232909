import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "SearchPage.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "SearchPage.mobile" */ './mobile'));

const SearchPage = props => (props.isMobileScreen ? <Mobile /> : <Desktop />);

SearchPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(SearchPage);
