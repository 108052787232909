import React from 'react';
import { children as childrenPropTypes } from 'PropTypes';
import theme from 'theme/common';

const Main = props => (
  <div className="CoreMain" style={theme.main}>
    {props.children}
  </div>
);

Main.propTypes = {
  children: childrenPropTypes.isRequired,
};

export default Main;
