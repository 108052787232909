import device from 'helpers/device';

const isPage = pageName => {
  const regexStr = `^/${pageName}/`;
  const regexp = new RegExp(regexStr, 'i');
  return regexp.test(window.location.pathname);
};

const isStoppage = () => isPage('stop-page');

const isNameStoppage = () => window.location.pathname === '/stop-page/name';

const isPhotoUploadStoppage = () => window.location.pathname === '/stop-page/photo-upload';

const isPayToStayStoppage = () => window.location.pathname === '/stop-page/pay-to-stay';

const isRegPhotoUpload = () => window.location.pathname === '/stop-page/album-upload';

const isSelfiePage = () => window.location.pathname === '/stop-page/selfie-verification';

const isAppMigrationPage = () => window.location.pathname === '/stop-page/app-migration';

const isRogMobileRepetation = () => window.location.pathname === '/stop-page/rog-mobile-repetation';

const isCrmScreening = () => window.location.pathname === '/crm-screening';

const isProfileCreationPage = () => isPage('profile-creation');

const isForgotPasswordPage = () => isPage('forgot-password');

const isSearchPage = () => window.location.pathname === '/search';

const isChatPage = () => window.location.pathname.includes('/inbox/chats');

const isRegPhotoPage = () => isPage('registration');

export const isCartPage = () => window.location.pathname === '/cart' || isPage('cart');

export const isPaymentPage = () => window.location.pathname === '/payment';

export const isPaymentThankYouPage = () => window.location.pathname === '/payment/thankyou';

export const isUpiAuthPage = () => isPage('upiauthentication');

const isMyShaadiPage = () => isPage('my-shaadi');

const isPaymentCartPages = () =>
  ['/cart', '/payment', '/payment/thankyou', '/compare-plans', '/payment/responseorder/my-cart', '/match-price', '/order', '/fishnet'].includes(
    window.location.pathname,
  );

const isCarouselProfilePage = () => window.location.pathname === '/profile' && window.location.search.indexOf('&referrerUrl') !== -1;

const isSelfProfilePreview = () => window.location.pathname === '/profile/preview';

const isInboxPending = () => window.location.pathname === '/inbox/pending/interests';

const isMobilePaymentpage = () => device.isMobile && ['/payment'].includes(window.location.pathname);

const isMyOrderPage = () => window.location.pathname === '/order';
const isShaadiEventFeedbackPage = () => window.location.pathname.indexOf('/shaadi-live-feedback') >= 0;

export const isMatchPricePage = () => window.location.pathname === '/match-price';

export default function() {
  return {
    isPaymentCartPages: isPaymentCartPages(),
    isRegPhotoPage: isRegPhotoPage(),
    isStoppage: isStoppage(),
    isCartPage: isCartPage(),
    isMyShaadiPage: isMyShaadiPage(),
    isProfileCreationPage: isProfileCreationPage(),
    isForgotPasswordPage: isForgotPasswordPage(),
    isPaymentThankYouPage: isPaymentThankYouPage(),
    isSelfProfilePreview: isSelfProfilePreview(),
    isPaymentPage: isPaymentPage(),
    isRegLoggerPage: isProfileCreationPage() || isNameStoppage() || isRegPhotoUpload() || isSelfiePage() || isAppMigrationPage(),
    isNoResponseSkip: isPhotoUploadStoppage(),
    isPayToStayStoppage: isPayToStayStoppage(),
    isRegPhotoUpload: isRegPhotoUpload(),
    isRogMobileRepetation: isRogMobileRepetation(),
    isCrmScreening: isCrmScreening(),
    isInboxPending: isInboxPending(),
    isSearchPage: isSearchPage(),
    canShowChat:
      !isSelfProfilePreview() && !isStoppage() && !isProfileCreationPage() && !isForgotPasswordPage() && !isRegPhotoPage() && !isPaymentCartPages(),
    canCallAppInit: !isStoppage() && !isProfileCreationPage() && !isRegPhotoPage() && !isForgotPasswordPage(),
    canShowLayerPartial: (!isPaymentCartPages() || isMobilePaymentpage()) && !isProfileCreationPage() && !isForgotPasswordPage() && !isMyOrderPage(),
    ignoreContentWrap: isStoppage() || isProfileCreationPage() || isForgotPasswordPage() || isRegPhotoPage(),
    ignoreContainer: isStoppage() || isProfileCreationPage() || isRegPhotoPage() || isForgotPasswordPage(),
    canAllowRightClick: isProfileCreationPage() || isForgotPasswordPage() || isStoppage() || isChatPage(),
    needMainPadding: !isStoppage() && !isProfileCreationPage() && !isForgotPasswordPage() && !isRegPhotoPage() && !isCarouselProfilePage(),
    isUpiAuthPage: isUpiAuthPage(),
    isShaadiEventFeedbackPage: isShaadiEventFeedbackPage(),
  };
}
