/* eslint camelcase: 0 */
import { httpResponse, createCookie, deleteCookie, uidFromAccessToken, currentDomain } from '../helpers';
import autoLogin from '../autoLogin';

const notFound = (logger, _method, _path) => {
  logger.info('(response)', 404);
  return Promise.reject(httpResponse(404, { code: 'E_404', message: 'No such service.' }));
};

const login = (logger, { appKey, platform, referer, accessToken, autologinToken, authHistoryToken }) => {
  const uid = uidFromAccessToken(accessToken);
  const auth = {
    uid,
    accessToken,
    autologinToken,
    authHistoryToken,
    appKey,
    platform,
    referer,
    domain: currentDomain(),
    nextAction: undefined,
  };
  return Promise.resolve(auth);
};

const noAuthResponse = httpResponse(401, {
  code: 'E_NO_AUTH',
  message: 'No valid access / autlogin / se token found',
});

const tokenLogin = (logger, { autologinToken, authHistoryToken, appKey, platform, referer }) =>
  autoLogin({ token: autologinToken, appKey, platform })
    .then(response => {
      const data = response.data.data || {};
      const { access_token: accessToken, domain, next_action: nextAction } = data;
      const regLoggerMemberStatus = [
        'PROFILE_COMPLETE',
        'PROFILE_COMPLETE_SUSPENDED',
        'ABOUTME_COMPLETE',
        'PROFILE_VERIFY',
        'PROFILE_VERIFY_PHOTO',
        'PROFILE_VERIFY_SUSPENDED',
        'PROFILE_VERIFY_SUSPENDED_PHOTO',
        'PROFILE_DEACTIVATED',
        'PROFILE_UNDER_VERIFICATION',
      ];
      const cookieName = regLoggerMemberStatus.includes(nextAction) ? 'reg_logger' : 'abc';
      deleteCookie('abc');
      deleteCookie('reg_logger');
      createCookie(cookieName, accessToken, 40 * 60);
      const auth = {
        uid: uidFromAccessToken(accessToken),
        accessToken,
        autologinToken,
        authHistoryToken,
        appKey,
        platform,
        referer,
        domain,
        nextAction,
      };
      window.location.reload(false);
      return Promise.resolve(auth);
    })
    .catch(error => Promise.reject(error));

// const linkLogin = (logger, { ml, se, authHistoryToken, appKey, platform, referer }) => Promise.reject(noAuthResponse);

// eslint-disable-line prettier/prettier
const authenticate = (logger, params, headers = {}) => {
  const { accessToken = '', autologinToken = '' } = headers;
  if (accessToken.length >= 10) {
    return login(logger, headers);
  }
  if (autologinToken.length > 5) {
    return tokenLogin(logger, headers);
  }
  // @todo autlogin using ml (memberlogin) and se for users coming from notifications, mailers and sms
  /* const { ml, se } = params;
   if (ml || se) {
     const linkLoginParams = { ...params, ...headers };
    return linkLogin(logger, linkLoginParams); 
  } */
  return Promise.reject(noAuthResponse);
};

export default {
  notFound,
  authenticate,
};
