import { ROUTE_CHANGE, COUNTS_CACHE, COUNTS_SUCCESS, EVT_REF } from 'actionTypes';

export default () => (state, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case ROUTE_CHANGE:
      return {
        ...state,
        isActive: payload && (state.path === payload.pathname || (state.otherPaths || []).includes(payload.pathname)),
      };
    case COUNTS_CACHE:
    case COUNTS_SUCCESS: {
      if (!payload || !payload.counts) {
        return state;
      }
      const countAPIData = payload.counts[state.newCntKey] || payload.counts[state.keyB] || payload.counts[state.key];
      const isNew = !!payload.counts[state.newCntKey];
      const readCount = payload.counts[state.key] || 0;
      // const count = state.key === 'matches-preferred' ? state.count : countAPIData;
      const count = state.key === 'matches-preferred' ? readCount : countAPIData;

      return count === undefined ? state : { ...state, count, isNew, readCount };
    }
    case EVT_REF: {
      return {
        ...state,
        isActive: state.key === payload || (state.refs || []).includes(payload),
      };
    }
    default:
      return state;
  }
};
