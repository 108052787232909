import { serialize, unserialize } from '../../../helpers/serializer';
import { Cookie, createCookie, myDomain, getCookie } from '../../../helpers/cookies';

const tomorrrow = () => {
  const now = new Date();
  const expire = new Date();
  expire.setFullYear(now.getFullYear());
  expire.setMonth(now.getMonth());
  expire.setDate(now.getDate() + 1);
  expire.setHours(0);
  expire.setMinutes(0);
  expire.setSeconds(0);
  return expire.toString();
};

const n = (context, key) => `sw_${context}_${key}`.slice(0, 18);

const canShow = (context, key) => getCookie(n(context, key)) !== 'Y';

const markShown = (context, key, secs) => createCookie(n(context, key), 'Y', secs || 4 * 3600);

const markShownForToday = (context, key) => createCookie(n(context, key), 'Y', tomorrrow());

// layerBanner :: Cookie
const layerBanner = Cookie('layer_banners', { parse: unserialize, stringify: serialize, defaultExpiry: tomorrrow() });

// :: String -> Boolean
layerBanner.hasShownLayer = key => !layerBanner.isEmpty() && !!layerBanner.get()[key];

// :: ('mobile'|'web') -> 'MILY'|'LYR'
layerBanner.getKey = layout => (layout === 'mobile' ? 'MILY' : 'LYR');

export default {
  canShow,
  markShown,
  markShownForToday,
  myDomain,
  createCookie,
  getCookie,
  layerBanner,
};
