import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AsyncComponent from 'components/AsyncComponent';

import Main from 'core/Main';
import Content from 'core/Content';

import NotFound from 'pages/NotFound';
import FourOFour from 'pages/FourOFour';
import SearchPartnerPage from 'pages/SearchPartnerPage';
import SearchPage from 'pages/SearchPage';
import ProfilePage from 'pages/ProfilePage';
import MatchesGroupPage from 'pages/MatchesGroupPage';
import CartPage from 'pages/CartPage';
import PaymentPage from 'pages/PaymentPage';
import OrderSuccessPage from 'pages/OrderSuccessPage';
import ChatPartial from 'partials/ChatPartial';
import LayerPartial from 'partials/LayerPartial';
import HeaderPartial from 'partials/HeaderPartial';
import FooterPartial from 'partials/FooterPartial';
import LayoutPartial from 'partials/LayoutPartial';
import DownloadAppPartial from 'partials/DownloadAppPartial';
import IntentsListingPage, { PageType as IntentsPageType } from 'pages/IntentsListingPage';
import ComparePlans from 'pages/ComparePlans';
import DebugPage from 'pages/DebugPage';

import UpgradeToPremiumPage from 'pages/UpgradeToPremiumPage';

import InboxRoutes from 'routes/inboxRoutes';

import Spinner from 'components/Spinner';

const ProfileDeletePage = AsyncComponent(
  () => import(/* webpackChunkName: "ProfileDeletion.desktop" */ '~/pages/ProfileDeletePage/desktop.tsx'),
  Spinner,
);

const MyPhotos = AsyncComponent(() => import(/* webpackChunkName: "MyPhotos" */ 'pages/MyPhotos'));
const AccountSettingsPage = AsyncComponent(() => import(/* webpackChunkName: "AccountSettingsPage" */ 'pages/AccountSettings'));
const InboxAccountSettingsPage = AsyncComponent(() => import(/* webpackChunkName: "AccountSettingsPage" */ 'pages/InboxAccountSettings'));
const MyProfilePage = AsyncComponent(() => import(/* webpackChunkName: "MyProfilePage" */ 'pages/MyProfilePage'));
const DailyRecommendationsPage = AsyncComponent(() => import(/* webpackChunkName: "DailyRecommendationsPage" */ 'pages/DailyRecommendationsPage'));
const MyShaadiDashboard = AsyncComponent(() => import(/* webpackChunkName: "MyShaadiDashboard" */ 'pages/MyShaadiDashboard'));
const MyOrderPage = AsyncComponent(() => import(/* webpackChunkName: "MyOrderPage" */ 'pages/MyOrderPage'));
const SubmitStoryPage = AsyncComponent(() => import(/* webpackChunkName: "SubmitStoryPage" */ 'pages/SubmitStoryPage/index.tsx'));

const OtherRoutes = () => (
  <LayoutPartial>
    <Switch>
      <Route component={HeaderPartial} />
    </Switch>
    <Content>
      <Main>
        <Switch>
          <Route path="/" exact component={() => <Redirect to="/search/partner?ref=splash" />} />
          <Route path="/inbox" component={InboxRoutes} />
          <Route path="/profile-delete" component={ProfileDeletePage} />
          <Route path="/search/partner" exact component={SearchPartnerPage} />
          <Route path="/search/partner/viewed" exact component={SearchPartnerPage} />
          <Route path="/search/partner/index" exact component={SearchPartnerPage} />
          <Route path="/search/partner/index/gtrk/2" exact component={SearchPartnerPage} />
          <Route path="/search/new-matches" exact component={SearchPage} />
          <Route path="/search/new-matches/viewed" exact component={SearchPage} />
          <Route path="/search/near-me" exact component={SearchPage} />
          <Route path="/search/near-me/viewed" exact component={SearchPage} />
          <Route path="/search/broader" exact component={SearchPage} />
          <Route path="/search/broader/viewed" exact component={SearchPage} />
          <Route path="/search/personal" exact component={SearchPage} />
          <Route path="/search/ematchmaker" exact component={SearchPage} />
          <Route path="/search/discovery/recently-joined" exact component={SearchPage} />
          <Route path="/search/discovery/recently-joined-viewed" exact component={SearchPage} />
          <Route path="/search/discovery/premium" exact component={SearchPage} />
          <Route path="/search/discovery/premium-viewed" exact component={SearchPage} />
          <Route path="/search/discovery/recent-visitors" exact component={SearchPage} />
          <Route path="/search/discovery/recent-visitors-viewed" exact component={SearchPage} />
          <Route path="/search/basic_search" exact component={SearchPage} />
          <Route path="/search/smart_search" exact component={SearchPage} />
          <Route path="/search/online" exact component={SearchPage} />
          <Route path="/search/specialcase" exact component={SearchPage} />
          <Route path="/search/astro" exact component={SearchPage} />
          <Route path="/search/matrimonial" exact component={SearchPage} />
          <Route path="/profile" exact component={ProfilePage} />
          <Route path="/profile/discovery" exact component={() => <MatchesGroupPage grpType="discover" />} />
          <Route path="/profile/viewed" exact component={() => <MatchesGroupPage grpType="intents" />} />
          <Route path="/my-shaadi" exact component={() => <MyShaadiDashboard />} />
          <Route path="/profile/daily-recommendations" exact component={DailyRecommendationsPage} />
          <Route path="/profile/shortlist/*" exact component={() => <Redirect to="/profile/shortlist" />} />
          <Route path="/profile/shortlist" exact component={() => <IntentsListingPage type={IntentsPageType.Shortlisted} />} />
          <Route path="/profile/viewed-profiles" exact component={() => <IntentsListingPage type={IntentsPageType.Viewed} />} />
          <Route path="/profile/blocked-members" exact component={() => <IntentsListingPage type={IntentsPageType.Blocked} />} />
          <Route path="/profile/ignored-members" exact component={() => <IntentsListingPage type={IntentsPageType.Ignored} />} />
          <Route path="/my-shaadi/photo" exact component={MyPhotos} />
          <Route path="/order" exact component={MyOrderPage} />
          <Route path="/cart" exact component={CartPage} />
          <Route path="/payment" exact component={PaymentPage} />
          <Route path="/payment/thankyou" exact component={OrderSuccessPage} />
          <Route path="/compare-plans" exact component={ComparePlans} />
          <Route path="/debug-r" exact component={DebugPage} />
          <Route path="/uva" exact component={FourOFour} />
          <Route path="/my-shaadi/my-account" exact component={AccountSettingsPage} />
          <Route path="/my-shaadi/my-account/inbox" exact component={InboxAccountSettingsPage} />
          <Route path="/my-shaadi/my-profile" exact component={MyProfilePage} />
          <Route path="/submit-story/:memberlogin" component={SubmitStoryPage} />
          <Route path="/upgrade-to-premium" exact component={UpgradeToPremiumPage} />
          <Route path="/404" component={FourOFour} />
          <Route component={NotFound} />
        </Switch>

        <Switch>
          <Route component={FooterPartial} />
        </Switch>
      </Main>

      <ChatPartial />
    </Content>

    <DownloadAppPartial />
    <LayerPartial />
  </LayoutPartial>
);

export default OtherRoutes;
