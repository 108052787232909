/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Location } from '@shaaditech/types/location';
// import addExperimentToDatadogContext from '~/helpers/addExperimentToDatadogContext';
import addToDatadogContext from '~/helpers/addToDatadogContext';
import { getDevicePerfBuckets } from '~/helpers/device';
import initializeDatadogRum from '~/helpers/initializeDatadogRum';

// Datadog is only imported and initialized on these pages to reduces additional payload.
const DATADOG_TRACKED_PAGES = [
  '/cart',
  '/payment/thankyou',
  '/payment',
  '/match-price',
  '/payment/responseorder/my-cart',
  '/search/partner',
  '/search/partner/viewed',
  '/search/partner/index',
  '/search/partner/index/gtrk/2',
  '/profile/daily-recommendations',
  '/profile',
  '/search/near-me',
  '/profile/viewed',
  '/profile/viewed-profiles',
  '/profile/shortlist',
  '/profile/blocked-members',
  '/profile/discovery',
  '/search/discovery/recent-visitors',
  '/search/discovery/premium', // more matches -- premium matches
  '/search/broader', // more matches -- members u may like
  '/search/personal', // more matches -- members looking for me
  '/my-shaadi', // dashboard page
];

type InitializeDatadogProps = {
  trackedPages: string[];
  location: Location;
};

export const InitializeDatadog = ({ trackedPages = DATADOG_TRACKED_PAGES, location }: InitializeDatadogProps) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeDatadog = useCallback(async () => {
    initializeDatadogRum({
      sampleRate: 5,
    });
  }, []);

  useEffect(() => {
    if (!isInitialized && trackedPages.includes(location.pathname)) {
      (async () => {
        await initializeDatadog();
        // addExperimentToDatadogContext(PROFILE_OPTIMISATION_EXP); // Removing Get Actual Experiments Bucket API Call
        addToDatadogContext('devicePerformanceBuckets', getDevicePerfBuckets());
        setIsInitialized(true);
      })();
    }
  }, [isInitialized, location, trackedPages]);

  return null;
};

// @ts-ignore
export default withRouter(InitializeDatadog);
