/**
 * !! INFO !!
 * This file is a helper utility, to help you register "api" routes anywhere (eg - your controllers).
 * This makes it unneccassary to have a central routes.js file
 *
 * Read src/api/README.md for more information
 */

import { mapKeysToValue } from '../helpers/object';
import { constant } from '../helpers/common';

/*
type RoutePath = String;
type RequestMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';
type Controller = Function;

type Config = {
  isPublic :: Boolean,
};

type Routes = {
  [key: RequestMethod]: Object Controller,
};
*/

// METHODS :: [RequestMethod]
export const METHODS = ['get', 'post', 'put', 'patch', 'delete'];

// registerNewRoute :: (RequestMethod, RoutePath, Controller, Config) -> Routes
export const registerNewRoute = (method, path, controller, { isPublic = false } = {}, routesMap) => {
  controller.isPublic = isPublic;
  return {
    ...routesMap,
    [method]: {
      ...routesMap[method],
      [path]: controller,
    },
  };
};

// ApiRouter (constructor) :: () -> ApiRouter
export const ApiRouter = () => {
  // routes :: Routes
  let routes = mapKeysToValue(constant({}), METHODS);

  // addRoute :: RequestMethod -> (RoutePath, Controller, Config) -> Routes
  const addRoute = method => (path, controller, config = {}) => {
    routes = registerNewRoute(method, path, controller, config, routes);
    return controller;
  };

  return {
    // [methodName: RequestMethod] :: (RoutePath, Controller, Config) -> ()
    ...mapKeysToValue(addRoute, METHODS),

    // register :: (RequestMethod, RoutePath, Controller, Config) -> ()
    register: (method, ...args) => addRoute(method, ...args),

    // getRoutes :: () -> Routes
    getRoutes: () => ({ ...routes }),

    // getRoute :: (Method, RoutePath) -> Controller,
    getRoute: (method, path) => routes[method][path],
  };
};

// :: ApiRouter
export default ApiRouter();
