import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { history as historyPropTypes } from 'PropTypes';
import monitorHistory from 'actions/onAppInit/monitorHistory';

const MonitorHistory = props => {
  useEffect(() => props.monitorHistory(props.history), []);
  return null;
};

MonitorHistory.propTypes = {
  history: PropTypes.shape(historyPropTypes).isRequired,
  monitorHistory: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapActionToProps = { monitorHistory };

const composedHoc = compose(withRouter, connect(mapStateToProps, mapActionToProps), memo);

export default composedHoc(MonitorHistory);
