import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { children as childrenPropTypes } from 'PropTypes';
import './style.scss';

import _isEqual from 'lodash/isEqual';
import { getTheme } from 'theme/common';

const Content = ({ ignoreContentWrap, layout, children, location }) => {
  const [pageModifier, setPageModifier] = useState('');
  const [contentStyles, setContentStyles] = useState(getTheme().content);

  useEffect(() => {
    const newThemeContent = getTheme().content;
    if (!_isEqual(contentStyles, newThemeContent)) {
      setContentStyles(newThemeContent);
    }
  }, [layout]);

  useEffect(() => {
    setPageModifier(`${layout}${location.pathname.split('/').join('-')}`);
  }, [layout, location]);

  if (ignoreContentWrap) return children;

  return (
    <div className={`CoreContent CoreContent--${pageModifier}`} style={contentStyles}>
      {children}
    </div>
  );
};

Content.propTypes = {
  ignoreContentWrap: PropTypes.bool.isRequired,
  children: childrenPropTypes.isRequired,
};

const selector = state => ({
  ignoreContentWrap: state.session.ignoreContentWrap,
  layout: state.view.layout,
});

export default withRouter(connect(selector)(Content));
