export const UNAUTH = 'UNAUTH';

export const SET_LAYOUT = 'SET_LAYOUT';

export const M_DRAWER_ACTION = 'M_DRAWER_ACTION';

export const ALERT_SHOW = 'ALERT_SHOW';
export const ALERT_HIDE = 'ALERT_HIDE';
export const ALERT_HIDE_KEY = 'ALERT_HIDE_KEY';

export const METADATA = 'METADATA';
export const MOBILE_CARD_SET_STATUS = 'MOBILE_CARD_SET_STATUS';
export const EOI_REQUEST = 'EOI_REQUEST';
export const EOI_SUCCESS = 'EOI_SUCCESS';
export const EOI_FAIL = 'EOI_FAIL';
export const CONTACT_EOI_REQUEST = 'CONTACT_EOI_REQUEST';
export const CONTACT_EOI_SUCCESS = 'CONTACT_EOI_SUCCESS';
export const CONTACT_EOI_FAIL = 'CONTACT_EOI_FAIL';
export const PHOTO_EOI_REQUEST = 'PHOTO_EOI_REQUEST';
export const PHOTO_EOI_SUCCESS = 'PHOTO_EOI_SUCCESS';
export const PHOTO_EOI_FAIL = 'PHOTO_EOI_FAIL';

export const PROFILE_BACKGROUND_SUCCESS = 'PROFILE_BACKGROUND_SUCCESS';
export const PROFILES_BACKGROUND_SUCCESS = 'PROFILES_BACKGROUND_SUCCESS';
export const PROFILES_BACKGROUND_REQUEST = 'PROFILES_BACKGROUND_REQUEST';

export const CONTACT_GET_SMS_REQUEST = 'CONTACT_GET_SMS_REQUEST';
export const CONTACT_GET_SMS_SUCCESS = 'CONTACT_GET_SMS_SUCCESS';
export const CONTACT_GET_SMS_ERROR = 'CONTACT_GET_SMS_ERROR';

export const PROFILE_HOVER = 'PROFILE_HOVER';

export const PHONE_VERIFICATION_REQUEST_SENT = 'PHONE_VERIFICATION_REQUEST_SENT';

export const SELECTED_SHORTLISTS_REQUEST = 'SELECTED_SHORTLISTS_REQUEST';
export const SELECTED_SHORTLISTS_SUCCESS = 'SELECTED_SHORTLISTS_SUCCESS';
export const SELECTED_SHORTLISTS_FAIL = 'SELECTED_SHORTLISTS_FAIL';

export const PREFERRED_SEARCH_REQUEST = 'PREFERRED_SEARCH_REQUEST';
export const PREFERRED_SEARCH_CACHE = 'PREFERRED_SEARCH_CACHE';
export const PREFERRED_SEARCH_SUCCESS = 'PREFERRED_SEARCH_SUCCESS';
export const PREFERRED_SEARCH_CANCEL = 'PREFERRED_SEARCH_CANCEL';
export const PREFERRED_SEARCH_FAIL = 'PREFERRED_SEARCH_FAIL';
export const PREFERRED_SEARCH_FREEZE = 'PREFERRED_SEARCH_FREEZE';

export const OTHER_SEARCH_REQUEST = 'OTHER_SEARCH_REQUEST';
export const OTHER_SEARCH_SUCCESS = 'OTHER_SEARCH_SUCCESS';
export const OTHER_SEARCH_FAIL = 'OTHER_SEARCH_FAIL';
export const OTHER_SEARCH_FREEZE = 'OTHER_SEARCH_FREEZE';
export const OTHER_SEARCH_TYPE_SET = 'OTHER_SEARCH_TYPE_SET';

export const PROFILE_QUEUE_PREDICT = 'PROFILE_QUEUE_PREDICT';
export const PROFILE_QUEUE_REQUEST = 'PROFILE_QUEUE_REQUEST';
export const PROFILE_QUEUE_SUCCESS = 'PROFILE_QUEUE_SUCCESS';
export const PROFILE_QUEUE_FAIL = 'PROFILE_QUEUE_FAIL';

export const PREFERRED_PHOTOS_REQUEST = 'PREFERRED_PHOTOS_REQUEST';
export const PREFERRED_PHOTOS_CACHE = 'PREFERRED_PHOTOS_CACHE';
export const PREFERRED_PHOTOS_SUCCESS = 'PREFERRED_PHOTOS_SUCCESS';
export const PREFERRED_PHOTOS_CANCEL = 'PREFERRED_PHOTOS_CANCEL';
export const PREFERRED_PHOTOS_FAIL = 'PREFERRED_PHOTOS_FAIL';

export const OTHERSEARCH_PHOTOS_REQUEST = 'OTHERSEARCH_PHOTOS_REQUEST';
export const OTHERSEARCH_PHOTOS_CACHE = 'OTHERSEARCH_PHOTOS_CACHE';
export const OTHERSEARCH_PHOTOS_SUCCESS = 'OTHERSEARCH_PHOTOS_SUCCESS';
export const OTHERSEARCH_PHOTOS_CANCEL = 'OTHERSEARCH_PHOTOS_CANCEL';
export const OTHERSEARCH_PHOTOS_FAIL = 'OTHERSEARCH_PHOTOS_FAIL';

export const PREFERRED_FACET_UPDATE = 'PREFERRED_FACET_UPDATE';

export const OTHERSEARCH_FACET_UPDATE = 'OTHERSEARCH_FACET_UPDATE';

export const PREFERRED_SEARCH_FLASH = 'PREFERRED_SEARCH_FLASH';

export const PREFERRED_SEARCH_LOADING = 'PREFERRED_SEARCH_LOADING';

export const PREMIUM_BANNNER_REQUEST = 'PREMIUM_BANNNER_REQUEST';
export const PREMIUM_BANNNER_CACHE = 'PREMIUM_BANNNER_CACHE';
export const PREMIUM_BANNNER_SUCCESS = 'PREMIUM_BANNNER_SUCCESS';
export const PREMIUM_BANNNER_FAIL = 'PREMIUM_BANNNER_FAIL';

export const CHAT_SKEW = 'CHAT_SKEW';
export const CHAT_SHOW_FLASH = 'CHAT_SHOW_FLASH';
export const CHAT_HIDE_FLASH = 'CHAT_HIDE_FLASH';
export const CHAT_HISTORY_REQUEST = 'CHAT_HISTORY_REQUEST';
export const CHAT_HISTORY_SUCCESS = 'CHAT_HISTORY_SUCCESS';
export const CHAT_HISTORY_FAIL = 'CHAT_HISTORY_FAIL';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAIL = 'NOTIFICATIONS_FAIL';
export const NOTIFICATIONS_TOAST_SHOW = 'NOTIFICATIONS_TOAST_SHOW';
export const NOTIFICATIONS_TOAST_HIDE = 'NOTIFICATIONS_TOAST_HIDE';

export const CHAT_DATA_REQUEST = 'CHAT_DATA_REQUEST';
export const CHAT_DATA_SUCCESS = 'CHAT_DATA_SUCCESS';
export const CHAT_DATA_CACHE = 'CHAT_DATA_CACHE';
export const CHAT_DATA_FAIL = 'CHAT_DATA_FAIL';
export const CHAT_DATA_PROFILE_SUCCESS = 'CHAT_DATA_PROFILE_SUCCESS';

export const CHAT_CONNECTING = 'CHAT_CONNECTING';
export const CHAT_CONNECTED = 'CHAT_CONNECTED';
export const CHAT_DISCONNECTED = 'CHAT_DISCONNECTED';
export const CHAT_PRESENCE = 'CHAT_PRESENCE';

export const CHAT_OTHER_IS_TYPING = 'CHAT_OTHER_IS_TYPING';
export const CHAT_OTHER_IS_NOT_TYPING = 'CHAT_OTHER_IS_NOT_TYPING';

export const CHAT_MESSAGES_RECEIVED = 'CHAT_MESSAGES_RECEIVED';
export const CHAT_MESSAGE_STATUS = 'CHAT_MESSAGE_STATUS';
export const CHAT_INCREMENT_COUNT = 'CHAT_INCREMENT_COUNT';

export const CHAT_WINDOW_ADD = 'CHAT_WINDOW_ADD';
export const CHAT_WINDOW_OPEN = 'CHAT_WINDOW_OPEN';
export const CHAT_WINDOW_CLOSE = 'CHAT_WINDOW_CLOSE';
export const CHAT_WINDOW_MINIMIZE = 'CHAT_WINDOW_MINIMIZE';
export const CHAT_WINDOW_UNREAD = 'CHAT_WINDOW_UNREAD';
export const CHAT_WINDOW_STACK = 'CHAT_WINDOW_STACK';

export const CHAT_WINDOW_REQUEST = 'CHAT_WINDOW_REQUEST';
export const CHAT_WINDOW_SUCCESS = 'CHAT_WINDOW_SUCCESS';
export const CHAT_WINDOW_FAIL = 'CHAT_WINDOW_FAIL';

export const CHAT_SETTINGS_CHANGE = 'CHAT_SETTINGS_CHANGE';
export const CHAT_SETTINGS_CACHE = 'CHAT_SETTINGS_CACHE';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_CACHE = 'AUTH_CACHE';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const SUCCESS_STORIES_REQUEST = 'SUCCESS_STORIES_REQUEST';
export const SUCCESS_STORIES_CACHE = 'SUCCESS_STORIES_CACHE';
export const SUCCESS_STORIES_SUCCESS = 'SUCCESS_STORIES_SUCCESS';
export const SUCCESS_STORIES_FAIL = 'SUCCESS_STORIES_FAIL';

export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_CACHE = 'SESSION_CACHE';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAIL = 'SESSION_FAIL';

export const COUNTS_REQUEST = 'COUNTS_REQUEST';
export const COUNTS_CACHE = 'COUNTS_CACHE';
export const COUNTS_SUCCESS = 'COUNTS_SUCCESS';
export const COUNTS_FAIL = 'COUNTS_FAIL';

export const EVT_REF = 'EVT_REF';
export const ROUTE_CHANGE = 'ROUTE_CHANGE';

export const TRUST_BADGE_REQUEST = 'TRUST_BADGE_REQUEST';
export const TRUST_BADGE_SUCCESS = 'TRUST_BADGE_SUCCESS';

export const MODAL_HIDE = 'MODAL_HIDE';
export const MODAL_SHOW = 'MODAL_SHOW';

export const SHORTLIST_CREATE_REQUEST = 'SHORTLIST_CREATE_REQUEST';
export const SHORTLIST_CREATE_SUCCESS = 'SHORTLIST_CREATE_SUCCESS';
export const SHORTLIST_CREATE_FAIL = 'SHORTLIST_CREATE_FAIL';

export const HIDE_DRAFT_FLASH = 'HIDE_DRAFT_FLASH';
export const FETCH_DRAFTS_REQUEST = 'FETCH_DRAFTS_REQUEST';
export const FETCH_DRAFTS_FAIL = 'FETCH_DRAFTS_FAIL';
export const FETCH_DRAFTS_SUCCESS = 'FETCH_DRAFTS_SUCCESS';
export const NEW_DRAFT_REQUEST = 'NEW_DRAFT_REQUEST';
export const NEW_DRAFT_FAIL = 'NEW_DRAFT_FAIL';
export const NEW_DRAFT_SUCCESS = 'NEW_DRAFT_SUCCESS';
export const MODIFY_DRAFT_REQUEST = 'MODIFY_DRAFT_REQUEST';
export const MODIFY_DRAFT_FAIL = 'MODIFY_DRAFT_FAIL';
export const MODIFY_DRAFT_SUCCESS = 'MODIFY_DRAFT_SUCCESS';
export const FETCH_DEFAULT_DRAFT_REQUEST = 'FETCH_DEFAULT_DRAFT_REQUEST';
export const FETCH_ALL_TYPE_DRAFT_REQUEST = 'FETCH_ALL_TYPE_DRAFT_REQUEST';
export const FETCH_DEFAULT_DRAFT_FAIL = 'FETCH_DEFAULT_DRAFT_FAIL';
export const FETCH_DEFAULT_DRAFT_SUCCESS = 'FETCH_DEFAULT_DRAFT_SUCCESS';
export const FETCH_ALL_TYPE_DRAFT_SUCCESS = 'FETCH_ALL_TYPE_DRAFT_SUCCESS';

export const PHOTO_PASSWORD_REQUEST_SUCCESS = 'PHOTO_PASSWORD_REQUEST_SUCCESS';

export const CHAT_USER_POPOVER_SUCCESS = 'CHAT_USER_POPOVER_SUCCESS';

export const LAYER_REQUEST = 'LAYER_REQUEST';
export const LAYER_SUCCESS = 'LAYER_SUCCESS';
export const LAYER_FAIL = 'LAYER_FAIL';

export const COLLECTION_DESTROY = 'COLLECTION_DESTROY';
export const COLLECTION_REQUEST = 'COLLECTION_REQUEST';
export const COLLECTION_SUCCESS = 'COLLECTION_SUCCESS';
export const COLLECTION_MOVE = 'COLLECTION_MOVE';
export const COLLECTION_FAIL = 'COLLECTION_FAIL';

export const PROFILE_LIST_REQUEST = 'PROFILE_LIST_REQUEST';
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS';
export const PROFILE_LIST_POSTSUCCESS = 'PROFILE_LIST_POSTSUCCESS';
export const PRELOAD_PROFILES_UIDS_EMPTY = 'PRELOAD_PROFILES_UIDS_EMPTY';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_CACHE = 'PROFILE_CACHE';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const PROFILE_PREPARE_NEXT = 'PROFILE_PREPARE_NEXT';
export const PROFILE_RESET = 'PROFILE_RESET';

export const WIDGET_MATCHES_REQUEST = 'WIDGET_MATCHES_REQUEST';
export const WIDGET_MATCHES_CACHE = 'WIDGET_MATCHES_CACHE';
export const WIDGET_MATCHES_SUCCESS = 'WIDGET_MATCHES_SUCCESS';
export const WIDGET_MATCHES_FAIL = 'WIDGET_MATCHES_FAIL';

export const UPDATE_DIMENSIONS_RESIZE = 'UPDATE_DIMENSIONS_RESIZE';
export const UPDATE_DIMENSIONS_SCROLL = 'UPDATE_DIMENSIONS_SCROLL';

export const HIDE_ALL_DROPDOWNS = 'HIDE_ALL_DROPDOWNS';
export const UPDATE_ACTIVE_DROPDOWN = 'UPDATE_ACTIVE_DROPDOWN';
export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL';

export const EDIT_ASTRO_REQUEST = 'EDIT_ASTRO_REQUEST';
export const EDIT_ASTRO_SUCCESS = 'EDIT_ASTRO_SUCCESS';
export const EDIT_ASTRO_FAIL = 'EDIT_ASTRO_FAIL';

export const FOUR_HOUR_REQUEST = 'FOUR_HOUR_REQUEST';
export const FOUR_HOUR_SUCCESS = 'FOUR_HOUR_SUCCESS';
export const FOUR_HOUR_FAIL = 'FOUR_HOUR_FAIL';
export const FOUR_HOUR_CLOSE = 'FOUR_HOUR_CLOSE';

export const CONNECT_MESSAGE_SUCCESS = 'CONNECT_MESSAGE_SUCCESS';

export const HISTORY_MODAL_SHOW = 'HISTORY_MODAL_SHOW';
export const REQUEST_MODAL_SHOW = 'REQUEST_MODAL_SHOW';

export const BLOCK_MEMBER_MISUSE = 'BLOCK_MEMBER_MISUSE';
export const REPORT_MODAL_REQUEST = 'REPORT_MODAL_REQUEST';
export const REPORT_MODAL_SUCCESS = 'REPORT_MODAL_SUCCESS';
export const REPORT_MODAL_ERROR = 'REPORT_MODAL_ERROR';
export const REPORT_MODAL_CLOSE = 'REPORT_MODAL_CLOSE';

export const REPORT_MISUSE_FAIL = 'REPORT_MISUSE_FAIL';

export const ATTACHMENT_UPLOAD_REQUEST = 'ATTACHMENT_UPLOAD_REQUEST';
export const ATTACHMENT_UPLOAD_PROGRESS = 'ATTACHMENT_UPLOAD_PROGRESS';
export const ATTACHMENT_UPLOAD_SUCCESS = 'ATTACHMENT_UPLOAD_SUCCESS';
export const ATTACHMENT_UPLOAD_ERROR = 'ATTACHMENT_UPLOAD_ERROR';
export const ATTACHMENT_UPLOAD_RESET = 'ATTACHMENT_UPLOAD_RESET';

export const FETCH_VIP_CONSULTANT_REQUEST = 'FETCH_VIP_CONSULTANT_REQUEST';
export const FETCH_VIP_CONSULTANT_FAIL = 'FETCH_VIP_CONSULTANT_FAIL';
export const FETCH_VIP_CONSULTANT_SUCCESS = 'FETCH_VIP_CONSULTANT_SUCCESS';

export const CONSULTANT_SUBMITTED_REQUEST = 'CONSULTANT_SUBMITTED_REQUEST';
export const CONSULTANT_SUBMITTED_SUCCESS = 'CONSULTANT_SUBMITTED_SUCCESS';
export const CONSULTANT_SUBMITTED_FAIL = 'CONSULTANT_SUBMITTED_FAIL';

export const MATCHES_GROUP_REQUEST = 'MATCHES_GROUP_REQUEST';
export const MATCHES_GROUP_SUCCESS = 'MATCHES_GROUP_SUCCESS';
export const MATCHES_GROUP_FAIL = 'MATCHES_GROUP_FAIL';
export const SAVED_SEARCH_REQUEST = 'SAVED_SEARCH_REQUEST';
export const SAVED_SEARCH_SUCCESS = 'SAVED_SEARCH_SUCCESS';
export const SAVED_SEARCH_FAIL = 'SAVED_SEARCH_FAIL';
export const SUBMIT_SAVED_SEARCH_SUCCESS = 'SUBMIT_SAVED_SEARCH_SUCCESS';
export const TOGGLE_SKIP = 'TOGGLE_SKIP';

export const DR_PROFILES_REQUEST = 'DR_PROFILES_REQUEST';
export const DR_PROFILES_SUCCESS = 'DR_PROFILES_SUCCESS';
export const DR_PROFILES_FAIL = 'DR_PROFILES_FAIL';
export const DR_QUEUE_SUCCESS = 'DR_QUEUE_SUCCESS';
export const DR_PHOTOS_SUCCESS = 'DR_PHOTOS_SUCCESS';
export const RESET_DR_CALL_COMPLETED = 'RESET_DR_CALL_COMPLETED';

export const DR_COUNTS_UPDATE = 'DR_COUNTS_UPDATE';
export const DR_PROFILE_RECOMMENDATION_ACTION_UPDATE = 'DR_PROFILE_RECOMMENDATION_ACTION_UPDATE';
export const END_PAGE_SHOW = 'END_PAGE_SHOW';

export const PROFILE_PHOTO_UPLOAD_SUCCESS = 'PROFILE_PHOTO_UPLOAD_SUCCESS';
export const PROFILE_PHOTO_UPLOAD_FAILED = 'PROFILE_PHOTO_UPLOAD_FAILED';
export const PROFILE_PHOTO_UPLOAD_MODAL_CLOSE = 'PROFILE_PHOTO_UPLOAD_MODAL_CLOSE';

export const FACEBOOK_ALBUM_PHOTO_REQUEST = 'FACEBOOK_ALBUM_PHOTO_REQUEST';
export const FACEBOOK_ALBUM_PHOTO_SUCCESS = 'FACEBOOK_ALBUM_PHOTO_SUCCESS';
export const FACEBOOK_ALBUM_PHOTO_FAIL = 'FACEBOOK_ALBUM_PHOTO_FAIL';

export const PHOTO_UPLOADED = 'PHOTO_UPLOADED';

export const GET_PROFILE_PHOTOS_REQUEST = 'GET_PROFILE_PHOTOS_REQUEST';
export const GET_PROFILE_PHOTOS_SUCCESS = 'GET_PROFILE_PHOTOS_SUCCESS';
export const GET_PROFILE_PHOTOS_FAIL = 'GET_PROFILE_PHOTOS_FAIL';

export const GET_REJECTED_ALBUM_PHOTOS_REQUEST = 'GET_REJECTED_ALBUM_PHOTOS_REQUEST';
export const GET_REJECTED_ALBUM_PHOTOS_SUCCESS = 'GET_REJECTED_ALBUM_PHOTOS_SUCCESS';
export const GET_REJECTED_ALBUM_PHOTOS_FAIL = 'GET_REJECTED_ALBUM_PHOTOS_FAIL';

export const UPDATE_PHOTO_REQUEST = 'UPDATE_PHOTO_REQUEST';
export const UPDATE_PHOTO_SUCCESS = 'UPDATE_PHOTO_SUCCESS';
export const UPDATE_PHOTO_FAIL = 'UPDATE_PHOTO_FAIL';

export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAIL = 'DELETE_PHOTO_FAIL';

export const GET_PROFILE_ASTRO_REQUEST = 'GET_PROFILE_ASTRO_REQUEST';
export const GET_PROFILE_ASTRO_SUCCESS = 'GET_PROFILE_ASTRO_SUCCESS';
export const GET_PROFILE_ASTRO_CACHE = 'GET_PROFILE_ASTRO_CACHE';
export const GET_PROFILE_ASTRO_FAIL = 'GET_PROFILE_ASTRO_FAIL';

export const GET_PROFILE_PRIVACY_SETTING_REQUEST = 'GET_PROFILE_PRIVACY_SETTING_REQUEST';
export const GET_PROFILE_PRIVACY_SETTING_SUCCESS = 'GET_PROFILE_PRIVACY_SETTING_SUCCESS';
export const GET_PROFILE_PRIVACY_SETTING_FAIL = 'GET_PROFILE_PRIVACY_SETTING_FAIL';

export const UPDATE_PROFILE_PRIVACY_SETTING_REQUEST = 'UPDATE_PROFILE_PRIVACY_SETTING_REQUEST';
export const UPDATE_PROFILE_PRIVACY_SETTING_SUCCESS = 'UPDATE_PROFILE_PRIVACY_SETTING_SUCCESS';
export const UPDATE_PROFILE_PRIVACY_SETTING_FAIL = 'UPDATE_PROFILE_PRIVACY_SETTING_FAIL';

export const CONTACT_SUMMARY_DATA_REQUEST = 'CONTACT_SUMMARY_DATA_REQUEST';
export const CONTACT_SUMMARY_DATA_SUCCESS = 'CONTACT_SUMMARY_DATA_SUCCESS';
export const CONTACT_SUMMARY_DATA_FAIL = 'CONTACT_SUMMARY_DATA_FAIL';
export const CONTACT_SUMMARY_VIEW_SMS_SHOW_SUCCESS = 'CONTACT_SUMMARY_VIEW_SMS_SHOW_SUCCESS';
export const CONTACT_SUMMARY_VIEW_SMS_SHOW_FAIL = 'CONTACT_SUMMARY_VIEW_SMS_SHOW_FAIL';

export const CART_REQUEST = 'CART_REQUEST';
export const CART_SUCCESS = 'CART_SUCCESS';
export const CART_FAIL = 'CART_FAIL';

export const PAYPAL_REQUEST = 'PAYPAL_REQUEST';
export const PAYPAL_SUCCESS = 'PAYPAL_SUCCESS';
export const PAYPAL_FAIL = 'PAYPAL_FAIL';

export const BANKLIST_REQUEST = 'BANKLIST_REQUEST';
export const BANKLIST_SUCCESS = 'BANKLIST_SUCCESS';
export const BANKLIST_FAIL = 'BANKLIST_FAIL';

export const DOORSTEP_REQUEST = 'DOORSTEP_REQUEST';
export const DOORSTEP_SUCCESS = 'DOORSTEP_SUCCESS';
export const DOORSTEP_FAIL = 'DOORSTEP_FAIL';

export const SHAADICENTER_REQUEST = 'SHAADICENTER_REQUEST';
export const SHAADICENTER_SUCCESS = 'SHAADICENTER_SUCCESS';
export const SHAADICENTER_FAIL = 'SHAADICENTER_FAIL';

export const UAE_EXCHANGE_REQUEST = 'UAE_EXCHANGE_REQUEST';
export const UAE_EXCHANGE_SUCCESS = 'UAE_EXCHANGE_SUCCESS';
export const UAE_EXCHANGE_FAIL = 'UAE_EXCHANGE_FAIL';

export const CONSENT_ACTION_REQUEST = 'CONSENT_ACTION_REQUEST';
export const CONSENT_ACTION_SUCCESS = 'CONSENT_ACTION_SUCCESS';
export const CONSENT_ACTION_FAIL = 'CONSENT_ACTION_FAIL';

export const GET_EXPERIEMENT_ACTION_REQUEST = 'GET_EXPERIEMENT_ACTION_REQUEST';
export const GET_EXPERIEMENT_ACTION_SUCCESS = 'GET_EXPERIEMENT_ACTION_SUCCESS';
export const GET_EXPERIEMENT_ACTION_FAIL = 'GET_EXPERIEMENT_ACTION_FAIL';

export const DOWNLOAD_BANNER_SHOW = 'DOWNLOAD_BANNER_SHOW';
export const DOWNLOAD_BANNER_HIDE = 'DOWNLOAD_BANNER_HIDE';
export const TOAST_SHOW = 'TOAST_SHOW';
export const TOAST_HIDE = 'TOAST_HIDE';
export const TOAST_HIDE_KEY = 'TOAST_HIDE_KEY';

export const INBOX_DATA_REQUEST = 'INBOX_DATA_REQUEST';
export const INBOX_DATA_SUCCESS = 'INBOX_DATA_SUCCESS';
export const INBOX_DATA_FREEZE = 'INBOX_DATA_FREEZE';
export const INBOX_FACET_UPDATE = 'INBOX_FACET_UPDATE';

export const GET_OTHER_PROFILE_PHOTOS_REQUEST = 'GET_OTHER_PROFILE_PHOTOS_REQUEST';
export const GET_OTHER_PROFILE_PHOTOS_SUCCESS = 'GET_OTHER_PROFILE_PHOTOS_SUCCESS';
export const GET_OTHER_PROFILE_PHOTOS_FAIL = 'GET_OTHER_PROFILE_PHOTOS_FAIL';

export const PRODUCTS_DETAIL_REQUEST = 'PRODUCTS_DETAIL_REQUEST';
export const PRODUCTS_DETAIL_SUCCESS = 'PRODUCTS_DETAIL_SUCCESS';
export const PRODUCTS_DETAIL_FAIL = 'PRODUCTS_DETAIL_FAIL';

export const OTP_GENERATION_REQUEST = 'OTP_GENERATION_REQUEST';
export const OTP_GENERATION_SUCCESS = 'OTP_GENERATION_SUCCESS';
export const OTP_GENERATION_FAIL = 'OTP_GENERATION_FAIL';

export const OTP_VERIFICATION_REQUEST = 'OTP_VERIFICATION_REQUEST';
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
export const OTP_VERIFICATION_FAIL = 'OTP_VERIFICATION_FAIL';

export const VERIFIED_MOBILE_REQUEST = 'VERIFIED_MOBILE_REQUEST';
export const VERIFIED_MOBILE_SUCCESS = 'VERIFIED_MOBILE_SUCCESS';
export const VERIFIED_MOBILE_FAIL = 'VERIFIED_MOBILE_FAIL';

export const GET_ORDERID_REQUEST = 'GET_ORDERID_REQUEST';
export const GET_ORDERID_SUCCESS = 'GET_ORDERID_SUCCESS';
export const GET_ORDERID_FAIL = 'GET_ORDERID_FAIL';

export const ADD_CART_REQUEST = 'ADD_CART_REQUEST';
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS';
export const ADD_CART_FAIL = 'ADD_CART_FAIL';
export const GET_PROFILE_PHOTO_REQUEST = 'GET_PROFILE_PHOTO_REQUEST';
export const GET_PROFILE_PHOTO_SUCCESS = 'GET_PROFILE_PHOTO_SUCCESS';
export const GET_PROFILE_PHOTO_FAIL = 'GET_PROFILE_PHOTO_FAIL';

export const GET_SELF_PROFILE_REQUEST = 'GET_SELF_PROFILE_REQUEST';
export const GET_SELF_PROFILE_SUCCESS = 'GET_SELF_PROFILE_SUCCESS';
export const GET_SELF_PROFILE_FAIL = 'GET_SELF_PROFILE_FAIL';

export const EOI_JUSTNOW_RESET = 'EOI_JUSTNOW_RESET';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const DOCUMENT_ON_CLICK_CHANGE_REQUEST = 'DOCUMENT_ON_CLICK_CHANGE_REQUEST';
export const DOCUMENT_ON_CLICK_CHANGE_SUCCESS = 'DOCUMENT_ON_CLICK_CHANGE_SUCCESS';
export const DOCUMENT_ON_CLICK_CHANGE_FAIL = 'DOCUMENT_ON_CLICK_CHANGE_FAIL';

export const REMOVE_PAGE_LOADER = 'REMOVE_PAGE_LOADER';

export const PAYMENT_THANK_YOU_REQUEST = 'PAYMENT_THANK_YOU_REQUEST';
export const PAYMENT_THANK_YOU_SUCCESS = 'PAYMENT_THANK_YOU_SUCCESS';
export const PAYMENT_THANK_YOU_FAIL = 'PAYMENT_THANK_YOU_FAIL';

export const BEEP_SOUND_PLAY = 'BEEP_SOUND_PLAY';
export const BEEP_SOUND_STOP = 'BEEP_SOUND_STOP';

export const CSAT_DATA_REQUEST = 'CSAT_DATA_REQUEST';
export const CSAT_DATA_SUCCESS = 'CSAT_DATA_SUCCESS';
export const CSAT_DATA_FAIL = 'CSAT_DATA_FAIL';

export const UPDATE_TRUST_BADGE_REQUEST = 'UPDATE_TRUST_BADGE_REQUEST';
export const UPDATE_TRUST_BADGE_SUCCESS = 'UPDATE_TRUST_BADGE_SUCCESS';
export const UPDATE_TRUST_BADGE_FAIL = 'UPDATE_TRUST_BADGE_FAIL';

export const SIMILAR_PROFILE_REQUEST = 'SIMILAR_PROFILE_REQUEST';
export const SIMILAR_PROFILE_SUCCESS = 'SIMILAR_PROFILE_SUCCESS';
export const SIMILAR_PROFILE_FAIL = 'SIMILAR_PROFILE_FAIL';
export const SET_CANCEL = 'SET_CANCEL';
export const RESET_CANCEL = 'RESET_CANCEL';

export const INBOX_REQUEST_SUMMARY_REQUEST = 'INBOX_REQUEST_SUMMARY_REQUEST';
export const INBOX_REQUEST_SUMMARY_SUCCESS = 'INBOX_REQUEST_SUMMARY_SUCCESS';

export const PROFILE_PAGE_SUCCESS = 'PROFILE_PAGE_SUCCESS';

export const NAV_HIDDEN = 'NAV_HIDDEN';

export const POSTPONE_AUTO_MOVE = 'POSTPONE_AUTO_MOVE';
export const UPDATE_NAV = 'UPDATE_NAV';

export const UPDATE_INVERSE_LOGO = 'UPDATE_INVERSE_LOGO';

export const PHONE_SETTING_DATA_REQUEST = 'PHONE_SETTING_DATA_REQUEST';
export const PHONE_SETTING_DATA_SUCCESS = 'PHONE_SETTING_DATA_SUCCESS';
export const PHONE_SETTING_DATA_FAIL = 'PHONE_SETTING_DATA_FAIL';

export const MY_DASHBOARD_WIDGETS_REQUESTS = 'MY_DASHBOARD_WIDGETS_REQUESTS';
export const MY_DASHBOARD_WIDGETS_SUCCESS = 'MY_DASHBOARD_WIDGETS_SUCCESS';
export const MY_DASHBOARD_WIDGETS_FAIL = 'MY_DASHBOARD_WIDGETS_FAIL';

export const EMI_DETAILS_REQUEST = 'EMI_DETAILS_REQUEST';
export const EMI_DETAILS_SUCCESS = 'EMI_DETAILS_SUCCESS';
export const EMI_DETAILS_FAIL = 'EMI_DETAILS_FAIL';

export const EOI_DELAYED_SUCCESS = 'EOI_DELAYED_SUCCESS';

export const GUEST_TOKEN_CACHE = 'GUEST_TOKEN_CACHE';
export const GUEST_TOKEN_SUCCESS = 'GUEST_TOKEN_SUCCESS';
export const GUEST_TOKEN_FAIL = 'GUEST_TOKEN_FAIL';

export const FORGOT_PASSWORD_OTP_SEND = 'FORGOT_PASSWORD_OTP_SEND';
export const FORGOT_PASSWORD_OTP_FAIL = 'FORGOT_PASSWORD_OTP_FAIL';
export const FORGOT_PASSWORD_OTP_REQUEST = 'FORGOT_PASSWORD_OTP_REQUEST';

export const LOGIN_WITH_OTP_REQUEST = 'LOGIN_WITH_OTP_REQUEST';
export const LOGIN_WITH_OTP_SENT = 'LOGIN_WITH_OTP_SENT';
export const LOGIN_WITH_OTP_FAIL = 'LOGIN_WITH_OTP_FAIL';

export const FP_OTP_VALIDATION_REQUEST = 'FP_OTP_VALIDATION_REQUEST';
export const FP_OTP_VALIDATION_FAIL = 'FP_OTP_VALIDATION_FAIL';
export const FP_OTP_VALIDATION_SUCCESS = 'FP_OTP_VALIDATION_SUCCESS';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const INTENT_LIST_REQUEST = 'INTENT_LIST_REQUEST';
export const INTENT_LIST_SUCCESS = 'INTENT_LIST_SUCCESS';
export const INTENT_LIST_FAILURE = 'INTENT_LIST_FAILURE';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';

export const HAS_HOROSCOPE = 'HAS_HOROSCOPE';
export const COOKIE_SUCCESS = 'COOKIE_SUCCESS';
export const AUTH_COOKIES_UPDATE = 'AUTH_COOKIES_UPDATE';
export const HAS_NET_CONNECTIVITIY = 'HAS_NET_CONNECTIVITIY';
export const LOST_NET_CONNECTIVITIY = 'LOST_NET_CONNECTIVITIY';
export const HAS_WEBP_SUPPORT = 'LOST_NET_CONNECTIVITIY';

export const TOGGLE_LAYOUT = 'SET_LAYOUT';

export const NOTIFICATIONS_HIDE_TOAST = 'NOTIFICATIONS_HIDE_TOAST';

export const DR_PROFILE_NEXT = 'DR_PROFILE_NEXT';

export const MODAL_WATERMARK_SHOW = 'MODAL_WATERMARK_SHOW';

export const PROFILE_QUEUE_CACHE = 'PROFILE_QUEUE_CACHE';

export const OTHER_SEARCH_CACHE = 'OTHER_SEARCH_CACHE';

export const CHATS_DATA_FAIL = 'CHATS_DATA_FAIL';

export const INBOX_STACK_CLOSE = 'INBOX_STACK_CLOSE';
export const SWIPE_ACTION_INBOX_STACK_ALBUM = 'SWIPE_ACTION_INBOX_STACK_ALBUM';

export const BOTTOM_EOI_TOGGLE = 'BOTTOM_EOI_TOGGLE';
export const SMART_BANNER_TOGGLE = 'SMART_BANNER_TOGGLE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL';

export const PROFILE_ALBUM_UPLOAD = 'PROFILE_ALBUM_UPLOAD';

export const BASIC_PROFILE_DETAILS_SUCCESS = 'BASIC_PROFILE_DETAILS_SUCCESS';
export const BASIC_PROFILE_DETAILS_FAIL = 'BASIC_PROFILE_DETAILS_FAIL';
export const PTP_DETAILS_REQUEST = 'PTP_DETAILS_REQUEST';
export const PTP_DETAILS_SUCCESS = 'PTP_DETAILS_SUCCESS';
export const PTP_DETAILS_FAIL = 'PTP_DETAILS_FAIL';

export const PTP_CANCEL_ORDER_REQUEST = 'PTP_CANCEL_ORDER_REQUEST';
export const PTP_CANCEL_ORDER_SUCCESS = 'PTP_CANCEL_ORDER_SUCCESS';
export const PTP_CANCEL_ORDER_FAIL = 'PTP_CANCEL_ORDER_FAIL';

export const MY_ORDER_REQUEST = 'MY_ORDER_REQUEST';
export const MY_ORDER_SUCCESS = 'MY_ORDER_SUCCESS';
export const MY_ORDER_FAIL = 'MY_ORDER_FAIL';

export const PROFILE_MOBILE_VERIFY_STATUS_SUCCESS = 'PROFILE_MOBILE_VERIFY_STATUS_SUCCESS';
export const PROFILE_MOBILE_VERIFY_STATUS_FAIL = 'PROFILE_MOBILE_VERIFY_STATUS_FAIL';

export const PAYMENT_STOPPAGE_DETAIL_REQUEST = 'PAYMENT_STOPPAGE_DETAIL_REQUEST';
export const PAYMENT_STOPPAGE_DETAIL_SUCCESS = 'PAYMENT_STOPPAGE_DETAIL_SUCCESS';
export const PAYMENT_STOPPAGE_DETAIL_FAIL = 'PAYMENT_STOPPAGE_DETAIL_FAIL';

export const DRAFT_SETTING_REQUEST = 'DRAFT_SETTING_REQUEST';
export const DRAFT_SETTING_SUCCESS = 'DRAFT_SETTING_SUCCESS';
export const DRAFT_SETTING_FAIL = 'DRAFT_SETTING_FAIL';

export const UPDATE_LAYER_COUNT = 'UPDATE_LAYER_COUNT';
export const STORE_PREFERRED_LIST_FACETBAR = 'STORE_PREFERRED_LIST_FACETBAR';
export const COMPARE_PREFERRED_LIST_FACETBAR = 'COMPARE_PREFERRED_LIST_FACETBAR';
export const SYSTEM_OPEN_TOOLTIP_PREFERRED_LIST = 'SYSTEM_OPEN_TOOLTIP_PREFERRED_LIST';

export const FETCH_ACCOUNT_SETTINGS_DATA_SUCCESS = 'FETCH_ACCOUNT_SETTINGS_DATA_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_DATA_REQUEST = 'FETCH_ACCOUNT_SETTINGS_DATA_REQUEST';
export const NEW_DEFAULT_DRAFTS_SUCCESS = 'NEW_DEFAULT_DRAFTS_SUCCESS';
export const NEW_DEFAULT_DRAFTS_REQUEST = 'NEW_DEFAULT_DRAFTS_REQUEST';
export const UPDATE_DRAFT_SETTING_STATUS = 'UPDATE_DRAFT_SETTING_STATUS';

export const EMAIL_VALIDATION_REQUEST = 'EMAIL_VALIDATION_REQUEST';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';
export const EMAIL_VALIDATION_FAIL = 'EMAIL_VALIDATION_FAIL';

export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAIL = 'UPDATE_EMAIL_FAIL';
export const RESET_EMAIL_STATUS = 'RESET_EMAIL_STATUS';

export const QR_VIEW_CONTACT_LAYER_CLOSE = 'QR_VIEW_CONTACT_LAYER_CLOSE';
export const RESET_TRIGGER_ID = 'RESET_TRIGGER_ID';
export const RESET_SOURCE_ON_CHAT_BACK = 'RESET_SOURCE_ON_CHAT_BACK';
export const SET_BLOCKED_DR_PROFILE_VIEWED = 'SET_BLOCKED_DR_PROFILE_VIEWED';
export const MOP_DOWNTIME_MESSAGING_REQUEST = 'MOP_DOWNTIME_MESSAGING_REQUEST';
export const MOP_DOWNTIME_MESSAGING_SUCCESS = 'MOP_DOWNTIME_MESSAGING_SUCCESS';
export const MOP_DOWNTIME_MESSAGING_FAIL = 'MOP_DOWNTIME_MESSAGING_FAIL';

export const PAYMENT_SESSION_REQUEST = 'PAYMENT_SESSION_REQUEST';
export const PAYMENT_SESSION_CACHE = 'PAYMENT_SESSION_CACHE';
export const PAYMENT_SESSION_SUCCESS = 'PAYMENT_SESSION_SUCCESS';
export const PAYMENT_SESSION_FAIL = 'PAYMENT_SESSION_FAIL';

export const UPDATE_AUTO_SUBCRIPTION_REQUEST = 'UPDATE_AUTO_SUBCRIPTION_REQUEST';
export const UPDATE_AUTO_SUBCRIPTION_SUCCESS = 'UPDATE_AUTO_SUBCRIPTION_SUCCESS';
export const UPDATE_AUTO_SUBCRIPTION_FAIL = 'UPDATE_AUTO_SUBCRIPTION_FAIL';

export const SHOW_BULK_CONNECT_MODAL = 'SHOW_BULK_CONNECT_MODAL';

export const SHOW_DOWNLOAD_APP_LAYER = 'SHOW_DOWNLOAD_APP_LAYER';
export const DISMISS_DOWNLOAD_APP_LAYER = 'DISMISS_DOWNLOAD_APP_LAYER';

export const FACETS_REQUEST = 'FACETS_REQUEST';
export const FACETS_SUCCESS = 'FACETS_SUCCESS';
export const FACETS_FAIL = 'FACETS_FAIL';

export const SHOW_ALBUM_GAMIFICATION_MODAL = 'SHOW_ALBUM_GAMIFICATION_MODAL';
export const HIDE_ALBUM_GAMIFICATION_MODAL = 'HIDE_ALBUM_GAMIFICATION_MODAL';

export const LOGEER_EXP_DATA_REQUEST = 'LOGEER_EXP_DATA_REQUEST';
export const LOGEER_EXP_DATA_SUCCESS = 'LOGEER_EXP_DATA_SUCCESS';
export const LOGEER_EXP_DATA_ERROR = 'LOGEER_EXP_DATA_ERROR';

export const EXPIRING_SOON_WIDGET_SUCCESS = 'EXPIRING_SOON_WIDGET_SUCCESS';
export const EXPIRING_SOON_WIDGET_FAIL = 'EXPIRING_SOON_WIDGET_FAIL';

export const SHOW_SHAADI_LIVE_ONBOARDING = 'SHOW_SHAADI_LIVE_ONBOARDING';

export const ADDON_DETAILS_REQUEST = 'ADDON_DETAILS_REQUEST';
export const ADDON_DETAILS_SUCCESS = 'ADDON_DETAILS_SUCCESS';
export const ADDON_DETAILS_FAIL = 'ADDON_DETAILS_FAIL';
