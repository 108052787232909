/* global window */
import { GUEST_TOKEN_CACHE, GUEST_TOKEN_SUCCESS, RESET_PASSWORD_SUCCESS, SET_LAYOUT, COOKIE_SUCCESS, HAS_WEBP_SUPPORT } from 'actionTypes';
import { identifyCommunity } from 'reducers/utils';
import constants from 'constants/constants';
import getModulePageName from 'reducers/session/getModulePageName';

const community = identifyCommunity(window.location.hostname);

const initialState = {
  appKey: process.env.REACT_APP_API_KEY,
  chatAppKey: '69c3f1c1ea31d60aa5516a439bb65949cf3f8a1330679fa7ff91fc9a5681b564',
  platform: constants.WEB_PLATFORM,
  wwwBaseUrl: `https://${community.domain}`,
  domainName: community.domainName,
  accessToken: '',
  autologinToken: '',
  authHistoryToken: '',
  uid: '',
  webp: window.webpSupported,
  hasWebpSupport: window.webpSupported === '1',
};

export default function(state = initialState, { type = '', payload = {} } = {}) {
  switch (type) {
    case COOKIE_SUCCESS: {
      const {
        reg_logger: regLogger,
        abc,
        webp = state.webp,
        ltabc: autologinToken = '',
        _alxm: authHistoryToken = '',
        litem,
        slang,
        slite,
        webview,
      } = payload;
      const accessToken = getModulePageName().isRegLoggerPage ? regLogger : abc;
      const uid = String(accessToken).split('|')[1];
      const sitePlatform = litem ? constants.LITE_APP_PLATFORM : state.platform;
      return {
        ...state,
        webp,
        hasWebpSupport: webp === '1',
        accessToken,
        uid,
        autologinToken,
        authHistoryToken,
        platform: webview ? constants.WEB_VIEW_PLATFORM : sitePlatform,
        appLanguage: slang,
        slite,
      };
    }
    case HAS_WEBP_SUPPORT:
      return {
        ...state,
        webp: '1',
        hasWebpSupport: true,
      };
    case GUEST_TOKEN_CACHE:
    case GUEST_TOKEN_SUCCESS: {
      const { guest_token } = payload;
      const uid = String(guest_token).split('|')[1];
      return {
        ...state,
        accessToken: guest_token,
        uid,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        accessToken: '',
        uid: '',
      };
    }
    case SET_LAYOUT: {
      if (state.platform === constants.LITE_APP_PLATFORM) {
        return state;
      }
      const { layout } = payload;
      return {
        ...state,
        platform: layout === 'mobile' ? constants.WAP_PLATFORM : constants.WEB_PLATFORM,
      };
    }
    default:
      return state;
  }
}
