import supports from './supports';
import { asyncGuards } from './guards.ts';

export default function ServiceWorker(swUrl, { applicationServerKey } = {}) {
  async function getRegistration() {
    await asyncGuards.checkShouldRegister();
    return navigator.serviceWorker.ready;
  }

  async function getPushSubscription() {
    await asyncGuards.checkPushPermission();
    const reg = await getRegistration();
    return reg.pushManager.getSubscription();
  }

  return {
    url: swUrl,
    getRegistration,
    withInstance: fn => (supports.serviceWorker() ? fn(navigator.serviceWorker) : null),
    async register() {
      await asyncGuards.checkShouldRegister();
      const reg = await navigator.serviceWorker.register(swUrl);
      return reg;
    },
    async unregister() {
      const reg = await getRegistration();
      return reg.unregister();
    },
    push: {
      async requestPermission() {
        await asyncGuards.checkPushPermission();
        return new Promise(async (resolve, reject) => {
          const permissionResult = window.Notification.requestPermission(resolve);
          permissionResult && permissionResult.then(resolve, reject);
        });
      },
      getSubscription: getPushSubscription,
      async subscribe() {
        await asyncGuards.checkShouldRegister();
        await asyncGuards.checkPushNotifications();
        const reg = await getRegistration();

        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey,
        });
      },
      async unsubscribe() {
        const sub = await getPushSubscription();
        return sub && sub.unsubscribe();
      },
    },
  };
}

export function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4); // eslint-disable-line no-mixed-operators
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}
