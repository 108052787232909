import React from 'react';
import PropTypes from 'prop-types';
import { SumTypePropType } from 'PropTypes';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import { PageType } from 'pages/IntentsListingPage/utils';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "IntentsListingPage.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "IntentsListingPage.mobile" */ './mobile'));

const IntentsListingPage = ({ isMobileScreen, ...props }) => (isMobileScreen ? <Mobile {...props} /> : <Desktop {...props} />);

IntentsListingPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
  type: SumTypePropType(PageType).isRequired,
};

export { PageType };

export default withLayoutDetection(IntentsListingPage);
