import { STORES, withTransaction } from '.';

export const pushEventToQueue = data => withTransaction(STORES.PENDING_EVENTS, ({ store }) => store.add(data));

export const retrievePendingEvents = () =>
  withTransaction(STORES.PENDING_EVENTS, async ({ store }) => {
    const events = await store.getAll();
    await store.clear(STORES.PENDING_EVENTS);
    return events;
  });
