import isBoolean from 'lodash/isBoolean';

let isWebpSupported;

const processImage = src =>
  new Promise((resolve, reject) => {
    const webPSample = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    const img = new Image();
    const afterImageProcess = () => resolve(img.height);
    img.onload = afterImageProcess;
    img.onerror = afterImageProcess;
    img.src = webPSample;
  });

export default async () => {
  if (isBoolean(isWebpSupported)) return isWebpSupported;
  try {
    const height = await processImage();
    isWebpSupported = height === 2;
  } catch (e) {
    isWebpSupported = false;
  }
  return isWebpSupported;
};
