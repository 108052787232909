import React, { Suspense, lazy } from 'react';

const DefaultSpinner = () => null;

export default (dynamicImport, Spinner) => {
  const Component = lazy(dynamicImport);
  return props => (
    <Suspense fallback={Spinner ? <Spinner {...props} /> : <DefaultSpinner />}>
      <Component {...props} />
    </Suspense>
  );
};
