import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "MatchesGroupPage.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "MatchesGroupPage.mobile" */ './mobile'));

const MatchesGroupPage = ({ isMobileScreen, grpType, layout }) =>
  isMobileScreen ? <Mobile grpType={grpType} layout={layout} /> : <Desktop grpType={grpType} />;

MatchesGroupPage.propTypes = {
  grpType: PropTypes.string.isRequired,
  isMobileScreen: PropTypes.bool.isRequired,
  layout: PropTypes.string.isRequired,
};

export default withLayoutDetection(MatchesGroupPage);
