export const supportProps = {
  serviceWorker: () => 'serviceWorker' in navigator,
  pushNotifications: () => 'PushManager' in window,
  notifications: () => 'Notification' in window,
  idb: () => 'indexedDB' in window,
};

export const checkSwSupport = () =>
  !supportProps.serviceWorker() ? Promise.reject(new Error('Service worker is not supported in your browser')) : Promise.resolve();
export const checkPushSupport = () =>
  !supportProps.pushNotifications() || !supportProps.notifications() || !supportProps.idb()
    ? Promise.reject(new Error('Push notifications not supported in your browser'))
    : Promise.resolve();

export default supportProps;
