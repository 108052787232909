import React from 'react';
import PropTypes from 'prop-types';
import withLayoutDetection from 'components/HOC/withLayoutDetection';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import { parse } from 'qs';
import axios from 'axios';
import { setCancel, cancelCall } from '~/actions/lib/utils';
import AsyncDataComponent from '~/components/HOC/asyncDataComponent.tsx';
import fetchSearchData from '~/actions/doPreferredSearch/fetchSearchData.ts';
import PageLoadSpinner from '~/components/PageLoadSpinner';

const searchDataSource = (props = { dataSourceProps: {} }) => {
  const queryParams = parse(window.location.search, { ignoreQueryPrefix: true });
  const searchKey = queryParams.pg_searchresults_id || '';
  props.dataSourceProps.resetCall(['dailyRecommendations']);
  const CancelToken = setCancel(axios, props.dataSourceProps.dispatch, 'matches_init');
  return fetchSearchData(searchKey)(
    {
      params: {
        target: {
          path: window.location.pathname,
          query: parse(window.location.search.slice(1)),
          changes: {},
          isMostPreferred: false,
        },
        ...props.dataSourceProps,
      },
    },
    { CancelToken },
  );
};

const Desktop = AsyncDataComponent({
  getComponent: () => import(/* webpackChunkName: "SearchPartnerPage.desktop" */ './desktop'),
  dataSource: searchDataSource,
  loader: () => (
    <div style={{ height: '100vh' }}>
      <Spinner color="#f1f1f2" />
    </div>
  ),
});

const Mobile = AsyncDataComponent({
  getComponent: () => import(/* webpackChunkName: "SearchPartnerPage.mobile" */ './mobile'),
  dataSource: searchDataSource,
  loader: () => <PageLoadSpinner isMobile />,
});

const SearchPartnerPage = ({ dataSource, isMobileScreen, dispatch, resetCall }) =>
  isMobileScreen ? (
    <Mobile dataSourceProps={{ ...dataSource, dispatch, resetCall }} />
  ) : (
    <Desktop dataSourceProps={{ ...dataSource, dispatch, resetCall }} />
  );

SearchPartnerPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

const selector = state => {
  const nav = state.nav || state.mNav || [];
  const matchesNav = nav.find(navItem => navItem.key === 'matches') || { nav: [] };
  const myMatchesSubnav = matchesNav.nav.find(subNavItem => subNavItem.key === 'matches-preferred') || {};
  const preferred_count = myMatchesSubnav.count || 0;
  return {
    dataSource: {
      isMobile: state.view.layout === 'mobile',
      file_extension: state.config.app.webp !== '0' ? 'webp' : '',
      preferred_count,
    },
  };
};

export default withLayoutDetection(connect(selector, dispatch => ({ dispatch, resetCall: args => dispatch(cancelCall(args)) }))(SearchPartnerPage));
