import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookie from 'cookie';
import { GA } from 'actions/lib/utils';
import { COOKIE_SUCCESS } from 'actionTypes';

const HandleCookies = props => {
  const updateReducer = () => {
    const cookies = cookie.parse(document.cookie);
    GA.setSessionVar(cookies);
    props.dispatch({
      type: COOKIE_SUCCESS,
      payload: cookies,
    });
  };
  useEffect(updateReducer, []);
  return null;
};

HandleCookies.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({ dispatch });

const composedHoc = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), memo);

export default composedHoc(HandleCookies);
