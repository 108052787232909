const baseValue = {
  profiles: [],
  defaultProfileId: '',
  timeLeftToConnect: '',
  key: '',
};

export const getFirstNonActionedIndex = data => {
  const nonActionedRecommendations = data
    .map((prof, i) => {
      if (!prof || prof.action === '') {
        return i;
      }
      return null;
    })
    .filter(i => i !== undefined && i !== null);
  return nonActionedRecommendations[0] || 0;
};

export default (payload = {}, base = baseValue, profileId = '') => {
  let index = 0;
  let lastActionIndex = -1;
  const actionedRecommendations = payload.data
    .map((prof, i) => {
      if (prof.action !== '') {
        lastActionIndex = i;
        return i;
      }
      return null;
    })
    .filter(i => i !== undefined && i !== null);

  const landingProfileIndex = actionedRecommendations.length > 0 ? actionedRecommendations.pop() + 1 : 0;

  if (profileId) {
    index = payload.data.findIndex(item => item.memberlogin === profileId);
  } else {
    index = landingProfileIndex;
  }
  if (index >= 20 || (payload.data && index >= payload.data.length)) {
    // Find first non actioned Profile
    index = getFirstNonActionedIndex(payload.data);
  }
  const disablePros = payload.data.slice(lastActionIndex + 2).map(item => item.memberlogin);
  const defaultProfile = payload.data[index] || {};
  const landingProfile = payload.data[landingProfileIndex] || {};
  const nextDefaultProfile = payload.data[index + 1] || {};
  const prevDefaultProfile = (index > 0 && payload.data[index - 1]) || {};
  const t = payload.remainingTime || 0;
  return {
    ...base,
    profiles:
      payload.data.map(p => ({
        uid: p.memberlogin, // FIXME: old code uses uid keyword; duplicating for safety; use only memberlogin
        memberlogin: p.memberlogin,
        action: p.action,
        action_date: p.action_date,
        // NOTE: recommendation has been removed as it had the 3 keys as above
      })) || [],
    defaultProfileId: defaultProfile.memberlogin || '',
    nextDefaultProfileId: nextDefaultProfile.memberlogin || '',
    prevDefaultProfileId: prevDefaultProfile.memberlogin || '',
    landingProfileId: landingProfile.memberlogin || '',
    timeLeftToConnect: payload.remainingTime,
    target_time: Math.round(new Date() / 1000) + t,
    disablePros,
    actionNotTaken: payload.actionNotTaken,
    key: payload.key || '',
  };
};
