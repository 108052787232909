"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truViewTracking = exports.trackViaFlyingShuttle = exports.trackOnboardingMatch = exports.trackOnDa = void 0;

var _experiments = require("@shaaditech/app/src/helpers/experiments");

var _da = require("@shaaditech/app/src/actions/lib/utils/da");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var getPlatform = function getPlatform() {
  var platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return platform.substring(0, 3);
};

var getValueIfPresent = function getValueIfPresent() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return value === '-' ? 'unknown' : value;
};

var trackOnDa = function trackOnDa(_ref) {
  var event = _ref.event,
      uid = _ref.uid,
      args = _ref.args,
      metadata = _ref.metadata,
      session = _ref.session;
  var platform = metadata.platform,
      event_referrer = metadata.event_referrer,
      event_loc = metadata.event_loc;
  var bucket = (0, _experiments.getTrueViewBucket)(session === null || session === void 0 ? void 0 : session.settings);
  var _args$isNonReal = args.isNonReal,
      isNonReal = _args$isNonReal === void 0 ? false : _args$isNonReal,
      daSchema = args.daSchema;
  /**
   * Important: Please pass proper schema name in `daSchema`
   *
   * For now if nothing is passed in `daSchema` it can be either be
   * 1. viewed_unviewed (True View)
   * 2. profile_completion
   * based on `isNonReal` (It stands for whether tracking is realtime or batch)
   *
   * (in future see if the dependency of `isNonReal` can be removed)
   */

  if (daSchema === 'zend_chat_usage') {
    var data = {
      memberlogin: session.uid,
      profileid: uid,
      platform: 'react_web',
      event_location: window.location.pathname,
      click_button: event
    };
    (0, _da.trackChatUsageEvent)(data);
  }

  if (daSchema === 'cta_tracking') {
    var _data = {
      memberlogin: session.uid,
      profileid: uid,
      app_version: '',
      platform: platform,
      device_info: '',
      event_location: getValueIfPresent(event_loc),
      click_button: event
    };
    (0, _da.trackNewCtasEvent)(_data);
  }

  if (isNonReal === true || bucket === 'B') {
    switch (daSchema) {
      case 'ANNUAL_INCOME':
        {
          var daPayload = _objectSpread({
            profileId: uid
          }, args);

          (0, _da.trackAnnualIncomeEvent)(daPayload);
          break;
        }

      case 'NEW_MATCHES':
        {
          var _data2 = {
            memberlogin: session.uid,
            profileid: uid,
            event_type: event,
            version: '',
            platform: platform,
            event_location: 'new_matches'
          };
          (0, _da.trackNewMatchesEvent)(_data2);
          var payload = {
            memberlogin: session.uid,
            profileid: uid,
            event_name: 'new_match_connect_attempt',
            event_location: getValueIfPresent(event_loc),
            event_referrer: getValueIfPresent(event_referrer),
            platform: getPlatform(platform)
          };
          (0, _da.trackTrueViewEvent)(payload);
          break;
        }

      default:
        {
          if (isNonReal) {
            // Batch
            var _daPayload = _objectSpread({
              memberlogin: session.uid,
              profileId: uid,
              event_referrer: getValueIfPresent(event_referrer),
              event_loc: getValueIfPresent(event_loc),
              event_type: getValueIfPresent(event),
              platform: platform
            }, args);

            (0, _da.trackDefaultEvent)(_daPayload);
          } else {
            // Realtime
            var _payload = {
              memberlogin: session.uid,
              profileid: uid,
              event_name: getValueIfPresent(event),
              event_location: getValueIfPresent(event_loc),
              event_referrer: getValueIfPresent(event_referrer),
              platform: getPlatform(platform)
            };
            (0, _da.trackTrueViewEvent)(_payload);
          }

          break;
        }
    }
  }
};

exports.trackOnDa = trackOnDa;

var trackOnboardingMatch = function trackOnboardingMatch(payload) {
  (0, _da.trackOnboardingResurface)(payload);
};

exports.trackOnboardingMatch = trackOnboardingMatch;

var truViewTracking = function truViewTracking(payload) {
  (0, _da.trackTrueViewEvent)(payload);
};

exports.truViewTracking = truViewTracking;

var trackViaFlyingShuttle = function trackViaFlyingShuttle(schemaName) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var version = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '1-0-0'; // @ts-ignore

  if (['prod', 'stagi'].includes(process.env.REACT_APP_ENV || '') && window.da) {
    // @ts-ignore
    window.da("trackSelfDescribingEvent:".concat(process.env.REACT_APP_ENV), {
      schema: "iglu:com.shaadi/".concat(schemaName, "/jsonschema/").concat(version),
      data: data
    });
  }
};

exports.trackViaFlyingShuttle = trackViaFlyingShuttle;