// eslint-disable-next-line
import { Settings, Bucket, AppView } from '@shaaditech/types/settings';
import { match } from '~/helpers/common';
import { getDownloadAppLink } from '~/mComponents/utils';

type BucketRequestData = {
  data: {
    experiment_actual: Record<string, { bucket: string }>;
  };
};

export const extractActualExperimentBuckets = (data: BucketRequestData): Record<string, string> => {
  const out: Record<string, string> = {};
  Object.keys(data.data.experiment_actual).forEach(key => {
    out[key] = data.data.experiment_actual[key].bucket;
  });
  return out;
};

// Experiment :: String -> Settings -> 'A'|'B'|String
export const Experiment = (key: string, defaultBucket: Bucket = 'A') => (settings: Settings = { experiments: {} }): Bucket => {
  const { experiments: { [key]: { bucket = defaultBucket } = {} } = {} } = settings;
  return bucket;
};

export const getConnectTextBucket = Experiment('connect_communication_change');

export const getWhatsAppCTABucket = (settings: Settings, view: AppView = {}): Bucket => {
  let bucket = Experiment('whatsapp_cta')(settings);
  if (bucket === 'B' && view && view.layout === 'desktop') {
    bucket = 'A';
  }
  return bucket;
};

export const getWhatsAppCTABucketDesktop = (settings: Settings, isDesktop = true): Bucket => {
  let bucket = Experiment('whatsapp_cta_web')(settings);
  if (bucket === 'B' && (settings.isPaidUser || !isDesktop)) {
    bucket = 'A';
  }
  return bucket;
};

export const getVoiceCallBucket = (settings: Settings): Bucket => Experiment('voice_call')(settings);

export const getMeetCampiagnGroup = (settings: Settings, view: AppView = {}): Bucket => {
  const bucketIOS = Experiment('video_call_ios')(settings);
  const bucketAndroid = Experiment('video_call')(settings);
  let bucket: Bucket = 'A';

  const platformInfo = getDownloadAppLink();

  switch (platformInfo.platform) {
    case 'android':
      bucket = bucketAndroid;
      break;
    case 'apple':
      bucket = bucketIOS;
      break;
    default:
  }

  if (bucket === 'B' && view && view.layout === 'desktop') {
    bucket = 'A';
  }
  return bucket;
};

export const getDraftSettingsBucket = (settings: Settings, view: AppView = {}): Bucket => {
  let bucket = Experiment('free_message_state')(settings);
  if (bucket === 'B' && (settings.isPaidUser || (view && view.layout === 'desktop'))) {
    bucket = 'A';
  }
  return bucket;
};

export const getFilterMessageBucket = (gender: string, settings: Settings): Bucket => {
  const isFemale = gender.toLowerCase() === 'female';
  const filterBucket = Experiment(isFemale ? 'CA000264' : 'CA000252')(settings);
  return isFemale ? swapFilterBucketForFemale(filterBucket) : filterBucket;
};
export const getMonetizationBucket = (settings: Settings): Bucket => Experiment('shaadi_event')(settings);
export const getEventDiscoveryWidgetBucket = (settings: Settings): Bucket => {
  const monetizationBucket = Experiment('shaadi_event')(settings);
  let finalBucket = monetizationBucket;
  const UIWidgetBucket = Experiment('CA000719')(settings);
  if (monetizationBucket === 'B') {
    finalBucket = UIWidgetBucket;
  }
  return finalBucket;
};
export const getHamburgerMigrationBucket = (settings: Settings): Bucket => Experiment('CA000718')(settings);

export const getMSiteExpiringConnectsReceiverUISettingsBucket = (settings: Settings): Bucket => Experiment('CA000671')(settings);

const swapFilterBucketForFemale = (bucket: Bucket) =>
  match(
    {
      A: 'B',
      B: 'A',
      _: 'A',
    },
    '_',
  )(bucket);
export const getSLFeatureinPPBucket = (settings: Settings, isDesktop = true): Bucket => {
  const campaignId = isDesktop ? 'CA000749' : 'CA000750';
  return Experiment(campaignId)(settings);
};
export const getPremiumCtaBucket = Experiment('CA000044');
export const getMyShaadiDRWidgetBucket = Experiment('CA000100');
export const getCRA2Bucket = Experiment('CA000056');
export const getFilteredUserBucket = Experiment('CA000103');
export const getTrueViewBucket = Experiment('CA000101');
export const getDRAutoScrollIntervalBucket = Experiment('CA000121');
export const getRevisedBCase = Experiment('CA000119');
export const getProfileDetailsOrder = Experiment('CA000152');
export const getMsiteInboxAlbumAccept = Experiment('CA000188');
export const getLocalCurrencyBucket = Experiment('CA000270', 'O');
export const getUpiIntentFlowBucket = Experiment('CA000279', 'O');
export const getFreeOfferEndingBucket = Experiment('CA000293', 'O');
export const getForcedRemovedDrBucket = Experiment('CA000292');
export const getLazyLoadingSuccessStoryBucket = Experiment('CA000327', 'O');
export const getProfileContactCardBucket = Experiment('CA000339');
export const getQRBucket = Experiment('CA000336');
export const getPremiumAutoRedirectionRemoveDrBucket = Experiment('CA000368');
export const getHideProfileHeaderBucket = Experiment('CA000390', 'O');
export const DECORATE_DR_IN_PROXY_EXPERIMENT_ID = 'CA000400';
export const getAlbumGamificationBucket = Experiment('CA000499', 'O');
export const CANARY_EXP_ID = 'CA000435';
export const getFilteredOutUiBucket = Experiment('CA000461');
export const getWebFilteredOutUiBucket = Experiment('CA000469');
export const getQrStackMenuBucket = Experiment('CA000465');
export const getPaytmUpiBucket = Experiment('CA000565', 'O');
export const getMsiteFilteredOutChatBucket = Experiment('CA000549');
export const getDesktopFilteredOutChatBucket = Experiment('CA000560');
export const PROFILE_OPTIMISATION_EXP = 'CA000486';
export const getBulkConnectMSiteBucket = Experiment('CA000493');
export const getBulkConnectWebBucket = Experiment('CA000620');
export const getUpiMopPositionBucket = Experiment('CA000570', 'O');
export const getAppPromotionBucket = Experiment('CA000582');
export const getInboxSortingBucket = Experiment('CA000609');
export const getChatAppPromotionBucket = Experiment('CA000627');
export const getMopDisplayBucket = Experiment('CA000639');
export const getMsiteDrEmailCardBucket = Experiment('CA000630');
export const getMSiteInboxPremiumPitchBucket = Experiment('CA000634');
export const getMsiteCCAndDCTotalPayableBucket = Experiment('CA000654');
export const getMSiteExpiringConnectsReceiverUIBucket = Experiment('CA000671');
export const getWebExpiringConnectsReceiverUIBucket = Experiment('CA000672');
export const getCountListingBucket = Experiment('CA000681');
export const getMsiteDgIdVerificationBucket = Experiment('CA000660');
export const getInboxSortingBucketWeb = Experiment('CA000715');
export const getMsiteProfileDeletionBucket = Experiment('CA000712');
export const getDesktopCountListingBucket = Experiment('CA000713');
export const getWebDrEmailCardBucket = Experiment('CA000670');
export const getWebRemoveFacebookPhotoUpload = Experiment('CA000756');
