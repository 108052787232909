import React, { FC, useReducer, useEffect } from 'react';
import asyncComponent from '~/components/AsyncComponent';

export interface DataComponentModule<T, P> {
  default: FC<P & { result: { data: T; error: Error } }>;
}

export interface Options<T, P> {
  dataSource: (props: P) => Promise<T>;
  getComponent: (props: P) => Promise<DataComponentModule<T, P>>;
  loader?: FC<any>;
}

type Result<E, T> = {
  status: 'pending' | 'success' | 'failure';
  data: T | null;
  error: E | null;
};

enum Action {
  DO_SUCCESS,
  DO_FAILURE,
}

export default function asyncDataComponent<T, P>({ dataSource, getComponent, loader }: Options<T, P>) {
  const Component = asyncComponent(getComponent, loader);

  const reducer = (state: Result<Error, T>, { type, payload }: { type: Action; payload?: any }) => {
    switch (type) {
      case Action.DO_SUCCESS:
        return {
          ...state,
          status: 'success',
          data: payload,
        };
      case Action.DO_FAILURE:
        return {
          ...state,
          status: 'failure',
          error: payload,
        };
    }
  };

  const initialState: Result<Error, any> = {
    status: 'pending',
    data: null,
    error: null,
  };

  return (props: P) => {
    const [result, dispatch] = useReducer(reducer, initialState as never);

    useEffect(() => {
      dataSource(props)
        .then((data: T) => dispatch({ type: Action.DO_SUCCESS, payload: data }))
        .catch((error: Error) => dispatch({ type: Action.DO_FAILURE, payload: error }));
    }, []);

    return <Component result={result} {...props} />;
  };
}
