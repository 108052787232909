import ServiceWorker, { urlB64ToUint8Array } from '~/helpers/service-workers';
import supports from '~/helpers/service-workers/supports';
import enableTracking from '~/helpers/service-workers/tracking';

const SW_URL = `/sw.js?sid=${`${Math.random().toString(16)}000000000`.substr(2, 8)}`;

export const sw = ServiceWorker(SW_URL, { applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_PUSH_VAPID_KEY) });

export default function register(serviceworkerName = 'service-worker') {
  if (!supports.serviceWorker()) return;

  //  Enables tracking for service worker, web push and pwa
  enableTracking(sw);

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location);

  if (publicUrl.origin !== window.location.origin) return;

  window.addEventListener('load', () => {
    sw.register().catch(e => console.log('>> Sw registration error', e));
  });
}
