import cookie from 'cookie';
import getModulePageName from '../reducers/session/getModulePageName';

export default (cookies = document.cookie) => {
  const parsedCookies = cookie.parse(cookies);
  const { reg_logger: regLogger, abc } = parsedCookies;
  const accessToken = getModulePageName().isRegLoggerPage ? regLogger : abc;
  const uid = String(accessToken).split('|')[1];
  return uid;
};
