/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionDispatcher, PromiseFactory, StateGetter, DispatchFunction } from '@shaaditech/types/actions';

type Options = {
  defer?: boolean; // (default: true) If false, will preload the action chunk before the function is called. Otherwise, it gets loaded on demand.
  exportName?: string; // (default: 'default') The export name of the function.
  timeout?: number; // (default: 100) The delay before preloading chunk. Only used when lazy is set to false.
};

type LazyAction<T, P> = (...args: any) => ActionDispatcher<T, P>;

// Load actions lazily in your page to reduce page chunk size.
// The action chunk can be loaded on-demand or preloaded after a certain delay.
export function lazyAction<T, P>(promiseFactory: PromiseFactory, options: Options = {}): LazyAction<T, P> {
  const { defer = true, exportName = 'default', timeout = 50 } = options;

  const getAction = () => promiseFactory().then(m => m[exportName]);
  if (!defer) setTimeout(getAction, timeout);
  return (...args: any[]) => (dispatch: DispatchFunction<T, P>, getState: StateGetter) => getAction().then(fn => fn(...args)(dispatch, getState));
}

type Key = string | symbol;

const promiseCache = new Map<Key, Promise<any>>();

// Wrap this around a data source to memoize it
export const memoize = <Data, Args extends Array<any>>(id: Key, fn: (...args: Args) => Promise<Data>) => (...args: Args): Promise<Data> => {
  if (promiseCache.has(id)) {
    return promiseCache.get(id) as Promise<Data>;
  }

  const promise = fn(...args);
  promiseCache.set(id, promise);
  return promise;
};

// Clear memoization cache
export const clearDataCache = (id: Key) => promiseCache.delete(id);
