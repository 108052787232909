import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
// import IntlComponent from 'components/IntlComponent';
import Spinner from 'components/Spinner';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "FourOFour.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "FourOFour.mobile" */ './mobile'));

// const Intl = IntlComponent({
//   en: () => import(/* webpackChunkName: "locales.en" */ './locales/en'),
//   mr: () => import(/* webpackChunkName: "locales.mr" */ './locales/mr'),
// });

const NotFound = props => (props.isMobileScreen ? <Mobile /> : <Desktop />);

NotFound.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(NotFound);
