import React from 'react';

const DeferredAsyncComponent = (dynamicImport, Spinner = null) =>
  class AsyncComponent extends React.PureComponent {
    state = { Component: null };
    componentWillUnmount = () => {
      window.removeEventListener('load', this.onLoad);
    };
    componentDidMount = () => {
      if (document.readyState === 'complete') this.onLoad();
      else window.addEventListener('load', this.onLoad);
    };
    onLoad = () => {
      dynamicImport()
        .then(({ default: Component }) => this.setState({ Component }))
        .catch(console.error);
    };
    render = () => {
      const { Component } = this.state;
      return Component ? <Component {...this.props} /> : Spinner;
    };
  };

export default DeferredAsyncComponent;
