import { DispatchType, GetStateType, AuthObjType, UnknownObject } from './../../types/actions';
import { CHAT_CONNECTING, CHAT_CONNECTED, CHAT_DISCONNECTED, CHAT_PRESENCE } from '~/actionTypes';
import Chat from '../../chat';
import onMessage from './onMessage';
import withAuth from '../withAuth';

export default () => (dispatch: DispatchType, getState: GetStateType): void =>
  withAuth(
    ({ auth }: { auth: AuthObjType }) => {
      const { config = {}, chat = {} } = getState();
      const { app = { chatAppKey: '' } } = config;
      const { settings = {} } = chat;
      const { status: chatStatus } = settings;
      const nonce = `${Math.random().toString(16)}000000000`.substr(2, 8);

      dispatch({ type: CHAT_CONNECTING, payload: {} });
      const socket = new Chat(app.chatAppKey, {
        auth: { uid: auth.uid, accessToken: auth.accessToken, nonce, device: 'web' },
      });
      // Chat.socket = socket;
      const channel = socket.subscribe(`private/${auth.uid}`);

      socket.on('connected', () => {
        dispatch({ type: CHAT_CONNECTED, payload: {} });
        socket.sendPresence(chatStatus || 'Online');
      });

      socket.on('disconnected', (flash: 'string') => {
        dispatch({ type: CHAT_DISCONNECTED, payload: { flash } });
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      socket.on('error', (err: any) => {
        console.log('CHAT ERROR', err);
      });

      channel.on('message', (msg: Record<'from', string> & UnknownObject) => {
        onMessage(auth, msg, dispatch, getState);
      });

      channel.on('presence', (status: string) => {
        dispatch({ type: CHAT_PRESENCE, payload: { status } });
      });

      socket.connect();
      // Chat.socket = socket;
      // @ts-ignore
      window.chatSocket = socket;
    },
    { caller: 'socketInitB', allowCache: true, delay: 1, getState, dispatch, params: {} },
  );
