import { openDB as openDatabase } from 'idb';

export async function openDB(...args) {
  try {
    const d = await openDatabase(...args);
    return d;
  } catch (e) {
    return {};
  }
}

export const createKeyValStore = (db, storeName) => ({
  db,
  get: key => db.then(d => d.get(storeName, key)),
  set: (key, val) => db.then(d => d.put(storeName, val, key)),
  delete: key => db.then(d => d.delete(storeName, key)),
  clear: () => db.then(d => d.clear(storeName)),
  keys: () => db.then(d => d.getAllKeys(storeName)),
});
