/* global document, window */
/* eslint no-underscore-dangle: 0 */
import { SET_LAYOUT, FOUR_HOUR_SUCCESS, FOUR_HOUR_CLOSE, UPDATE_DIMENSIONS_SCROLL, UPDATE_DIMENSIONS_RESIZE } from 'actionTypes';
import detectLayout from 'actions/onAppInit/detectLayout';

const initialState = {
  prevScroll: 0,
  scroll: 0,
  hideNav: false,
  prevHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  prevWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  layout: detectLayout().layout,
  orientation: window.screen && window.screen.width > window.screen.height ? 'landscape' : 'portrait',
  detection: {},
  topSpace: 0,
  isHeaderBarVisible: true,
  isFacetTitleFixed: false,
  isFacetOffScreen: false,
  chatScrollHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LAYOUT: {
      return {
        ...state,
        layout: action.payload.layout,
        detection: action.payload,
      };
    }
    case FOUR_HOUR_SUCCESS: {
      const topSpace = action.payload.ticker.isVisible ? 75 : 0;
      if (state.topSpace === topSpace) return state;
      return { ...state, topSpace };
    }
    case FOUR_HOUR_CLOSE: {
      const topSpace = 0;
      if (state.topSpace === topSpace) return state;
      return { ...state, topSpace };
    }
    case UPDATE_DIMENSIONS_SCROLL: {
      const newHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      if (state.scroll === action.payload.scroll) {
        return {
          ...state,
          height: newHeight,
        };
      }

      const isHeaderBarVisible = action.payload.scroll <= state.scroll || action.payload.scroll <= 55;
      const chatScrollHeight = state.height - (isHeaderBarVisible ? 154 : 113);
      const newState = {
        ...state,
        height: newHeight,
        prevScroll: state.scroll,
        scroll: action.payload.scroll,
        isFacetOffScreen: action.payload.isSearchPage && action.payload.scroll > action.payload.facetBottom - 75,
        isFacetTitleFixed:
          action.payload.isSearchPage && action.payload.scroll >= 62 && action.payload.scroll < 10000 && action.payload.scroll > state.scroll,
        isHeaderBarVisible,
        chatScrollHeight,
      };
      return JSON.stringify(newState) === JSON.stringify(state) ? state : newState;
    }
    case UPDATE_DIMENSIONS_RESIZE: {
      const chatScrollHeight = action.payload.height - (state.isHeaderBarVisible ? 154 : 113);
      const newState = {
        ...state,
        prevHeight: state.height,
        prevWidth: state.width,
        width: action.payload.width,
        height: action.payload.height,
        orientation: action.payload.orientation,
        layout: action.payload.layout,
        chatScrollHeight,
        detection: {
          ...state.detection,
          isMobile: action.payload.layout === 'mobile',
          layout: action.payload.layout,
        },
      };
      return JSON.stringify(newState) === JSON.stringify(state) ? state : newState;
    }

    default:
      return {
        ...state,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      };
  }
}
