import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import initializeStore, { createStorageListener } from 'store';
import ErrorBoundary from 'components/ErrorBoundary';
import HandleCookies from 'components/HandleCookies';
import MonitorNetConnection from 'components/MonitorNetConnection';
import MonitorHistory from 'components/MonitorHistory';
import MonitorResize from 'components/MonitorResize';
import MonitorScroll from 'components/MonitorScroll';
import MonitorAuthentication from 'components/MonitorAuthentication';
import DeferredAsyncComponent from 'components/DeferredAsyncComponent';
import DetectWebP from 'components/DetectWebP';
// import GTM from 'components/GTM';
import InititalizeDatadog from 'components/InitializeDatadog';
import Routes from 'routes';

const store = initializeStore();
window.addEventListener('storage', createStorageListener(store));

const SubscribeToNotifications = DeferredAsyncComponent(() =>
  import(/* webpackChunkName: "SubscribeToNotifications" */ 'components/PushNotifications/Subscribe.tsx'),
);

export default () => (
  <ErrorBoundary showError>
    <Router>
      <Provider store={store}>
        {/* <GTM /> */}
        <DetectWebP />
        <HandleCookies />
        <MonitorNetConnection />
        <MonitorHistory />
        <MonitorResize />
        <MonitorScroll />
        <MonitorAuthentication />
        <InititalizeDatadog />
        <SubscribeToNotifications autoSubscribe />
        <Routes />
      </Provider>
    </Router>
  </ErrorBoundary>
);
