import Serializer from './php-serialize';

// unserialize :: String -> *
export const unserialize = (str, ...args) => {
  if (!str) return '';
  try {
    return Serializer.unserialize(str, ...args);
  } catch (e) {
    return '';
  }
};

// serialize :: * -> String
export const serialize = Serializer.serialize;
