export default ({ entryPoint, loginAttempt, deviceId, sessionPtnr, appVersion, autoLoginTokenRequired = true, data }) => ({
  method: 'post',
  url: '/users/login',
  data: {
    metadata: {
      entry_point: entryPoint,
      login_attempt: loginAttempt,
      device_id: deviceId,
      session_ptnr: sessionPtnr,
      app_version: appVersion,
      autologin_flag: autoLoginTokenRequired,
    },
    data,
  },
});
