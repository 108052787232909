import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "Cart.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "Cart.mobile" */ './mobile'));

const CartPage = props => (props.isMobileScreen ? <Mobile /> : <Desktop />);

CartPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(CartPage);
