import requestService from '../services/requestService';
import Router from '~/api/router';

const index = async (logger, query, auth, config) => {
  const request = {
    url: `/api/search/${auth.uid}`,
    // url: `/api/search/${auth.uid}`,
    method: 'get',
    params: query,
    baseURL: process.env.REACT_APP_PROXY_API_URL,
  };
  return requestService(
    logger,
    { ...query, _debug: 'search_partner' },
    auth,
    request,
    data => data,
    data => null,
    {
      cancelToken: config.CancelToken,
    },
  ).then(({ data }) => data);
};

Router.get('/preferred_matches_proxy', index);

export default {
  index,
};
