import React from 'react';
import DeferredAsyncComponent from 'components/DeferredAsyncComponent';
import { Context } from '../../components/Common/Context';

const Desktop = DeferredAsyncComponent(() => import(/* webpackChunkName: "LayerPartial.desktop" */ './desktop'));
const Mobile = DeferredAsyncComponent(() => import(/* webpackChunkName: "LayerPartial.mobile" */ './mobile'));

export default props => (
  <Context.Consumer>
    {({ canShowLayerPartial, layout }) => {
      if (!canShowLayerPartial) {
        return null;
      }
      return layout === 'desktop' ? <Desktop {...props} /> : <Mobile {...props} />;
    }}
  </Context.Consumer>
);
