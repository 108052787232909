import { stringify } from 'qs';
import { trackViaFlyingShuttle } from '@shaaditech/saga-deprecated/lib/tracking/tracking.helpers';
import {
  CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_FREE_USER,
  CHOOSE_ANOTHER_PLAN_VIP_LAPSED_USER_REQUEST_SOURCE,
  CHOOSE_ANOTHER_PLAN_SILVER_LAPSED_USER_REQUEST_SOURCE,
  CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_LAPSED_USER,
  CHOOSE_ANOTHER_PLAN_VIP_RENEW_USER_REQUEST_SOURCE,
  CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_RENEW_USER,
  CHOOSE_ANOTHER_PLAN_SILVER_RENEW_USER_REQUEST_SOURCE,
  FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_FREE_USER,
  FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_RENEW_USER,
  FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_LAPSED_USER,
  FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_PREMIUM_USER,
  UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_ENTPT,
  UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOC,
  UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTREF,
  UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOCBTN,
  RENEW_NOW_BUTTON_TEXT_NORMAL_USER,
  RENEW_NOW_SMALL_BUTTON_TEXT_NORMAL_USER,
  RENEW_NOW_BUTTON_TEXT_VIP_USER,
  RENEW_NOW_SMALL_BUTTON_TEXT_VIP_USER,
  RENEW_NOW_BUTTON_TEXT_SILVER_USER,
  RENEW_NOW_SMALL_BUTTON_TEXT_SILVER_USER,
} from './UpgradeToPremiumConstants';

import { AuthInterface, PaymentVisitFunction, TrackParams } from './UpgradeToPremium.types';

/**
 * This function simply calls the
 * flying shuttle helper for user
 * tracking for any given action.
 * @param action Type of Action | string
 * @param memberlogin User identification | string
 */
export const trackFlyingShuttleEvent = (action: string, memberlogin: string): void => {
  trackViaFlyingShuttle('page_load_event', {
    memberlogin,
    action,
    url: window.location.href,
  });
};

/**
 * This functions returns the payment
 * page URL with added parameters
 * source as 'upgrade_to_premium'
 * ref as window.location.href
 * @param userType Type of the user | string
 * @param isVip Whether the user is a VIP user or not | Boolean
 * @param isSilver Whether the user is a silver user or not | Boolean
 * @param backprevious should it return back to previous page or not | Boolean
 * @returns string URL
 */
export const getPaymentUrl = (
  userType: string,
  isVip = false,
  isSilver = false,
  go: string = window.location.pathname.replace('/', ''),
  backprevious = true,
): string => {
  const queryParams = {
    source: getPaymentVisitsSource(userType, isVip, isSilver),
    go,
    backprevious,
  };
  return `/payment?${stringify(queryParams)}`;
};

/**
 * This function returns the source
 * parameter for Payment Page 1
 * visit
 * @param userType Type of the user | string
 * @param isVip Whether the user is a VIP user or not | Boolean
 * @param isSilver Whether the user is a silver user or not | Boolean
 * @returns string Source Parameter value
 */
export const getPaymentVisitsSource = (userType: string, isVip = false, isSilver = false): string => {
  switch (userType.toLowerCase()) {
    case 'free':
      return CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_FREE_USER;
    case 'lapsed':
      if (isVip) {
        return CHOOSE_ANOTHER_PLAN_VIP_LAPSED_USER_REQUEST_SOURCE;
      }
      if (isSilver) {
        return CHOOSE_ANOTHER_PLAN_SILVER_LAPSED_USER_REQUEST_SOURCE;
      }
      return CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_LAPSED_USER;
    case 'renew':
      if (isVip) {
        return CHOOSE_ANOTHER_PLAN_VIP_RENEW_USER_REQUEST_SOURCE;
      }
      if (isSilver) {
        return CHOOSE_ANOTHER_PLAN_SILVER_RENEW_USER_REQUEST_SOURCE;
      }
      return CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_RENEW_USER;
    default:
      return CHOOSE_ANOTHER_PLAN_REQUEST_SOURCE_LAPSED_USER;
  }
};

/**
 * This functions returns the source
 * parameter for Fetch membership details
 * API call
 * @param userType Type of the user | string
 * @returns string Source Parameter value
 */
export const getFetchMembershipDetailsAPISource = (userType: string): string => {
  switch (userType) {
    case 'premium':
      return FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_PREMIUM_USER;
    case 'lapsed':
      return FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_LAPSED_USER;
    case 'renew':
      return FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_RENEW_USER;
    default:
      return FETCH_MEMBERSHIP_DETAILS_REQUEST_SOURCE_FREE_USER;
  }
};

/**
 * This function detects wether
 * the user is a VIP User or Not
 * based on their membership_details.
 * @param param MembershipDetails
 * @returns boolean
 */
export const isVipUser = ({ isVip, showOrderSummary }: { isVip: boolean; showOrderSummary: boolean }): boolean =>
  isVip === true && showOrderSummary === false;

/**
 * This function detects wether
 * the user is a Silver User or Not
 * based on their membership_details.
 * @param param MembershipDetails
 * @returns boolean
 */
export const isSilverUser = ({ isVip, showOrderSummary }: { isVip: boolean; showOrderSummary: boolean }): boolean =>
  isVip === false && showOrderSummary === false;

/**
 * This function returns the
 * difference between passed
 * start and end date in
 * No. of Days
 * @param stateDate any
 * @param endDate any
 * @returns number Difference in Days
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDateDifference = (startDate: any, endDate: any) => {
  const NO_OF_MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
  const diffTime = endDate - startDate;
  return Math.ceil(diffTime / NO_OF_MILLISECONDS_IN_A_DAY);
};

/**
 * This function returns the
 * no. of days for user's
 * membership expiry in no.
 * of days
 * @param membershipInfo membership_info from membership Details | null
 * @returns difference in days | number
 */
export const getDaysForMembershipExpiry = (membershipInfo: { valid_till: string } | null = null): number => {
  if (!membershipInfo) {
    return 0;
  }
  const todaysDate = new Date();
  const validTillDate = membershipInfo.valid_till.split(' ');
  const tillDate = new Date(`${validTillDate[1].replace(',', '').toLowerCase()}/${parseInt(validTillDate[0], 10)}/${parseInt(validTillDate[2], 10)}`);
  return getDateDifference(todaysDate, tillDate);
};

/**
 * This function formats the
 * passed date for membership
 * details card.
 * @param date date
 * @returns formatted date string
 */
export const formatDate = (date: Date): string => {
  const day = date.getDate();
  const formattedDay = ['st', 'nd', 'rd'][((((day + 90) % 100) - 10) % 10) - 1] || 'th';
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return `${day}${formattedDay} ${months[date.getMonth()]}`;
};

/**
 * This function simply calls
 * the passed payments visit
 * function with the request
 * details based on Auth
 * for VIP users.
 * @param auth Auth Details
 * @param paymentVisit PaymentVisitFunction
 */
export const requestVIPAccountRenewal = (auth: AuthInterface, paymentVisit: PaymentVisitFunction): void => {
  const headerDetails = {
    xEntpt: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_ENTPT,
    xEvtloc: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOC,
    xEvtref: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTREF,
    xEvtlocbtn: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOCBTN,
  };
  const requestDetails = {
    data: {
      type: 'renewal-lead',
      'renewal-lead': {
        memberlogin: auth.user.uid,
      },
    },
  };
  paymentVisit({
    uid: auth.user.uid,
    ...auth,
    requestDetails,
    headerDetails,
  });
};

/**
 * This function simply calls
 * the passed payments visit
 * function with the request
 * details based on Auth
 * for Normal /Non-VIP users.
 * @param paymentVisitsAPISource Source Parameter
 * @param auth AuthInterface
 * @param paymentVisit PaymentVisitFunction
 * @param trackParams Parameters for Visit Tracking
 */
export const paymentVisitAPICall = (
  paymentVisitsAPISource: string,
  auth: AuthInterface,
  paymentVisit: PaymentVisitFunction,
  trackParams: TrackParams,
): void => {
  const headerDetails = {
    xEntpt: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_ENTPT,
    xEvtloc: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOC,
    xEvtref: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTREF,
    xEvtlocbtn: UPGRADE_TO_PREMIUM_REQUEST_HEADER_X_EVTLOCBTN,
  };
  const requestDetails = {
    data: {
      type: 'payment-visit-log',
      'payment-visit-log': {
        memberlogin: auth.user.uid,
        source: paymentVisitsAPISource,
        ...trackParams,
      },
    },
  };
  paymentVisit({
    uid: auth.user.uid,
    ...auth,
    requestDetails,
    headerDetails,
  });
};

/**
 * This function returns the text
 * for the button on Hero Sections,
 * Membership Details Card and on
 * the Contacts Card.
 * @param isVip is a VIP User | Boolean
 * @param isSilver is a Silver User | Boolean
 * @param isSmallButton is it a small button | Boolean
 * @returns Button Text | String
 */
export const getRenewMembershipButtonText = (isVip: boolean, isSilver: boolean, isSmallButton = true): string => {
  if (isVip) {
    if (isSmallButton) {
      return RENEW_NOW_SMALL_BUTTON_TEXT_VIP_USER;
    }
    return RENEW_NOW_BUTTON_TEXT_VIP_USER;
  }
  if (isSilver) {
    if (isSmallButton) {
      return RENEW_NOW_SMALL_BUTTON_TEXT_SILVER_USER;
    }
    return RENEW_NOW_BUTTON_TEXT_SILVER_USER;
  }
  if (isSmallButton) {
    return RENEW_NOW_SMALL_BUTTON_TEXT_NORMAL_USER;
  }
  return RENEW_NOW_BUTTON_TEXT_NORMAL_USER;
};

/**
 * This functions returns tag styling
 * and tag label for MembershipDetailsCard
 * @param userType Type of User | String
 * @param renewalDays No. of Days remaining for Renewal | Number
 * @param futureMembership Is the Future membership text | Boolean
 * @param isVipRenewalRequested Has Requested for VIP Renewal | Boolean
 * @returns TagsStyling and Label Text | string and string
 */
export const getMembershipDetailsCardPillTextAndTagStyling = (
  userType: string,
  renewalDays: number,
  futureMembership: boolean,
  isVipRenewalRequested: boolean,
): {
  tagStyle: string;
  label: string;
} => {
  if (futureMembership) {
    return { tagStyle: 'futureMembershipTag', label: 'Upcoming Plan' };
  }
  if (userType === 'premium') {
    return { tagStyle: 'successTag', label: 'Active' };
  }
  if (isVipRenewalRequested) {
    return { tagStyle: 'renewalRequestedTag', label: 'Renewal Requested' };
  }
  if (userType === 'renew') {
    if (renewalDays === 0) {
      return { tagStyle: 'dangerTag', label: `Expires Today!` };
    }
    return { tagStyle: 'dangerTag', label: `${renewalDays} days left!` };
  }
  return { tagStyle: 'dangerTag', label: 'Expired' };
};

export const calculatePrimaryHeroSectionOpacityBasedOnScroll = (scrollOffset: number): number => {
  let opacity = 33 / scrollOffset;
  opacity -= 0.2;
  opacity = Number(opacity.toFixed(2));
  return opacity;
};

export const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode.toLowerCase()) {
    case 'inr':
      return '₹';
    case 'usd':
      return 'US $';
    case 'eur':
      return '€';
    case 'cad':
      return 'CA $';
    case 'aud':
      return 'AU $';
    case 'gbp':
      return '£';
    default:
      return currencyCode.toUpperCase();
  }
};
