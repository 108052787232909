// @todo export const to be used here and not default export
import device from 'helpers/device';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    paddingTop: !device || device.isMobile ? '100px' : '0px',
    flexFlow: 'column nowrap',
  },
  main: {
    position: 'relative',
    zIndex: '1',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    height: '100%',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    msFlexWrap: 'nowrap',
    msFlexDirection: 'column',
  },
  mainStopPage: {
    position: 'relative',
    zIndex: '1',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    height: '100%',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    paddingTop: '100px',
  },
  content: {
    paddingTop: !device || device.isMobile ? '0px' : '100px',
    position: 'relative',
    flex: 1,
  },
  mobileContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    fontFamily: 'Roboto, sans-seif',
    fontWeight: 400,
    fontSmoothing: 'antialiased',
  },
};

export const getTheme = () => ({
  ...styles,
  container: {
    ...styles.container,
    paddingTop: !device || device.getIsMobile() ? '100px' : '0px',
  },
  content: {
    ...styles.content,
    paddingTop: !device || device.getIsMobile() ? '0px' : '100px',
  },
});

export default styles;
