import { combineReducers } from 'redux';
import config from 'reducers/config';
import session from 'reducers/session';
import metadata from 'reducers/metadata';
import view from 'reducers/view';
import cancelApi from 'reducers/cancelApi';

/**
 * @param {Object} - key/value of reducer functions
 */
const createReducer = asyncReducers =>
  combineReducers({
    config,
    session,
    metadata,
    view,
    cancelApi,
    // When reducers are provided to createReducer they'll be plopped on here
    ...asyncReducers,
  });

export default createReducer;
