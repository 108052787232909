import React, { useContext, useState, useEffect } from 'react';
import { ReactReduxContext } from 'react-redux';
import { logError } from '~/helpers/rollbar';
/* adding provision to rename saga reducer at consumer end,
in current case it will help using modal reducer here in app package */
export default (duckInjectFns = [], reducerkeyMap = []) => WrappedComponent => props => {
  const { store } = useContext(ReactReduxContext);
  const [isInjected, setIsInjected] = useState(false);

  useEffect(() => {
    const derivedPromises = duckInjectFns.map((fn, index) => fn(store, reducerkeyMap[index] ?? null));
    Promise.all(derivedPromises)
      .then(() => setIsInjected(true))
      .catch(logError);
  }, [store]);

  return isInjected ? <WrappedComponent {...props} /> : null;
};
