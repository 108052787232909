import { stringify } from 'qs';
import { AUTH_FAIL, UNAUTH } from 'actionTypes';
import errors from '../lib/errors';

export default ({ dispatch, getModulePageName, getDomain, redirectExternal }) => error => {
  // @todo ask the A3 Team to handle this in their component to avoid this anti-pattern
  if (getModulePageName().isPaymentCartPages) {
    redirectExternal(`${window.location.protocol}//${getDomain()}/registration/user/login?${stringify({ go: window.location.href })}`);
  }
  // @todo have either AUTH_FAIL or UNAUTH
  dispatch({ type: AUTH_FAIL, payload: errors.clean(error) });
  dispatch({ type: UNAUTH, payload: {} });
};
