import {
  UNAUTH,
  CONTACT_EOI_SUCCESS,
  SESSION_CACHE,
  EDIT_PROFILE_SUCCESS,
  SESSION_SUCCESS,
  UPDATE_LAYER_COUNT,
  DRAFT_SETTING_SUCCESS,
  UPDATE_DRAFT_SETTING_STATUS,
  PAYMENT_SESSION_SUCCESS,
  LOGEER_EXP_DATA_SUCCESS,
} from 'actionTypes';

const initialState = {
  defaultSearchFormat: 'list',
  isPaidUser: false,
  showUpgradeBanner: false,
  canAccessChat: false,
  canInitiateChat: false,
  hasUploadedPhoto: true,
  canConnectWithMessage: false,
  showUpgradeLinks: true,
  canSendPasswordOnConnect: false,
  contactsTotal: 0,
  contactsRemaining: 0,
  isUnderScreening: false,
  isAstroGamified: false,
  gender: 'none',
  canViewCollegeAndEmployer: false,
  canViewHoroscope: false,
  horoscopeStyle: 'l/ENG/hs/1',
  isFamilyGamified: false,
  isHidden: false,
  isBothPartyPayUser: false,
  isMobileVerified: false,
  mobileNumber: '',
  mobileCountry: '',
  payToStay: {},
  newMatch: {},
  profileWriting: 'A',
  isShowOnbordingResurface: false,
  triggerType: 'reg',
  triggerLocation: '',
  connectCount: 0,
  showConnectLayer: 'Y',
  showDraftSetting: false,
  haveMostRelevantEvent: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UNAUTH:
      return {
        ...initialState,
      };
    case CONTACT_EOI_SUCCESS: {
      return {
        ...state,
        contactsTotal: action.payload.contacts_total || state.contactsTotal,
        contactsRemaining:
          action.payload.contacts_remaining || (action.payload.contactDeduction === true ? state.contactsRemaining - 1 : state.contactsRemaining),
      };
    }
    case SESSION_CACHE: {
      const { experiments, payToStay, newMatch, onBoardingResurface } = action.payload;
      return {
        ...state,
        experiments: {
          ...(experiments.experiments || experiments),
        },
        payToStay: {
          ...(payToStay.payToStay || payToStay),
        },
        newMatch: {
          ...(newMatch.newMatch || newMatch),
        },
        profileWriting: experiments.profileWriting,
        isShowOnbordingResurface: onBoardingResurface?.onBoardingResurface?.login ?? false,
        triggerType: onBoardingResurface?.triggerType ?? state.triggerType,
        triggerLocation: onBoardingResurface?.triggerLocation ?? state.triggerLocation,
      };
    }
    case EDIT_PROFILE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isFamilyGamified: !(data?.family?.mother_profession?.length > 0 && data.family.father_profession.length > 0),
      };
    }
    case LOGEER_EXP_DATA_SUCCESS:
    case PAYMENT_SESSION_SUCCESS: {
      const { experiments } = action.payload;
      return {
        ...state,
        experiments: {
          ...(experiments.experiments || experiments),
        },
        profileWriting: experiments.profileWriting,
      };
    }
    case SESSION_SUCCESS: {
      const { auth, self, settings, experiments, offers, payToStay, newMatch, draftSetting, onBoardingResurface } = action.payload;
      return {
        ...state,
        defaultSearchFormat: self.flags.isNri ? 'grid' : 'list',
        canAccessChat: self.flags.activeStatus === 'default',
        wasPaidUser: auth.membership.indexOf('was') !== -1,
        expiryDate: auth.expiryDate || '',
        isPaidUser: self.flags.membershipLevel !== 'Free',
        hasUploadedPhoto: self.flags.albumStatus !== 'noPhoto',
        canConnectWithMessage: self.flags.membershipLevel !== 'Free',
        canInitiateChat: self.flags.membershipLevel !== 'Free',
        showUpgradeBanner: self.flags.membershipLevel === 'Free',
        showUpgradeLinks: self.flags.membershipLevel === 'Free',
        isUnderScreening: self.flags.activeStatus === 'toBeScreened',
        contactsTotal: auth.sms.balance + auth.sms.viewed,
        contactsRemaining: auth.sms.balance,
        canSendPasswordOnConnect: self.flags.albumStatus === 'requestPassword',
        horoscopeStyle: self.flags.horoscopeStyle,
        gender: self.gender,
        canViewHoroscope: self.flags.isHoroscopeApplicable,
        isNri: self.flags.isNri,
        isHidden: self.flags.isHidden,
        isIndianDiaspora: self.flags.isIndianDiaspora,
        canViewCollegeAndEmployer: self.flags.membershipLevel !== 'Free',
        isFamilyGamified: self.flags.isFamilyGamified,
        isAstroGamified: self.flags.isHoroscopeApplicable && !self.flags.isAstroReady,
        isBothPartyPayUser: self.flags.isBothPartyPayUser,
        isMobileVerified: settings.isMobileVerified,
        experiments: {
          ...(experiments.experiments || experiments),
        },
        membershipTags: self.flags.membershipTags || 'free',
        mobileNumber: settings.mobileNumber,
        mobileCountry: settings.mobileCountry,
        offerCode: offers.offer_code || '',
        offer_details: offers.offer_details && offers.offer_details.length > 0 ? offers.offer_details : '',
        payToStay: {
          ...(payToStay.payToStay || payToStay),
        },
        newMatch: {
          ...(newMatch.newMatch || newMatch),
        },
        connectCount: (draftSetting.connect || {}).count || state.connectCount || 0,
        showConnectLayer: (draftSetting.connect || {}).status || state.status || 'Y',
        showDraftSetting: (draftSetting.connect || {}).showDraftSetting || state.showDraftSetting || false,
        profileWriting: experiments.profileWriting,
        isShowOnbordingResurface: onBoardingResurface ? onBoardingResurface.login : false,
        triggerType: onBoardingResurface?.triggerType ?? state.triggerType,
        triggerLocation: onBoardingResurface?.triggerLocation ?? state.triggerLocation,
      };
    }
    case UPDATE_DRAFT_SETTING_STATUS:
      return { ...state, showDraftSetting: action.payload.connect };
    case UPDATE_LAYER_COUNT:
      return { ...state, connectCount: action.payload.connect };
    case DRAFT_SETTING_SUCCESS:
      return { ...state, showConnectLayer: action.payload.setting };
    case 'RELEVANT_EVENT_DETAILS_SET_STATE': {
      const { event } = action.payload;
      if (event?.id) {
        return { ...state, haveMostRelevantEvent: true };
      }
      return state;
    }
    default:
      return state;
  }
}
