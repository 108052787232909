import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker'; // eslint-disable-line no-unused-vars

const $root = document.getElementById('root');
const renderApp = () => {
  ReactDOM.render(<App />, $root);
};

// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
module.hot && module.hot.accept('./App', renderApp);
renderApp();

// NOTE: To disable service worker, uncomment the unregister line and comment out the register line
// sw.unregister().catch(() => {});
registerServiceWorker();
