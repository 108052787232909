import cookie from 'cookie';
import { createCookie } from 'api/helpers';
import { CHAT_SETTINGS_CHANGE } from 'actionTypes';
import localCache from 'localCache';
import api from 'api';

const updateCache = (cacheKey, payload, key, value) => {
  localCache.write(cacheKey, payload, 3600 * 24 * 7);
  if (key === 'status') {
    // updating shbar chat cookie,this change will help chat status to stay in sync in zend and react
    const chatSettingsMap = {
      offline: '3',
      online: '1',
    };

    if (chatSettingsMap[value]) {
      const cookies = cookie.parse(document.cookie);
      if (cookies.shbar) {
        const chatSettings = cookies.shbar.split(',');
        chatSettings[0] = chatSettingsMap[value];
        createCookie('shbar', chatSettings.toString());
      }
    }
    const cacheData = localCache.read(cacheKey);
    cacheData.status = value;
    cacheData.isOpen = value === 'online';
    localCache.write(cacheKey, cacheData, 3600 * 24 * 7);
  }
};
const changeSettings = (auth, [key, value], { dispatch, getState }, retries = 3) => {
  const cacheKey = `${auth.uid}::chatSettings`;
  let payload = getState().chat.settings;
  if (key === 'status') {
    const data = { [key]: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() };
    api
      .put('/chat-presence/update', data)
      .then(() => {
        dispatch({ type: CHAT_SETTINGS_CHANGE, payload: { key, value } });
        payload = getState().chat.settings;
        updateCache(cacheKey, payload, key, value);
        if (window.chatSocket && value && !window.chatSocket.sendPresence(value) && retries > 0) {
          setTimeout(() => changeSettings(auth, [key, value], { dispatch, getState }, retries - 1), 2000);
          console.log(`Will retry setting status ${value} in 2s`);
        }
      })
      .catch(error => console.log('chat changeSettings error ', error));
  } else {
    dispatch({ type: CHAT_SETTINGS_CHANGE, payload: { key, value } });
    updateCache(cacheKey, payload, key, value);
  }
};

export default changeSettings;
