import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/Common/Spinner';

const addSlash = base => (`${base}`.endsWith('/') ? `${base}` : `${base}/`);
const removeSlash = path => (`${path}`.startsWith('/') ? `${path}`.slice(1) : `${path}`);

const NotFound = props => {
  useEffect(() => {
    const href = `${addSlash(props.base)}${removeSlash(window.location.pathname)}${window.location.search}`;
    window.location.href = href;
  }, []);

  return (
    <div style={{ display: 'flex', flex: 1, paddingTop: '60px', alignItems: 'flex-start' }}>
      <Spinner isVisible text="&nbsp;Loading..." />
    </div>
  );
};

NotFound.propTypes = {
  base: PropTypes.string.isRequired,
};

const injectReducerAsProps = state => ({ base: state.config.app.wwwBaseUrl });

export default connect(injectReducerAsProps)(NotFound);
