import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTheme } from '@material-ui/core';

export const spinAnimation = keyframes`to { transform: rotate(360deg); }`;

const CircularProgress = styled.div`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  border: ${({ thickness }) => thickness}px solid transparent;
  border-left-color: ${({ color }) => color};
  border-bottom-color: ${({ color }) => color};
  border-right-color: ${({ color }) => color};
  border-radius: 100%;

  animation: ${spinAnimation} ${({ duration }) => duration} linear infinite;
`;

// FIXME: There is no reason this uses both useTheme and styled-components; standardizing needed
export default ({ ...p }) => {
  const theme = useTheme();
  const color = theme.palette[p.color || 'primary'] || {};
  const props = {
    ...p,
    color: color.main || p.color,
    size: `${p.size || 32}px`,
    thickness: p.thickness || 3,
    duration: p.duration || '0.8s',
  };
  return <CircularProgress {...props} />;
};
