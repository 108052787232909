import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'mComponents/Spinner/styles';
import CircularProgress from 'mComponents/Common/Mui/CircularProgress';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '~/components/Common/FormElements/theme';

const Spinner = ({ size, duration, color, thickness, ...props }) => (
  <MuiThemeProvider theme={theme}>
    <Container {...props}>
      <CircularProgress {...{ size, duration, color, thickness }} />
    </Container>
  </MuiThemeProvider>
);

Spinner.defaultProps = {
  style: {},
  size: 32,
  duration: '1s',
  color: 'secondary',
  thickness: 2,
};

Spinner.propTypes = {
  style: PropTypes.shape({}),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.string,
  color: PropTypes.string,
  thickness: PropTypes.number,
};

export default Spinner;
