import { COUNTS_REQUEST, COUNTS_CACHE, COUNTS_SUCCESS, COUNTS_FAIL, DR_PROFILES_SUCCESS } from 'actionTypes';
import api from 'api';
import localCache from 'localCache';
import errors from '../lib/errors';
import { cacheKey } from '../lib/utils';
import isDrPage from '~/helpers/isDrPage.ts';
import preloadProfileList from '~/actions/onCollectionInit/loadCollection/preloadProfileList';
import '~/api/controllers/CountsController';

export default (auth, dispatch, forceCallApi = false, getState) => {
  dispatch({ type: COUNTS_REQUEST, payload: {} });
  const cKey = cacheKey('counts', auth.uid);
  const countsData = localCache.read(cKey);
  if (countsData && !forceCallApi) {
    dispatch({ type: COUNTS_CACHE, payload: countsData });
  }

  api
    .get('/counts/me')
    .then(response => {
      const { drSuccessData = {}, ...countsResponse } = response.data;
      dispatch({ type: COUNTS_SUCCESS, payload: countsResponse });
      localCache.write(cKey, countsResponse, 1800);

      // FIXME: following code assumes that you can reach the dr page directly or via click on 'Matches' section of header
      if (isDrPage()) {
        const { defaultProfileId = '', actionNotTaken, profiles = [] } = drSuccessData;
        dispatch({ type: DR_PROFILES_SUCCESS, payload: drSuccessData });
        if (defaultProfileId && getState) {
          let forward = 1;
          let backward = 1;
          if (actionNotTaken === 0) {
            forward = 4;
            backward = 1;
          }
          const profileUids = profiles.map(p => p.memberlogin);
          preloadProfileList(defaultProfileId, { getState, dispatch, render: true }, { forward, backward, profileUids });
        }
      }
    })
    .catch(error => dispatch({ type: COUNTS_FAIL, payload: errors.clean(error) }));
};
