import cookie from 'cookie';
import { AUTH_SUCCESS, COOKIE_SUCCESS } from 'actionTypes';
import localCache from 'localCache';

export const getTokenExpiry = ({ currentDate, getMinutesIncrement }) => {
  const tokenExpiryDate = new Date(currentDate);
  tokenExpiryDate.setMinutes(getMinutesIncrement(currentDate));
  tokenExpiryDate.setMilliseconds(0);
  return tokenExpiryDate.toGMTString();
};

export const getMinutesIncrementForAccessToken = currentDate => currentDate.getMinutes() + 30;

const yearInMinutes = 24 * 60 * 365;

export const getMinutesIncrementForAutoLoginToken = currentDate => currentDate.getMinutes() + yearInMinutes;

export const getAccessTokenExpiry = currentDate =>
  getTokenExpiry({
    currentDate,
    getMinutesIncrement: getMinutesIncrementForAccessToken,
  });

export const getAutoLoginTokenExpiry = currentDate =>
  getTokenExpiry({
    currentDate,
    getMinutesIncrement: getMinutesIncrementForAutoLoginToken,
  });

export default ({
  wrappedFuncToCall,
  setApiHeadersCommon,
  dispatch,
  getCacheKeyOfAuth,
  getModulePageName,
  guard,
  history,
  currentDate = new Date(),
}) => response => {
  const {
    data: { auth },
  } = response;
  const { accessToken } = auth;

  const key = getCacheKeyOfAuth(accessToken);
  localCache.write(key, response.data, 1800);
  setApiHeadersCommon(auth);

  // if not a visitor page having guest token
  if (!key.includes('guest')) {
    // cookie setup
    const accessTokenCookieName = getModulePageName().isRegLoggerPage ? 'reg_logger' : 'abc';
    guard.createCookie(accessTokenCookieName, accessToken, getAccessTokenExpiry(currentDate));
    guard.createCookie('ltabc', auth.autologinToken, getAutoLoginTokenExpiry(currentDate));

    // dispatch events for reducer changes
    const cookies = cookie.parse(document.cookie);
    dispatch({ type: COOKIE_SUCCESS, payload: cookies });
    dispatch({ type: AUTH_SUCCESS, payload: response.data });
  }

  // call wrapped function by withAuth
  return wrappedFuncToCall({ auth }, history);
};
