import get from 'lodash/get';
import { compose } from 'redux';

export { get, compose };

export const map = <T = any, R = any>(fn: (x: T) => R) => (x: Array<T>) => x.map(fn);

export const not = (fn: (...a: any[]) => boolean) => (...args: any[]): boolean => !fn(...args);

export const head = <T = any>([h]: T[]) => h;

export const constant = <T = any>(x: T) => () => x;

export const identity = <T = any>(x: T) => x;

export const noop = () => undefined;

export const eq = <X = any>(x: X) => (y: X) => x === y;

export const gte = <X = any>(x: X) => (y: X) => y >= x;

export const between = (a: number, b: number) => (x: number) => x > a && x < b;

export const FALLBACK = constant(true);

export const startsWith = (x: string) => (y: string) => y.indexOf(x) === 0;

export const prop = <T = any>(k: string, defaultVal?: T) => (o: { [k: string]: any }): T => (o && k in o ? o[k] : defaultVal);

export const propPath = <T = any>(keys: string[], defaultVal?: T) => (o: { [k: string]: any }) => get(o, keys, defaultVal);

export const ifElse = <T = any, R = any>(pred: (x: T) => boolean, truthy: (x: T) => R, falsey: (x: T) => R) => (x: T) =>
  pred(x) ? truthy(x) : falsey(x);
