import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import NotFound from 'pages/NotFound';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Mobile = AsyncComponent(() => import(/* webpackChunkName: "BuddyListPage.mobile" */ 'pages/BuddyListPage/mobile'), Spinner);

const BuddyListPage = props => (props.isMobileScreen ? <Mobile /> : <NotFound />);

BuddyListPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(BuddyListPage);
