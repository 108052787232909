import React from 'react';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';
import NotFound from 'pages/NotFound';
import withLayoutDetection from 'components/HOC/withLayoutDetection';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "ContactSummaryPage.desktop" */ './desktop'), Spinner);

const ContactSummaryPage = props => (props.isMobileScreen ? <NotFound /> : <Desktop />);

ContactSummaryPage.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
};

export default withLayoutDetection(ContactSummaryPage);
