import React from 'react';
import cookie from 'cookie';

const ucword = str => {
  const newstr = str.toLowerCase();
  return newstr.replace(/(^([a-zA-Z]))|([ -][a-zA-Z])/g, s => s.toUpperCase());
};
const calculateAspectRatio = ({ width, height }, ref, refType = 'height') => {
  let ImgHeight;
  let imgWidth;
  switch (refType) {
    case 'height': {
      imgWidth = (width / height) * ref;
      ImgHeight = ref;
      return { width: imgWidth, height: ImgHeight };
    }
    case 'width': {
      ImgHeight = (height / width) * ref;
      imgWidth = ref;
      return { width: imgWidth, height: ImgHeight };
    }
    default: {
      return { width, height };
    }
  }
};
const either = (val, fallback) => (val === undefined ? fallback : val);
const shouldShowAlbum = ({ isPaidUser, hasUploadedPhoto }, connectionStatus) =>
  isPaidUser || hasUploadedPhoto || ['theyAccepted', 'theyContacted', 'accepted'].includes(connectionStatus);
const reduceObj = (attrNameArr, AttrMap) => {
  if (!AttrMap) return {};
  return attrNameArr.reduce((accum, attrName) => {
    accum[attrName] = AttrMap[attrName] || '';
    return accum;
  }, {});
};

const encode64 = str => window.btoa(unescape(encodeURIComponent(str)));
const decode64 = str => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch (err) {
    console.log('Error in decode64.  Failed for:', str);
    return '';
  }
};
const openWindowPopUp = (width, height, url, alignment = 'center') => {
  let top = 0;
  let left = 0;
  const widowWidth = window.screen.availWidth / 2;
  const windowHeight = window.screen.availHeight / 2;
  const popUpWidth = width / 2;
  const popUpHeight = height / 2;
  switch (alignment) {
    case 'center':
      left = widowWidth - popUpWidth;
      top = windowHeight - popUpHeight;
      break;
    default: {
      top = 0;
      left = 0;
    }
  }

  window.open(url, '', `height=${height},width=${width},left=${left},top=${top},scrollbar=yes`);
};

const formatText = (text, values, regex, jsxText) => {
  if (!values.length) return text;

  return (
    <React.Fragment>
      {text.split(regex).reduce((prev, current, i) => {
        if (!i) return [current];

        return prev.concat(values.includes(current) ? jsxText : current);
      }, [])}
    </React.Fragment>
  );
};

const countryDictionary = {
  INDIA: 'IN|India',
};

const siteAccessedFrom = () => {
  const cookies = cookie.parse(document.cookie);
  const { i2c } = cookies;
  return i2c;
};

export const isSiteAccessedFromIndia = () => {
  const INDIA = countryDictionary.INDIA;
  return siteAccessedFrom() === INDIA;
};

export const getWindowLocationForIE = () => {
  if (!window.location.origin) {
    window.location.origin = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  }
  return window.location.origin;
};

// const isProfilePhotoScreened = photos => photos && !!photos[0] && photos[0].isPhotoScreened;

export const shouldShowAlbumGamification = photos => photos.count === 0;

// runSaga is middleware.run function
// rootSaga is a your root saga for static saagas
export const createSagaInjector = (runSaga, rootSaga) => {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = key => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a staticlly loaded file,
  injectSaga('root', rootSaga);

  return injectSaga;
};

export { ucword, either, calculateAspectRatio, shouldShowAlbum, reduceObj, encode64, decode64, openWindowPopUp, formatText };
