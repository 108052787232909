import { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import monitorResize from 'actions/onAppInit/monitorResize';

const MonitorResize = props => {
  useEffect(() => props.monitorResize(), []);
  return null;
};

MonitorResize.propTypes = {
  monitorResize: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapActionToProps = { monitorResize };

const composedHoc = compose(withRouter, connect(mapStateToProps, mapActionToProps), memo);

export default composedHoc(MonitorResize);
