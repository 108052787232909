import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AsyncComponent from 'components/AsyncComponent';
import Spinner from 'components/Spinner';

const Desktop = AsyncComponent(() => import(/* webpackChunkName: "PaymentPage.desktop" */ './desktop'), Spinner);
const Mobile = AsyncComponent(() => import(/* webpackChunkName: "PaymentPage.mobile" */ './mobile'));

const PaymentPage = ({ layout }) => (layout === 'desktop' ? <Desktop /> : <Mobile />);

PaymentPage.propTypes = {
  layout: PropTypes.string.isRequired,
};

const selector = state => ({
  layout: state.view.layout,
});

export default connect(selector, {})(PaymentPage);
